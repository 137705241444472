import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { GetBatchTest } from '@generatedTypes/graphql';
@Component({
  selector: 'app-modal-regulatory',
  templateUrl: './modal-regulatory.component.html',
  styleUrls: ['./modal-regulatory.component.scss'],
})
export class ModalRegulatoryComponent {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalRegulatoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GetBatchTest.Regulations,
  ) {}

  close() {
    this.dialogRef.close(true);
  }

  navigateToExternal(url: string) {
    window.open(url, '_blank');
  }
}
