import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  NotificationDialogType,
  AdminManagementActionResult,
  ClassifyResultType,
  RemediationManagement,
} from '@types';
import { SessionService } from '@services/session.service';
@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  dialogContents = {
    [NotificationDialogType.UNSUPPORTED_FILE]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Unsupported file type',
      content: {
        text: ['Your files must meet the following requirements'],
        list: [
          'File format is .csv',
          'Selected reports must be for Trade State',
          'File name is unmodified',
        ],
      },
      buttons: {
        left: '',
        right: 'OK',
      },
    },
    [NotificationDialogType.UNSAVED_CHANGES]: {
      imgAddr: 'assets/images/help-question.svg',
      title: 'Do you want to leave your changes?',
      content: {
        text: ['Changes you made will not be saved'],
        list: [],
      },
      buttons: {
        left: 'Cancel',
        right: 'Leave',
      },
    },
    [NotificationDialogType.DEACTIVATE_TENANT]: {
      imgAddr: 'assets/images/help-question.svg',
      title: 'Are you sure you want to deactivate this tenant?',
      content: {
        text: ['Any non-admin users associated will lose access.'],
        list: [],
      },
      buttons: {
        left: 'Cancel',
        right: 'Deactivate',
      },
    },
    [NotificationDialogType.MISSING_FILES]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some reports are missing',
      content: {
        text: [
          'You did not select a file for every asset class.',
          'Are you sure you want to continue',
        ],
        list: [],
      },
      buttons: {
        left: 'Cancel',
        right: 'Continue',
      },
    },

    [NotificationDialogType.DUPLICATE_FILES]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Duplicate file selected',
      content: {
        text: [
          'Some of your files appear to be the same.',
          'Please double-check your selected files for any duplicates.',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'OK',
      },
    },
    [NotificationDialogType.REMOVE_FILE]: {
      imgAddr: 'assets/images/help-question.svg',
      title: 'Are you sure you want to remove this file?',
      content: {
        text: ['The change cannot be undone.'],
        list: [],
      },
      buttons: {
        left: 'Cancel',
        right: 'Remove',
      },
    },
    [NotificationDialogType.UPLOAD_FAILED]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Upload failed',
      content: {
        text: [
          'Sorry, an error occurred during the upload process.',
          'Please try uploading your files again',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Go back',
      },
    },

    [AdminManagementActionResult.CREATE_USER_SUCCESS]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'New user profile created',
      content: {
        text: ['An invitation has been sent to the email address provided'],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },
    [AdminManagementActionResult.CREATE_TENANT_SUCCESS]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'New tenant created!',
      content: {
        text: ['You can now create new users for this tenant.'],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },
    [AdminManagementActionResult.UPDATE_USER_SUCCESS]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'User profile updated',
      content: {
        text: ['Changes have been successfully saved'],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },
    [AdminManagementActionResult.UPDATE_TENANT_SUCCESS]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'Tenant profile updated',
      content: {
        text: ['Changes have been successfully saved'],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },
    [AdminManagementActionResult.UPDATE_USER_FAILED]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'User profile update failed',
      content: {
        text: ['', ''],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Go back',
      },
    },
    [AdminManagementActionResult.CREATE_USER_FAILED]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Failed to create user profile',
      content: {
        text: ['User is already created. Please try another email address.'],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },

    [AdminManagementActionResult.LEAVE_FIELDS_INCOMPLETE]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some fields are incomplete',
      content: {
        text: [
          'Please complete all required fields.',
          'All changes will be lost if you continue. ',
        ],
        list: [],
      },
    },
    [ClassifyResultType.LEAVE_UNSAVED]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Do you want to leave asset class classification?',
      content: {
        text: ['Changes you made will not be saved.'],
        list: [],
      },
      buttons: {
        left: 'Cancel',
        right: 'Leave',
      },
    },
    [ClassifyResultType.SAVE_SUCCESS]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'Classifications saved!',
      content: {
        text: [
          'Your classifications have been saved.',
          'You can change your classifications within',
          'the remediation tab.',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Continue',
      },
    },
    [ClassifyResultType.ASSET_CLASSS_UNCLASSIFIED]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Please classify one or more asset classes',
      content: {
        text: [
          'You cannot proceed until you classify one or ',
          'more asset classes and write a comment',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Ok',
      },
    },

    [ClassifyResultType.MISSING_COMMENTS]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some asset class are missing comments',
      content: {
        text: [
          'Comments are required for any asset class',
          'classified as "Confirm issue" or "No issue".',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Ok',
      },
    },
    [ClassifyResultType.OVERWRITE_EXISTING]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Are you sure you want to overwrite the existing information',
      content: {
        text: [
          'By clicking "Submit", any changes you make will',
          'overwrite the previously submitted remediation?',
        ],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Submit',
      },
    },
    // Various notifications will be needed throughout the "remediation management" views.
    [RemediationManagement.MISSING_RCOMMENTS]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some asset classes are missing comments.',
      content: {
        text: [
          'Comments are required for any asset class',
          'classified as "Confirm issue" or "No issue".',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Ok',
      },
    },
    [RemediationManagement.REMDEDIATION_COMPLETE]: {
      imgAddr: 'assets/images/no-issue-sm.svg',
      title: 'Remediation complete!',
      content: {
        text: [
          'Your assignments have been made. ',
          'You can change your assignments within',
          'the remediation tab.',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'Ok',
      },
    },
    [RemediationManagement.PAST]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some of your target resolution dates are in the past.',
      content: {
        text: [
          'By clicking “Submit”, you will be submitting target',
          'resolution dates that are in the past. You can update',
          'these dates by clicking “Go back”.',
        ],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Submit',
      },
    },
    [RemediationManagement.INCOMPLETE]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Some of your assignments are incomplete.',
      content: {
        text: [
          'By clicking “Assign”, you will be submitting',
          'incomplete remediation tasks. You can complete',
          'these fields later by opening the “Remediation” tab. ',
        ],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Assign',
      },
    },
    [RemediationManagement.CLASSIFY]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Please classify one or more asset classes.',
      content: {
        text: [
          'You cannot proceed until you classify one or',
          'more asset classes and write a comment.',
        ],
        list: [],
      },
      buttons: {
        left: '',
        right: 'ok',
      },
    },
    [RemediationManagement.LEAVE_ASSIGN]: {
      imgAddr: 'assets/images/help-question.svg',
      title: 'Do you want to leave remediation assignment?',
      content: {
        text: ['Changes you made will not be saved. '],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Leave',
      },
    },
    [RemediationManagement.LEVAE_CLASSIFICATION]: {
      imgAddr: 'assets/images/help-question.svg',
      title: 'Do you want to leave asset class classification?',
      content: {
        text: ['Changes you made will not be saved. '],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Leave',
      },
    },
    [RemediationManagement.OVERWRITE_INFORMATION]: {
      imgAddr: 'assets/images/alert.svg',
      title: 'Are you sure you want to overwrite the existing information?',
      content: {
        text: [
          'By clicking “Submit”, any changes you make',
          ' will overwrite the previously submitted remediation.',
        ],
        list: [],
      },
      buttons: {
        left: 'Go back',
        right: 'Submit',
      },
    },
    // Various notifications will be needed throughout the "remediation management" views end.
  };
  displayContent: {};
  public cancelButtonLabel?: string;
  public okButtonLabel?: string;
  @Output() btnClick?: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) notificationType,
    private sessionService: SessionService,
  ) {
    this.displayContent = this.dialogContents[notificationType];
    console.log(this.displayContent);
  }

  ngOnInit() {}

  btnClicked(event) {
    this.btnClick.emit(event);
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
  }
  handleOKClicked() {
    this.dialogRef.close(true);
    this.sessionService.showNavBar$.next(true);
  }
}
