import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { ToastColorOption } from '@types';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  snackBarRef: MatSnackBarRef<any>;
  constructor(private snackBar: MatSnackBar) {}

  show(msg: string, color: ToastColorOption, error?: Error): void {
    this.snackBarRef = this.snackBar.open(msg, '', {
      panelClass: `custom-toast-${color}`,
      duration: 3000,
    });
    if (error) {
      console.log(error);
    }
  }
}
