import { MessageDisplayComponent } from './components/message-display/message-display.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatSliderModule,
  MatInputModule,
  MatSelectModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatDialogModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTabsModule } from '@angular/material/tabs';

import {
  CalculateImpactedRecordsPipe,
  CustomFilterPipe,
  FormatBreadcrumbsPipe,
  FormatFieldsPipe,
  FormatPercentPipe,
  FormatStatusPipe,
  RegulatoryPipe,
} from '@shared/pipes/index';

import { MatSortModule } from '@angular/material/sort';
import {
  NavbarComponent,
  FooterComponent,
  DialogComponent,
  ModalRegulatoryComponent,
  ModalTradeDetailComponent,
  ActivityTrendChartComponent,
  DetailHeaderComponent,
  BreadcrumbsComponent,
  FixedTableComponent,
  BreadcrumbsUploadComponent,
  NestedNavbarComponent,
  LoadingComponent,
  FieldErrorDisplayComponent,
  PasswordTooltipComponent,
  ToastDialogComponent,
} from '@shared/components/index';
import { TableSortModule } from './modules/table-sort/module';
import { LunaMultiSelectModule } from './modules/luna-multi-select/module';
import { FilterSelectorModule } from './components/filter-selector/module';
import {
  ClickOutsideDirective,
  FeatureFlagDirective,
  DebounceClickDirective,
} from '@shared/directives/index';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { LunaPillGroupModule } from './modules/luna-pill-group/module';
import { PhonePipe } from './pipes/phone.pipe';
import { FilterArrayColumnPipe } from './pipes/filter-array-column.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatSliderModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxChartsModule,
    MatTabsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    NavbarComponent,
    FooterComponent,
    DialogComponent,
    ModalRegulatoryComponent,
    ModalTradeDetailComponent,
    ToastDialogComponent,
    ActivityTrendChartComponent,
    DetailHeaderComponent,
    FixedTableComponent,
    BreadcrumbsComponent,
    ClickOutsideDirective,
    NestedNavbarComponent,
    NotificationDialogComponent,
    FeatureFlagDirective,
    LoadingComponent,
    BreadcrumbsUploadComponent,
    FieldErrorDisplayComponent,
    MessageDisplayComponent,
    PasswordTooltipComponent,
    DebounceClickDirective,
    CalculateImpactedRecordsPipe,
    CustomFilterPipe,
    FormatBreadcrumbsPipe,
    FormatFieldsPipe,
    FormatPercentPipe,
    FormatStatusPipe,
    RegulatoryPipe,
    PhonePipe,
    FilterArrayColumnPipe,
  ],
  exports: [
    MatButtonModule,
    MatSliderModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    TableSortModule,
    NavbarComponent,
    FooterComponent,
    DialogComponent,
    ActivityTrendChartComponent,
    ModalRegulatoryComponent,
    ModalTradeDetailComponent,
    ToastDialogComponent,
    DetailHeaderComponent,
    FixedTableComponent,
    FieldErrorDisplayComponent,
    MessageDisplayComponent,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    BreadcrumbsComponent,
    ClickOutsideDirective,
    NestedNavbarComponent,
    NotificationDialogComponent,
    PasswordTooltipComponent,
    LoadingComponent,
    LunaMultiSelectModule,
    LunaPillGroupModule,
    FilterSelectorModule,
    FeatureFlagDirective,
    CalculateImpactedRecordsPipe,
    CustomFilterPipe,
    FormatBreadcrumbsPipe,
    FormatFieldsPipe,
    FormatPercentPipe,
    FormatStatusPipe,
    RegulatoryPipe,
    PhonePipe,
    FilterArrayColumnPipe,
  ],
  entryComponents: [
    DialogComponent,
    ModalRegulatoryComponent,
    ModalTradeDetailComponent,
    ToastDialogComponent,
    ActivityTrendChartComponent,
    PasswordTooltipComponent,
    DetailHeaderComponent,
    FixedTableComponent,
    BreadcrumbsComponent,
    NotificationDialogComponent,
    LoadingComponent,
    MessageDisplayComponent,
  ],
})
export class SharedModule {}
