import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AdminManagementType,
  UploadReportSteps,
  ClassifyResultType,
  NotificationDialogType,
  IssueAssignmentType,
} from '@types';

@Component({
  selector: 'app-nested-navbar',
  templateUrl: './nested-navbar.component.html',
  styleUrls: ['./nested-navbar.component.scss'],
})
export class NestedNavbarComponent implements OnInit {
  headers = {
    [AdminManagementType.MANAGE_TENANT]: {
      title: `Edit a tenant`,
      content: 'Tenants',
      btnText: 'Save Changes',
    },
    [AdminManagementType.CREATE_TENANT]: {
      title: 'Create a new tenant',
      content: 'Tenants',
      btnText: 'Create tenant',
    },
    [AdminManagementType.CREATE_USER]: {
      title: 'Create a new user',
      content: 'User profiles',
      btnText: 'Create User',
    },
    [AdminManagementType.MANAGE_USER]: {
      content: 'User profiles',
      title: 'Edit a user profile',
      btnText: 'Save changes',
    },
    [ClassifyResultType.DEFAULT]: {
      title: 'Classify asset classes',
      content: 'Classify results',
      btnText: 'Classify',
    },
    [IssueAssignmentType.ASSIGN_REMEDIATION_TASKS]: {
      title: 'Assign remediation tasks',
      content: 'Step #2',
      btnText: 'Assign',
    },
  };
  uploadHeaders = {
    [UploadReportSteps.SELECT_JURISDICTION]: {
      title: 'Select a jurisdiction',
      content: 'Upload reports',
    },
    [UploadReportSteps.PREPARE_FILES]: {
      title: 'Prepare your files',
      content: 'Upload reports',
    },
    [UploadReportSteps.SELECT_FILES]: {
      title: 'Select your files',
      content: 'Upload reports',
    },
    [UploadReportSteps.UPLOAD_RESULT]: {
      [NotificationDialogType.UPLOADING]: {
        title: 'Uploading your files',
        content: 'Upload reports',
      },
      [NotificationDialogType.UPLOAD_FAILED]: {
        title: 'Uploading your files',
        content: 'Upload reports',
      },
      [NotificationDialogType.UPLOAD_SUCCESS]: {
        title: 'Upload successful',
        content: 'Upload reports',
      },
    },
  };
  @Input() managementType?: string;
  @Output() handleBtnClicked = new EventEmitter<string>();
  @Input() btnActive?: boolean;
  @Input() showSaveSucceed?: boolean;
  @Input() fromPage?: string;
  @Input() curStep?: any;
  @Input() notificationType?: any;
  constructor() {}

  ngOnInit() {}

  buttonClicked() {
    this.handleBtnClicked.emit('btn');
  }

  close() {
    this.handleBtnClicked.emit('close');
  }
}
