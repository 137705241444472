import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFields',
})
export class FormatFieldsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const values = value.split('_');
      const fieldsTitles = values.map(item => {
        return _.capitalize(item);
      });
      return fieldsTitles.join(' ');
    }
  }
}
