import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@services/index';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  googleUAId = 'UA-122325794-1';
  showNavBar: boolean;
  isNarrowWidth = false;

  @HostBinding('style.background-color')
  bodyBackgroundColor: string;
  constructor(private sessionService: SessionService, private router: Router) {
    this.sessionService.initialNvOAuthService();
    this.appendGaTrackingCode();
  }

  ngOnInit() {
    this.setShowNavBar();
    this.router.events
      .pipe(
        tap(() => {
          if (
            this.router.url.includes('dashboard') ||
            this.router.url.endsWith('admin') ||
            this.router.url.endsWith('assignments')
          ) {
            this.bodyBackgroundColor = '#faf8f8';
          } else {
            this.bodyBackgroundColor = 'white';
          }
          if (
            this.router.url === '/dashboard' ||
            this.router.url === '/summary'
          ) {
            this.isNarrowWidth = true;
          } else {
            this.isNarrowWidth = false;
          }
        }),
      )
      .subscribe();
  }

  setShowNavBar() {
    this.sessionService.showNavBar$.subscribe(showNavBar => {
      this.showNavBar = showNavBar;
    });
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML =
        `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', '` +
        this.googleUAId +
        `', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
    }
  }
}
