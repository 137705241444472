import { get, every, some, filter, sortBy, debounce } from 'lodash';
import { _isNumberValue } from '@angular/cdk/coercion';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Sort, Filter } from './type';

export class TableDataSource<T> extends DataSource<T> {
  private _data: T[] = [];
  private _filter: Filter[] = [];
  private _sort: Sort = null;
  private _renderSubject: BehaviorSubject<T[]> = new BehaviorSubject([]);
  private _subscriptionList: Subscription[] = [];

  constructor(initial: T[] | Observable<T[]>) {
    super();
    // this.process = debounce(this.process, 200);
    this.data = initial;
  }

  set data(data: T[] | Observable<T[]>) {
    if (data instanceof Observable) {
      this._subscriptionList.push(data.subscribe(d => (this.data = d)));
    } else {
      this._data = data;
      this.process();
    }
  }

  set sort(sort: Sort) {
    this._sort = sort;
    this.process();
  }

  set filter(filter: Observable<Filter[]> | Filter[]) {
    if (filter instanceof Observable) {
      this._subscriptionList.push(filter.subscribe(f => (this.filter = f)));
    } else {
      this._filter = filter;
      this.process();
    }
  }

  get empty() {
    return !this._renderSubject.value.length;
  }

  private doFilter(data: T[]) {
    return filter(data, item =>
      every(this._filter, f => {
        const prop = _selectProperty(item, f.selector);
        return some(f.values, v => {
          if (typeof v === 'function') {
            return v(item);
          }
          return prop === v;
        });
      }),
    );
  }

  private doSort(data: T[]) {
    if (!this._sort) {
      return data;
    }

    const sorted = sortBy(data, item => {
      const prop = _selectProperty(item, this._sort.selector);
      if (typeof prop === 'string') {
        return prop.toLocaleLowerCase();
      }
      return prop;
    });

    return this._sort.isAsc ? sorted : sorted.reverse();
  }

  private process() {
    const filtered = this.doFilter(this._data);
    const sorted = this.doSort(filtered);
    this._renderSubject.next(sorted);
  }

  connect() {
    return this._renderSubject;
  }
  disconnect() {
    this._subscriptionList.forEach(sub => sub.unsubscribe());
    this._subscriptionList = [];
  }
}

const _selectProperty = (
  row: any,
  selector: string | ((row: object) => any),
) => {
  if (typeof selector === 'string') {
    return get(row, selector);
  }
  return selector(row);
};
