import { NgModule } from '@angular/core';
import { TableSort } from './tableSort';
import { TableSortHeader } from './tableSortHeader';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [TableSort, TableSortHeader],
  declarations: [TableSort, TableSortHeader],
})
export class TableSortModule {}
