import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStatus',
})
export class FormatStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value instanceof Array && value[1] !== null) {
      return value[1].filter(item => item.status === value[0].toUpperCase())
        .length;
    }
  }
}
