import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  AllowedStatuses,
  BatchTestInput,
  ChangeAssetTestStatusGQL,
  CreateBatchTestCommentGQL,
  DeleteCommentGQL,
  GetAuditLogsInput,
  GetAuditLogsGQL,
  GetBatchTestGQL,
  GetComments,
  GetCommentsGQL,
  GetCommentInput,
  GetResultStatusesGQL,
  GetRemediationsGQL,
  UpdateCommentGQL,
  GetAuditLogs,
  GetResultStatuses,
  GetBatchTest,
  UpdateComment,
  DeleteComment,
} from '@generatedTypes/graphql';
import { TestStatusEnum } from '@types';

@Injectable({
  providedIn: 'root',
})
export class BatchTestService {
  batch_test$: BehaviorSubject<any> = new BehaviorSubject(null);
  auditLogs$: any = new BehaviorSubject(null);

  constructor(
    private getBatchTestGQL: GetBatchTestGQL,
    private changeAssetTestStatusGQL: ChangeAssetTestStatusGQL,
    private createBatchTestCommentGQL: CreateBatchTestCommentGQL,
    private updateCommentGQL: UpdateCommentGQL,
    private deleteCommentGQL: DeleteCommentGQL,
    private getCommentsGQL: GetCommentsGQL,
    private getResultStatusGQL: GetResultStatusesGQL,
    private getAuditLogsGQL: GetAuditLogsGQL,
    private getRemediationsGQL: GetRemediationsGQL,
  ) {}

  createBatchTestComment(batchTestKey: string, commentText: string) {
    return this.createBatchTestCommentGQL.mutate(
      { batchTestKey, commentText },
      {
        fetchPolicy: 'no-cache',
      },
    );
  }

  updateComment(
    commentKey: string,
    commentText: string,
    getCommentInput: GetCommentInput,
  ): Observable<any> {
    return this.updateCommentGQL.mutate(
      { commentKey, commentText },
      {
        fetchPolicy: 'no-cache',
        refetchQueries: [
          {
            query: this.getCommentsGQL.document,
            variables: {
              input: getCommentInput,
            },
          },
        ],
      },
    );
  }

  deleteComment(commentKey: string): Observable<any> {
    return this.deleteCommentGQL.mutate(
      { commentKey },
      {
        fetchPolicy: 'no-cache',
      },
    );
  }

  getBatchTest(input: BatchTestInput): Observable<GetBatchTest.GetBatchTest> {
    return this.getBatchTestGQL
      .watch({ input }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => data.getBatchTest),
        tap(data => this.batch_test$.next(data)),
      );
  }

  getComments(input: GetCommentInput): Observable<GetComments.GetComments> {
    return this.getCommentsGQL
      .watch(
        {
          input,
        },
        { fetchPolicy: 'network-only' },
      )
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getComments;
        }),
      );
  }

  getCommentsQuery(input?: GetCommentInput) {
    // bare Apollo QueryRef needed for fetchmore
    return this.getCommentsGQL.watch(
      {
        input,
      },
      { fetchPolicy: 'network-only' },
    );
  }

  getBatchTestStatusFromAssetTests(asset_tests: any) {
    const statusMap = {
      [TestStatusEnum.REVIEW_REQUIRED]: 0,
      [TestStatusEnum.ISSUE_CONFIRMED]: 0,
      [TestStatusEnum.NO_ISSUE]: 0,
      [TestStatusEnum.PASSED]: 0,
    };

    asset_tests.forEach(asset_test => {
      const result_status = asset_test.asset_test_activity[0].result_status;
      statusMap[result_status]++;
    });
    let newStatus = TestStatusEnum.REVIEW_REQUIRED;
    if (statusMap[TestStatusEnum.REVIEW_REQUIRED] > 0) {
      newStatus = TestStatusEnum.REVIEW_REQUIRED;
    } else if (statusMap[TestStatusEnum.ISSUE_CONFIRMED] > 0) {
      newStatus = TestStatusEnum.ISSUE_CONFIRMED;
    } else if (statusMap[TestStatusEnum.NO_ISSUE] > 0) {
      newStatus = TestStatusEnum.NO_ISSUE;
    }
    return newStatus;
  }

  changeAssetTestStatus(
    assetTestKey: string,
    resultStatus: AllowedStatuses,
    batchTestKey: string,
    comment?: string,
  ): Observable<any> {
    return this.changeAssetTestStatusGQL.mutate(
      { assetTestKey, resultStatus, batchTestKey, comment },
      {
        fetchPolicy: 'no-cache',
        refetchQueries: [
          {
            query: this.getBatchTestGQL.document,
            variables: {
              input: {
                batch_test_key: batchTestKey,
                batch_key: null,
                result_id: null,
              },
            },
          },
          {
            query: this.getRemediationsGQL.document,
            variables: {
              input: {
                batch_test_key: batchTestKey,
                batch_key: null,
                result_id: null,
              },
            },
          },
        ],
      },
    );
  }

  getAuditLogs(
    input: GetAuditLogsInput,
  ): Observable<GetAuditLogs.GetAuditLogs[]> {
    return this.getAuditLogsGQL
      .watch({ input }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        take(1),
        map(({ data }) => data.getAuditLogs),
      );
  }

  getResultStatus(): Observable<GetResultStatuses.GetResultStatuses[]> {
    return this.getResultStatusGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        take(1),
        map(({ data }) => data.getResultStatuses),
      );
  }
}
