import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(public dialog: MatDialog) {}
  isShown = false;
  width = '50%';
  currentYear: number;

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
  openDialog(): void {
    const dialogReF = this.dialog.open(DialogComponent, {
      width: this.width,
      maxWidth: '448px',
    });
  }
}
