import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { TableSort } from './tableSort';
import { CdkColumnDef } from '@angular/cdk/table';
import { Sort, Selector } from './type';

enum SORT_STATUS {
  NA = 0,
  ASC = 1,
  DESC = 2,
}

@Component({
  selector: '[tableSortHeader]',
  exportAs: 'matSortHeader',
  templateUrl: 'tableSortHeader.html',
  styleUrls: ['tableSortHeader.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class TableSortHeader {
  @Input('tableSortHeader') selector: string | Selector;
  // @Input() arrowPosition: 'before' | 'after' = 'after';

  @ViewChild('up', { static: true }) _upRef: ElementRef;
  @ViewChild('down', { static: true }) _downRef: ElementRef;

  private _status: SORT_STATUS = SORT_STATUS.NA;

  constructor(
    private _sort: TableSort,
    private _hostRef: ElementRef,
    private _columnDef: CdkColumnDef,
  ) {
    if (!_sort) {
      throw new Error('tableSortHeader must be contained inside tableSort.');
    }
    this._sort.sortChange.subscribe((sort: Sort) => {
      if (!sort || sort.columnId !== this._columnDef.name) {
        this._status = SORT_STATUS.NA;
      } else {
        this._status = sort.isAsc ? SORT_STATUS.ASC : SORT_STATUS.DESC;
      }
      this._handleUpdate();
    });
  }

  get active() {
    return this._status !== SORT_STATUS.NA;
  }
  get isAsc() {
    return this._status === SORT_STATUS.ASC;
  }
  get isDesc() {
    return this._status === SORT_STATUS.DESC;
  }

  _handleUpdate() {
    if (this.active) {
      this._hostRef.nativeElement.classList.add('sort-active');
    } else {
      this._hostRef.nativeElement.classList.remove('sort-active');
    }
  }

  @HostListener('click', ['$event.target'])
  _handleClick(dom) {
    let status = this._status;
    if (dom === this._upRef.nativeElement) {
      status = this.isAsc ? SORT_STATUS.NA : SORT_STATUS.ASC;
    } else if (dom === this._downRef.nativeElement) {
      status = this.isDesc ? SORT_STATUS.NA : SORT_STATUS.DESC;
    } else {
      status = (this._status + 1) % 3;
    }

    if (status === SORT_STATUS.NA) {
      this._sort.setSort(null);
    } else {
      this._sort.setSort({
        columnId: this._columnDef.name,
        selector: this.selector,
        isAsc: status === SORT_STATUS.ASC,
      });
    }
  }
}
