import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss'],
})
export class MessageDisplayComponent implements OnInit {
  @Input() message: string;
  @Input() messageStatus: boolean;
  @Input() hasResend?: boolean;
  @Output() handleClose?: EventEmitter<any> = new EventEmitter();
  @Output() handleResendEmit?: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeNotification() {
    // Either messageStatus is false or true will always display, set messageStatus to null if you want to disappear message
    this.message = '';
    this.messageStatus = null;
    localStorage.removeItem('inviteUserFailed');
    this.handleClose.emit('close');
  }

  handleResendClicked(event) {
    if (event) {
      this.handleResendEmit.emit();
    }
  }
}
