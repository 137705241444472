import { HttpHeaders } from '@angular/common/http';
export namespace TradeDetail {
  export interface QueryParams {
    asset_test_result_key?: string;
    report_type?: string;
  }
  export interface UrlParams {
    id: string;
    class: string;
    type: string;
  }
}

export enum DashboardCharts {
  ActivityTrend = 'activityTrend',
  ActivityTrendDetails = 'activityTrendDetails',
  HighPasses = 'highPasses',
  FailureRate = 'failureRate',
  TotalRecords = 'totalRecords',
  HighPassesDetails = 'highPassesDetails',
}
export interface ReportTypes {
  submission: Array<ReportValues>;
  position?: Array<ReportValues>;
}

export interface ReportValues {
  data?: Object[];
  entryName?: string;
  name?: string;
  reportName?: string;
}

export enum ReportTypesEnum {
  POSITION = 'Position',
  SUBMISSION = 'Submission',
}

export enum TestStatusEnum {
  REVIEW_REQUIRED = 'Review required',
  ISSUE_CONFIRMED = 'Issue confirmed',
  CONFIRM_ISSUE = 'Confirm issue',
  NO_ISSUE = 'No issue',
  NO_DATA = 'No data',
  PASSED = 'Passed',
}

export interface ChartValueData {
  name: string;
  value: number;
}
export interface LineChartData {
  name: TestStatusEnum;
  series: ChartValueData[];
}

export interface LineChartDetailData {
  name: string;
  chartData: LineChartData[];
}

export interface FailureRateChartInputItem {
  assetName: string;
  name: string;
  series: ChartSeries[];
}

export interface ChartSeries {
  name: string;
  value: number;
}
export interface ChartPercentData {
  value: number;
  percent: number;
}
export interface PositionAndSubmission {
  highPass: ChartPercentData;
  lowPass: ChartPercentData;
}

export interface ChartReportData {
  position: PositionAndSubmission;
  submission: PositionAndSubmission;
}

export interface HttpErrorResponseInterface extends Error {
  error: string;
  headers: HttpHeaders;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}

export interface SignupQueryParams {
  email: string;
  registrationToken: string;
  orgId: string;
}
export interface OrganizationOperationResult {
  message: string;
  status: string;
  organization: OrganizationDetails;
}

export interface OrganizationDetails {
  roduct: string;
  organizationId: any;
  organizationName: string;
  organizationStatus: string;
  onboardAdminEmail: string;
  allowedDomains: string;
  restrictedDomains: string;
}

export interface FeatureTypes {
  Json: any;
}

export enum AssetClassNamesEnum {
  CREDIT = 'Credit',
  RATES = 'Rates',
  FOREIGN_EXCHANGE = 'Foreign Exchange',
  EQUITIES = 'Equities',
  COMMODITIES = 'Commodities',
  EXCHANGE_TRADED_DERIVATIVES = 'Exchange Traded Derivatives',
}

export enum UploadReportSteps {
  SELECT_JURISDICTION = 'step1',
  PREPARE_FILES = 'step2',
  SELECT_FILES = 'step3',
  UPLOAD_RESULT = 'step4',
}

export enum NotificationDialogType {
  UPLOADING = 'uploading',
  UPLOAD_SUCCESS = 'success',
  UPLOAD_FAILED = 'failed',
  UNSUPPORTED_FILE = 'unsupported_file',
  REMOVE_FILE = 'remove_file',
  MISSING_FILES = 'missing_files',
  DUPLICATE_FILES = 'duplicate_files',
  UNSAVED_CHANGES = 'leave',
  DEACTIVATE_TENANT = 'deactivate',
}

export enum AdminManagementType {
  CREATE_USER = 'create_user',
  MANAGE_USER = 'manage_user',
  CREATE_TENANT = 'create_tenant',
  MANAGE_TENANT = 'manage_tenant',
}
export enum AdminManagementActionResult {
  CREATE_USER_SUCCESS = 'creat_user_success',
  CREATE_USER_FAILED = 'create_user_failed',
  UPDATE_USER_SUCCESS = 'update_user_success',
  CREATE_TENANT_SUCCESS = 'creat_tenant_success',
  UPDATE_TENANT_SUCCESS = 'modify_tenant_success',
  LEAVE_FIELDS_INCOMPLETE = 'leave_fields_incomplete',
  UPDATE_USER_FAILED = 'update_user_failed',
}

export enum ClassifyResultType {
  DEFAULT = 'default',
  SAVE_SUCCESS = 'save_success',
  LEAVE_UNSAVED = 'leave_unsaved',
  ASSET_CLASSS_UNCLASSIFIED = 'asset_class_unclassified',
  MISSING_COMMENTS = 'missing_comments',
  OVERWRITE_EXISTING = 'overwrite_existing',
}
export enum SortAsType {
  STRING,
  NUMBER,
  DATE,
  TIME,
}

export enum IssueAssignmentType {
  ASSIGN_REMEDIATION_TASKS = 'assign_remediation_tasks',
}

export enum ToastColorOption {
  SUCCESS = 'success',
  ERROR = 'error',
}
export enum RemediationManagement {
  MISSING_RCOMMENTS = 'missing_rcomments',
  REMDEDIATION_COMPLETE = 'remediation_complete',
  PAST = 'past',
  INCOMPLETE = 'incomplete',
  CLASSIFY = 'classify',
  LEAVE_ASSIGN = 'leave_assign',
  LEVAE_CLASSIFICATION = 'leave_classification',
  OVERWRITE_INFORMATION = 'overwrite_information',
}

export enum RiskStatus {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum PasswordTypeEnum {
  text = 'text',
  password = 'password',
}

export namespace TenantOptions {
  export enum Features {
    UPLOAD_REPORT = 'uploadReport',
    DIRECT_DOWNLOAD_TRADE_DETAILS = 'directDownloadTradeDetails',
  }
}

export enum IssueTypes {
  ASSIGNMENTS = 'Your assignments',
  ISSUES = 'All issues',
}

export enum IssueFilters {
  ALL = 'All',
  OPEN = 'Open',
  RESOLVED = 'Resolved',
}

export enum IssueStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export enum AdminType {
  USER = 'user',
  TENANT = 'tenant',
  ACTIVE = 'Active',
  DEACTIVE = 'Deactivated',
  TRUE = 'true',
  FALSE = 'false',
  DISABLED = 'disabled',
}

export enum AccessOrganizationStatusEnum {
  ACTIVATED = 'Activated',
  DEACTIVATED = 'Deactivated',
}
export enum RegisteredStatusTypeEnum {
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  SUCCESS = 'SUCCESS',
  REGISTRATION_PENDING = 'REGISTRATION_PENDING',
}

export enum RegulatorEnum {
  EMIR = 'EMIR',
}
