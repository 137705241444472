import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ToastService } from '@services/toast.service';
import {
  DownloadTradeDetailsGQL,
  DownloadTradeDetails,
  GetTradeDetailsGQL,
  TradeDetailsInput,
  GetTradeDetails,
  GetTradeDetailConfigurationGQL,
  GetTradeDetailConfiguration,
  UpsertTradeDetailConfigurationGQL,
  TradeDetailConfigurationInput,
  UpsertTradeDetailConfiguration,
} from '@generatedTypes/graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradeDetailService {
  constructor(
    private getTradeDetailsGQL: GetTradeDetailsGQL,
    private getTradeDetailConfigurationGQL: GetTradeDetailConfigurationGQL,
    private upsertTradeDetailConfigurationGQL: UpsertTradeDetailConfigurationGQL,
    private downloadTradeDetailsGQL: DownloadTradeDetailsGQL,
    private toastService: ToastService,
  ) {}

  getTradeDetails(
    input: TradeDetailsInput,
  ): Observable<GetTradeDetails.GetTradeDetails[]> {
    return this.getTradeDetailsGQL
      .watch(input, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(map(response => response.data.getTradeDetails));
  }

  getTradeDetailConfiguration(
    batchTestkey: string,
  ): Observable<GetTradeDetailConfiguration.GetTradeDetailConfiguration> {
    return this.getTradeDetailConfigurationGQL
      .watch({ batch_test_key: batchTestkey }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(response => response.data.getTradeDetailConfiguration),
      );
  }

  upsertTradeDetailConfiguration(
    tradeDetailConfigurationInput: TradeDetailConfigurationInput,
  ): Observable<UpsertTradeDetailConfiguration.Mutation> {
    return this.upsertTradeDetailConfigurationGQL
      .mutate(
        {
          input: {
            batch_test_key: tradeDetailConfigurationInput.batch_test_key,
            configuration: tradeDetailConfigurationInput.configuration,
          },
        },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getTradeDetailConfigurationGQL.document,
              variables: {
                input: {
                  batch_test_key: tradeDetailConfigurationInput.batch_test_key,
                },
              },
            },
          ],
        },
      )
      .pipe(
        map(response => {
          return response.data;
        }),
      );
  }
  downloadTradeDetails(
    input: DownloadTradeDetails.Variables,
  ): Observable<DownloadTradeDetails.Mutation> {
    return this.downloadTradeDetailsGQL
      .mutate(input, { fetchPolicy: 'no-cache' })
      .pipe(
        map(response => {
          return response.data;
        }),
      );
  }
}
