import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AcceptTermsOfAgreementsGQL,
  GetTermsOfAgreementsGQL,
  GetTermsOfAgreements,
  AcceptTermsOfAgreements,
} from '@generatedTypes/graphql';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  constructor(
    private acceptTermsOfAgreementsGQL: AcceptTermsOfAgreementsGQL,
    private getTermsOfAgreementsGQL: GetTermsOfAgreementsGQL,
  ) {}

  getTermsOfAgreements(): Observable<
    GetTermsOfAgreements.GetTermsOfAgreements
  > {
    return this.getTermsOfAgreementsGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(map(response => response.data.getTermsOfAgreements));
  }

  acceptTermsOfAgreements(
    termsKey: string,
  ): Observable<AcceptTermsOfAgreements.AcceptTermsOfAgreements> {
    return this.acceptTermsOfAgreementsGQL
      .mutate({ termsKey }, { fetchPolicy: 'no-cache' })
      .pipe(map(response => response.data.acceptTermsOfAgreements));
  }
}
