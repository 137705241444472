import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@services/index';
@Injectable({
  providedIn: 'root',
})
export class TermsGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.userService.getUser().pipe(
      map(val => {
        if (
          !val.agreed_latest_terms &&
          !this.userService.agreedLatestTerms$.value
        ) {
          this.router.navigateByUrl('/terms-of-service');
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
