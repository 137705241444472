import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value = '', ...args: any[]): any {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, (txt, f, s, t) => {
        if (t) {
          return `(${f}) ${s}-${t}`;
        } else if (s) {
          return `(${f}) ${s}`;
        } else if (f) {
          if (f.length > 3) {
            return `(${f})`;
          } else {
            return f;
          }
        }
      });
  }
}
