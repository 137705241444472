import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';

@Pipe({
  name: 'regulatory',
})
export class RegulatoryPipe implements PipeTransform {
  transform(value: any): any {
    const values = sortBy(value, 'short_nme');
    const appxGroup = values.filter(v => v.short_nme.match(/appx/i));
    const numericGroup = values.filter(v => !v.short_nme.match(/appx/i));
    const orderedValues = [].concat(numericGroup).concat(appxGroup);
    return orderedValues;
  }
}
