import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-toast-dialog',
  templateUrl: './toast-dialog.component.html',
  styleUrls: ['./toast-dialog.component.scss'],
})
export class ToastDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ToastDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  ngOnInit() {}
  handleCloseClicked(type?: string) {
    this.dialogRef.close(type);
  }
}
