import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsOfServiceComponent } from './routes/terms-of-service/terms-of-service.component';
import { TermsComponent } from './routes/terms-of-service/terms/terms.component';
import { AuthRedirectComponent } from './routes/auth-redirect/auth-redirect.component';
import {
  AdminGuard,
  AuthGuard,
  TermsGuard,
  VerifyEmailGuard,
} from '@guards/index';
const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: './routes/welcome/welcome.module#WelcomeModule',
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./routes/verify-email/verify-email.module').then(
        s => s.VerifyEmailModule,
      ),
    canActivate: [VerifyEmailGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./routes/signup/signup.module').then(r => r.SignupModule),
  },
  {
    path: 'uploadReport',
    loadChildren: () =>
      import('./routes/upload-report/upload-report.module').then(
        m => m.UploadReportModule,
      ),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./routes/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('./routes/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule,
      ),
  },
  {
    path: 'results',
    pathMatch: 'prefix',
    component: AuthRedirectComponent,
  },
  {
    path: 'summary',
    loadChildren: () =>
      import('./routes/summary/summary.module').then(m => m.ResultsModule),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./routes/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'result/:resultId/:class',
    loadChildren: () =>
      import('./routes/result-detail/result-detail.module').then(
        m => m.ResultDetailModule,
      ),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'assignments',
    loadChildren: () =>
      import('./routes/assignments/assignments.module').then(
        m => m.AssignmentsModule,
      ),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./routes/dashboard/dashboard.module').then(
        m => m.DashboardModule,
      ),
    canActivate: [AuthGuard, TermsGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./routes/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, TermsGuard, AdminGuard],
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./routes/unauthorized/unauthorized.module').then(
        m => m.UnauthorizedModule,
      ),
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: AuthRedirectComponent,
  },
  {
    path: '**',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  // imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
