import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LunaMultiSelectModule } from '../../modules/luna-multi-select/module';
import { FilterSelectorComponent } from './filter-selector.component';
import { LunaPillGroupModule } from 'app/shared/modules/luna-pill-group/module';

@NgModule({
  imports: [CommonModule, LunaMultiSelectModule, LunaPillGroupModule],
  exports: [FilterSelectorComponent],
  declarations: [FilterSelectorComponent],
})
export class FilterSelectorModule {}
