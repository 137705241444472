import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs-upload',
  templateUrl: './breadcrumbs-upload.component.html',
  styleUrls: ['./breadcrumbs-upload.component.scss'],
})
export class BreadcrumbsUploadComponent implements OnInit {
  totalSteps = 3;
  @Input() curStep: number;
  steps: number[];
  constructor() {}

  ngOnInit() {
    this.steps = new Array(this.totalSteps).fill(0);
  }
}
