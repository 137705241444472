import { Pipe, PipeTransform } from '@angular/core';
import { head, lowerCase, get } from 'lodash';
import { GetBatchSummary } from '@generatedTypes/graphql';
import { ReportTypesEnum } from '@types';

interface FilterItems {
  groupDisplay: string;
  itemDisplays: string[];
  selector: string;
}

enum FilterImpactingTypes {
  FAILURES_IMPACTING = 'Failures impacting',
}

@Pipe({
  name: 'calculateImpactedRecords',
})
export class CalculateImpactedRecordsPipe implements PipeTransform {
  transform(value: GetBatchSummary.BatchTests, args: FilterItems[]): number {
    const filter = args.find(
      f => f.selector === FilterImpactingTypes.FAILURES_IMPACTING,
    );
    if (!filter) {
      return value.nbr_failed;
    }

    const count = value.failures_impacting
      .filter(item =>
        filter['values'].some(f => f({ failures_impacting: [item] })),
      )
      .reduce((pre, item) => pre + item.nbr_failed, 0);

    return (value['__nbr_failed'] = count);
  }
}
