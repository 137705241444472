import { Injectable } from '@angular/core';
import { ToastService } from '@services/toast.service';
import { map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import {
  chain,
  sumBy,
  merge,
  filter,
  fill,
  sortBy,
  transform,
  zipObject,
  forOwn,
  uniq,
  get,
} from 'lodash';

import { UtilService } from '@services/util.service';
import * as moment from 'moment';
import { AssetClassNamesEnum, RegulatorEnum } from '../types';
import {
  ActivityTrendsInput,
  ActivityTrendsDetailInput,
  GetActivityTrendsChartGQL,
  GetAssetClasses,
  GetAssetClassesGQL,
  GetActivityTrendsChart,
  GetActivityTrendsDetailChartGQL,
  GetActivityTrendsDetailChart,
  GetEarliestAssetActivityDateGQL,
  GetHighPassesChartGQL,
  GetTotalRecordsChartGQL,
  GetTotalRecordsChart,
  GetFailureRateRecordsChartGQL,
  GetFailureRateRecordsDeatilChartGQL,
  GetFailureRateRecordsChart,
  GetFailureRateRecordsDeatilChart,
  GetHighPassesChart,
  GetHighPassesDetailChartGQL,
  GetTestKindsGQL,
  GetTestKinds,
  GetHighPassesDetailChart,
} from '@generatedTypes/graphql';
import { LineChartData, LineChartDetailData, ReportTypes } from '@types';
import { SessionService } from '@services/session.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  assetClasses: any;
  testKinds: any;

  ASSET_CLASS_ORDERED = [
    'Credit',
    'Rates',
    'Equities',
    'Foreign Exchange',
    'Commodities',
    'ETD',
  ];
  RESPONSIBLE_PARTY_ORDERED = {
    position: ['Our Firm', '3rd Party', 'SEF/DCO', 'Counterparty'],
    submission: [
      'RT',
      'PET',
      'RT-PET',
      'RT-PET-CONFIRM',
      'PET-CONFIRM',
      'CONFIRM',
      'SNAPSHOT',
      'DOCUMENT',
      'VALUATION',
    ],
  };
  constructor(
    private toastService: ToastService,
    private getActivityTrendsChartGQL: GetActivityTrendsChartGQL,
    private getActivityTrendsDetailChartGQL: GetActivityTrendsDetailChartGQL,
    private getFailureRateRecordsChartGQL: GetFailureRateRecordsChartGQL,
    private getTotalRecordsDataGQL: GetTotalRecordsChartGQL,
    private getHighPassesChartGQL: GetHighPassesChartGQL,
    private getHighPassesDeatilChartGQL: GetHighPassesDetailChartGQL,
    private getFailureRateRecordsDeatilChartGQL: GetFailureRateRecordsDeatilChartGQL,
    private getAssetClassesGQL: GetAssetClassesGQL,
    private getTestKindsGQL: GetTestKindsGQL,
    private getEarliestAssetActivityDateGQL: GetEarliestAssetActivityDateGQL,
    private utilService: UtilService,
    private sessionService: SessionService,
  ) {
    const $assetClassesData = this.getAssetClassesData();
    const $testKinds = this.getTestKindsData();
    this.sessionService.selectedRegulator$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return combineLatest([$assetClassesData, $testKinds]);
        }),
      )
      .subscribe(([assetClassesData, testKinds]) => {
        this.assetClasses = assetClassesData
          .map(assetClass => {
            const isETD = [
              AssetClassNamesEnum.EXCHANGE_TRADED_DERIVATIVES,
              'ETD',
            ].includes(assetClass.nme);

            if (
              isETD &&
              localStorage.getItem('regulator') === RegulatorEnum.EMIR
            ) {
              return Object.assign(assetClass, { nme: 'ETD' });
            } else if (!isETD) {
              return assetClass;
            }
          })
          .filter(u => u);
        this.testKinds = testKinds;
      });
  }

  // ************************************************
  //               QUERIES START
  // ************************************************

  getFailureRateByCategory(
    regulator_name: string,
  ): Observable<GetFailureRateRecordsChart.FailureRateReport[]> {
    return this.getFailureRateRecordsChartGQL
      .watch({ regulator_name }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(response => response.data.getReport.failure_rate_report),
      );
  }

  getFailureRateDetailChart(
    regulator_name: string,
  ): Observable<GetFailureRateRecordsDeatilChart.FailureRateDetailReport[]> {
    return this.getFailureRateRecordsDeatilChartGQL
      .watch({ regulator_name }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(response => response.data.getReport.failure_rate_detail_report),
      );
  }

  getHighPassReportData(
    regulator_name: string,
  ): Observable<GetHighPassesChart.HighPassReport[]> {
    return this.getHighPassesChartGQL
      .watch({ regulator_name }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getReport.high_pass_report;
        }),
      );
  }
  getHighPassReportDetailsData(
    regulator_name: string,
  ): Observable<GetHighPassesDetailChart.HighPassDetailReport[]> {
    return this.getHighPassesDeatilChartGQL
      .watch({ regulator_name }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getReport.high_pass_detail_report;
        }),
      );
  }

  getTotalRecordsData(
    regulator_name: string,
  ): Observable<GetTotalRecordsChart.TotalRecordsReport[]> {
    return this.getTotalRecordsDataGQL
      .watch({ regulator_name }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getReport ? data.getReport.total_records_report : [];
        }),
      );
  }

  getActivityTrendsData(
    input: ActivityTrendsInput,
  ): Observable<GetActivityTrendsChart.ActivityTrendsReport[]> {
    return this.getActivityTrendsChartGQL
      .watch(input)
      .valueChanges.pipe(
        map(response => response.data.getReport.activity_trends_report),
      );
  }
  getActivityTrendsDetailData(
    input: ActivityTrendsDetailInput,
  ): Observable<GetActivityTrendsDetailChart.ActivityTrendsDetailReport[]> {
    return this.getActivityTrendsDetailChartGQL
      .watch(input)
      .valueChanges.pipe(
        map(response => response.data.getReport.activity_trends_detail_report),
      );
  }

  getEarliestAssetActivityDate(regulator_name: string): Observable<any> {
    return this.getEarliestAssetActivityDateGQL
      .watch({ regulator_name })
      .valueChanges.pipe(
        map(response => response.data.getBatches[0].report_date),
      );
  }

  getAssetClassesData(): Observable<GetAssetClasses.GetAssetClasses[]> {
    return this.getAssetClassesGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(map(response => response.data.getAssetClasses));
  }

  getTestKindsData(): Observable<GetTestKinds.GetTestKinds[]> {
    return this.getTestKindsGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(map(response => response.data.getTestKinds));
  }

  // ************************************************
  //                QUERIES - END
  // ************************************************

  // ************************************************
  //               HELPER START
  // ************************************************
  calculateAssetClass(data: any, displayType: string, chartType: string) {
    const packageAssetData = data.map(item => {
      const packageAssetOrders = item.data
        .map(v => {
          return v.displayOrder;
        })
        .sort();
      this.assetClasses.forEach(asset => {
        const c = packageAssetOrders.findIndex(b => asset.order === b);
        if (c > -1) {
          delete packageAssetOrders[c];
        } else {
          item.data.push(
            displayType === 'failureRate'
              ? this.formatFailureRateChartDetail(
                  asset.nme,
                  asset.order,
                  chartType,
                )
              : this.formatHighChartDetail(asset.nme, asset.order),
          );
        }
      });

      item = { ...item, data: sortBy(item.data, 'displayOrder') };

      return item;
    });
    return packageAssetData;
  }

  calculateEvaluated(assetData: any[]) {
    let highEvaluated = 0;
    let lowEvaluated = 0;

    filter(assetData, ['high_pass', true]).map((v: any, k) => {
      highEvaluated += v.count;
    });
    filter(assetData, ['high_pass', false]).map((v: any, k) => {
      lowEvaluated += v.count;
    });
    const total =
      highEvaluated + lowEvaluated > 0 ? highEvaluated + lowEvaluated : 0;
    return [highEvaluated, lowEvaluated, total];
  }

  calculateDisplayOrder(testData: [], className: string, testKey: string) {
    let displayOrder = 0;
    testData.filter(asset => {
      if (asset['nme'] === className) {
        displayOrder = asset[testKey];
      }
    });
    return displayOrder;
  }

  getDisplayOrder(classNme): number {
    return this.ASSET_CLASS_ORDERED.indexOf(classNme) + 1;
  }

  // ************************************************
  //               HELPER END
  // ************************************************

  // ************************************************
  //                CHART SPECIFIC - START
  // ************************************************
  formatHighChartDetailData(data): ReportTypes {
    const reportData = ['submission', 'position'].map(type => {
      const asset = chain(data)
        .filter(item => item.report_name.toLowerCase() === type)
        .value();
      const packageEntryFromatData = chain(asset)
        .groupBy(c => c.report_entry_name)
        .map((v, k) => {
          return {
            name: k,
            data: chain(v)
              .groupBy(a => a.asset_class_name)
              .map((v, k) => {
                const a = chain(v)
                  .groupBy(a => a.report_entry_name)
                  .value();
                return {
                  name: k,
                  displayOrder: this.getDisplayOrder(k),
                  series: chain(a)
                    .map((v, k) => {
                      const [
                        highEvaluated,
                        lowEvaluated,
                        total,
                      ] = this.calculateEvaluated(v);
                      return [
                        {
                          name: 'High Pass results', // if changed here, must change "*ngIf" on html
                          // assetName: assetName,
                          value: highEvaluated,
                          percent: (highEvaluated / total).toFixed(2),
                        },
                        {
                          name: 'Low Pass results', // if changed here, must change "*ngIf" on html
                          // assetName: assetName,
                          value: lowEvaluated,
                          percent: (lowEvaluated / total).toFixed(2),
                        },
                      ];
                    })
                    .value()[0],
                };
              })
              .value(),
          };
        })
        .value();
      return packageEntryFromatData;
    });

    return {
      submission: this.calculateAssetClass(
        reportData[0],
        'highChart',
        'submission',
      ),
      [this.utilService.getPositionKey()]: this.calculateAssetClass(
        reportData[1],
        'highChart',
        'position',
      ),
    };
  }

  formatHighPassChartData(data) {
    const series = data.map(asset => {
      const formattedAsset = asset.reports.map(report => {
        const formattedReport = this.formatHighReport(report, asset.assetName);

        return formattedReport;
      });
      return {
        assetName: asset.assetName,
        data:
          this.utilService.getPositionKey() === 'position'
            ? sortBy(formattedAsset, 'name')
            : sortBy(formattedAsset, 'name').reverse(),
      };
    });
    return series;
  }

  formatFailureChartData(data) {
    const formattedData = data.map(testKind => {
      const series = testKind.reports.map(report => {
        return {
          name: report.name,
          value: report.value,
        };
      });
      const formattedObject = {
        assetName: this.utilService.titleCase(testKind.name),
        name: this.utilService.titleCase(testKind.name), // capitalize the first letter,
        series,
      };
      return formattedObject;
    });
    return formattedData;
  }

  formatDataForTotalRecords(data) {
    const reportTypes = uniq(data.map(item => item.report_type));
    const reportData = reportTypes.map((type: string) => {
      const totalRecordsReport = chain(data)
        .filter(item => item.report_type === type)
        .groupBy(c => c.asset_class)
        .value();
      const totalRecordFormat = {
        Report: this.utilService.getPositionKey(type),
      };

      chain(totalRecordsReport)
        .map((v, k) => {
          totalRecordFormat[k] = sumBy(v, 'count');
        })
        .value();
      return totalRecordFormat;
    });

    const assetClasses = this.filterAssetClassByRegulator(this.assetClasses);
    return { reportData, assetClasses };
  }

  formatDataForTotalDetailRecords(data) {
    const reportTypes = uniq(data.map(item => item.report_type));
    const reportData = reportTypes.map(type => {
      const totalRecordsReport = chain(data)
        .filter(item => item.report_type === type)
        .groupBy(c => c.report_entry_type)
        .value();

      const totalRecordDetailFormat = [];

      chain(totalRecordsReport)
        .map((v, k) => {
          const totalRecordFormat = {};
          totalRecordFormat['Report'] = k;
          chain(v)
            .map(item => {
              totalRecordFormat[item.asset_class] = item.count;
            })
            .value();
          totalRecordDetailFormat.push(totalRecordFormat);
        })
        .value();
      return totalRecordDetailFormat;
    });

    if (reportTypes && reportTypes.length > 0) {
      const fileteredArray = Object.keys(reportTypes);
      const includedParties = fill(Array(Object.keys(reportTypes).length), []);

      fileteredArray.forEach(index => {
        for (data of reportData[index]) {
          includedParties[index].push(data.Report.toLowerCase());
        }
      });
      Object.values(this.RESPONSIBLE_PARTY_ORDERED).forEach(
        (sortedParties, index) => {
          sortedParties.forEach(party => {
            if (
              includedParties.length - 1 <= index &&
              includedParties[index] &&
              !includedParties[index].includes(party.toLowerCase())
            ) {
              reportData[index].push({ Report: party });
            }
          });
        },
      );
      const assetClasses = this.filterAssetClassByRegulator(this.assetClasses);
      return {
        [this.utilService.getPositionKey()]: reportData[0],
        submission: reportData[1],
        assetClasses,
      };
    }
  }

  filterAssetClassByRegulator(assetClasses): string[] {
    if (this.sessionService.selectedRegulator$.value === 'CFTC') {
      return assetClasses
        .map(item => item.nme)
        .filter(item => item !== 'Exchange Traded Derivatives');
    } else {
      return assetClasses.map(item => item.nme);
    }
  }

  formatActivityTrendGeneralData(
    data: GetActivityTrendsChart.ActivityTrendsReport,
    xAxisTicks: string[],
  ): LineChartData[] {
    const displayKeys = ['Review required', 'Issue confirmed', 'No issue'];
    const includedKeys = fill(Array(3), false);
    const a = chain(data)
      .filter(status => displayKeys.includes(status.result_status))
      .groupBy((b: GetActivityTrendsChart.ActivityTrendsReport) => {
        includedKeys[displayKeys.indexOf(b.result_status)] = true;
        return b.result_status;
      })
      .map((value, key) => {
        const includedWeeks = zipObject(
          xAxisTicks,
          fill(Array(xAxisTicks.length), false),
        );
        let series = chain(value)
          .groupBy((d: GetActivityTrendsChart.ActivityTrendsReport) => d.week)
          .map((value, key) => {
            const week = moment.utc(key).format('DD MMM YYYY');
            includedWeeks[week] = true;
            // upperCase()
            return {
              name: week,
              value: sumBy(value, 'count'),
            };
          })
          .value();
        forOwn(includedWeeks, function(isIncluded, week) {
          if (!isIncluded) {
            series.push({ name: week, value: 0 });
          }
        });

        series = sortBy(series, function(e) {
          return moment(e.name);
        });

        return {
          name: key,
          series: series,
        };
      })
      .value();
    // push missed legends now.
    const standardSeries = [];
    xAxisTicks.forEach(week => {
      standardSeries.push({
        name: week,
        value: 0,
      });
    });
    // push empty series to missing legends
    includedKeys.forEach((isIncluded, index) => {
      if (!isIncluded) {
        a.push({ name: displayKeys[index], series: standardSeries });
        includedKeys[index] = true;
      }
    });

    // all legends are presented. sort the legend
    const b = sortBy(a, [
      function(e) {
        return displayKeys.indexOf(e.name);
      },
    ]);
    return b as LineChartData[];
  }

  formatActivityTrendDetailData(
    data: GetActivityTrendsDetailChart.ActivityTrendsDetailReport,
    xAxisTicks: string[],
  ): LineChartDetailData[] {
    const displayKeys = ['Review required', 'Issue confirmed', 'No issue'];
    return chain(data)
      .filter(status => displayKeys.includes(status.result_status))
      .groupBy((d: GetActivityTrendsDetailChart.ActivityTrendsDetailReport) => {
        return d.asset_class;
      })
      .map((value, key) => ({
        name: key,
        chartData: this.formatActivityTrendGeneralData(
          value as any,
          xAxisTicks,
        ),
      }))
      .value();
  }

  formatXAxisTicksForActivityTrendDetail(dates: {
    start_date: string;
    end_date: string;
  }): string[] {
    const ticks = [];
    let cur = moment.utc(dates.end_date);
    while (cur.isSameOrAfter(dates.start_date)) {
      ticks.push(cur.format('DD MMM YYYY'));
      cur = cur.subtract(1, 'day');
    }
    return ticks;
  }

  formatDisplayedTicksForActivityTrend(dates: {
    start_date: string;
    end_date: string;
  }): string[] {
    const ticks = [];
    let cur = moment.utc(dates.end_date);
    while (cur.isSameOrAfter(dates.start_date)) {
      ticks.push(cur.format('DD MMM YYYY'));
      cur = cur.subtract(1, 'week');
    }
    return ticks;
  }

  formatYAxisTicksForActivityTrend(data, type) {
    let max = 0.0;
    const yAxisTicks = [];
    const length = 4;
    let gap = 1;

    max =
      type === 'general'
        ? this.getMaxForActivityTrendGeneral(data)
        : this.getMaxForActivityTrendDetail(data);
    max = Math.ceil(max / 10) * 10;
    gap = Math.ceil(max / (length - 1) / 10) * 10;
    for (let i = 0; i < length; i++) {
      yAxisTicks.push(i * gap);
    }
    return yAxisTicks;
  }

  prepareFailRateByCategoryGeneralData(
    data: GetFailureRateRecordsChart.FailureRateReport[],
  ) {
    const failureRateChartData = chain(data)
      .groupBy(
        (c: GetFailureRateRecordsChart.FailureRateReport) => c.test_kind_name,
      )
      .map((value, key) => {
        const displayOrder = this.calculateDisplayOrder(
          this.testKinds,
          key,
          'test_kind_key',
        );
        const reports = value.map((item, index) => {
          return {
            name: this.utilService.getPositionKey(item.report_name),
            value: item.failure_rate,
          };
        });
        return {
          name: key,
          displayOrder: displayOrder,
          reports: reports,
        };
      })
      .value();
    return this.formatFailureChartData(
      sortBy(failureRateChartData, 'displayOrder'),
    );
  }

  prepareFailRateByCategoryGeneralDetailData(
    data: GetFailureRateRecordsDeatilChart.FailureRateDetailReport[],
  ) {
    const reportData = ['submission', 'position'].map(type => {
      return this.generateFailureRateDetailData(data, type);
    });
    return {
      submission: reportData[0],
      [this.utilService.getPositionKey()]: reportData[1],
    };
  }

  generateFailureRateDetailData(
    data: GetFailureRateRecordsDeatilChart.FailureRateDetailReport[],
    type: string,
  ) {
    const failureRateChartData = this.filterFailureRateByReportName(data, type);
    const failureRateChartDetailData = [];
    let failureRateChartDetailDatas = [];
    type = this.utilService.getPositionKey(type);
    transform(failureRateChartData, (r, v: any, k) => {
      const kindName = k;
      return transform(v, (r, value: any, key) => {
        failureRateChartDetailData.push({
          name: kindName,
          entryName: key,
          reportName: type.charAt(0).toUpperCase() + type.slice(1),
          data: chain(value)
            .map(item => {
              const displayOrder = this.getDisplayOrder(item.asset_class_name);
              return {
                name: item.asset_class_name,
                series: [
                  {
                    name: item.report_name,
                    value: item.failure_rate,
                    percent: item.failure_rate,
                  },
                ],
                displayOrder: displayOrder,
              };
            })
            .value(),
        });
      });
    });
    failureRateChartDetailDatas = this.calculateAssetClass(
      failureRateChartDetailData,
      'failureRate',
      type,
    );
    return failureRateChartDetailDatas;
  }

  filterFailureRateByReportName(
    data: GetFailureRateRecordsDeatilChart.FailureRateDetailReport[],
    type: string,
  ) {
    const failureRateChartPostionData = data
      .filter(item => item.report_name.toLowerCase() === type.toLowerCase())
      .reduce((result, item) => {
        const kindNameResults = (result[item.test_kind_name] =
          result[item.test_kind_name] || {});
        const entryNameResults = (kindNameResults[item.report_entry_name] =
          kindNameResults[item.report_entry_name] || []);
        entryNameResults.push(item);
        return result;
      }, {});

    return failureRateChartPostionData;
  }

  prepareHighPassByAssetClassGeneralData(
    data: GetHighPassesChart.HighPassReport[],
  ) {
    const highPassChartData = chain(data)
      .groupBy((c: GetHighPassesChart.HighPassReport) => c.asset_class_name)
      .map((v, k) => {
        let displayOrder = 0;
        this.assetClasses.filter(asset => {
          if (asset.nme === k) {
            displayOrder = asset.order;
          }
        });
        return {
          assetName: k,
          displayOrder: displayOrder,
          reports: chain(v)
            .groupBy((d: GetHighPassesChart.HighPassReport) => d.report_name)
            .map((v, k) => {
              const value = chain(v)
                .groupBy(
                  (e: GetHighPassesChart.HighPassReport) => e.report_name,
                )
                .map((v, k) => {
                  let val = {};
                  const [
                    highEvaluated,
                    lowEvaluated,
                    total,
                  ] = this.calculateEvaluated(v);
                  val = {
                    highPass: {
                      value: highEvaluated,
                      percent: (highEvaluated / total).toFixed(2),
                    },
                    lowPass: {
                      value: lowEvaluated,
                      percent: (lowEvaluated / total).toFixed(2),
                    },
                  };
                  return val;
                })
                .value();
              return k === 'Position'
                ? { position: merge({}, ...value) }
                : { submission: merge({}, ...value) };
            })
            .value(),
        };
      })
      .value();

    const withValue = {
      highPass: {
        value: 0,
        percent: 0,
      },
      lowPass: {
        value: 0,
        percent: 0,
      },
    };

    highPassChartData.map((item: any) => {
      if (item.reports.length <= 1) {
        if (item.reports.length === 0) {
          item.reports = this.formatHighPassWithValue(withValue);
        } else {
          item.reports.map((report: any) => {
            this.formatWithInitialValue(item, report, withValue);
          });
        }
      }
    });
    return this.formatHighPassChartData(
      sortBy(highPassChartData, 'displayOrder'),
    );
  }

  formatHighPassWithValue(withValue: any) {
    const highPass = {
      position: withValue,
    };
    if (localStorage.getItem('regulator') !== RegulatorEnum.EMIR) {
      return [
        { ...highPass },
        {
          submission: withValue,
        },
      ];
    } else {
      return [{ ...highPass }];
    }
  }

  formatWithInitialValue(item: any, report: any, withValue) {
    if (
      Object.keys(report)[0] === 'position' &&
      localStorage.getItem('regulator') !== RegulatorEnum.EMIR
    ) {
      item.reports.push({
        submission: withValue,
      });
    } else {
      item.reports.push({
        position: withValue,
      });
    }
  }

  getActivityTrendDateRange(start?: string, end?: string) {
    if (!start && !end) {
      return this.getActivityTrendGeneralDateRange();
    }
    if (start) {
      return {
        start_date: start,
        end_date: this.calculateDateForActivityTrend(start, 'add'),
      };
    } else {
      return {
        start_date: this.calculateDateForActivityTrend(end, 'subtract'),
        end_date: end,
      };
    }
  }

  private formatHighReport(report, assetName: string) {
    for (let type in report) {
      const { highPass, lowPass } = report[type];
      type = this.utilService.getPositionKey(type);
      const result = {
        name: type.charAt(0).toUpperCase() + type.slice(1), // capitalize the first letter
        series: [
          {
            name: 'High Pass results', // if changed here, must change "*ngIf" on html
            assetName: assetName,
            value: highPass.value,
            percent: highPass.percent,
          },
          {
            name: 'Low Pass results', // if changed here, must change "*ngIf" on html
            assetName: assetName,
            value: lowPass.value,
            percent: lowPass.percent,
          },
        ],
      };
      return result;
    }
  }

  private formatHighChartDetail(assetName: string, displayOrder: number) {
    const result = {
      name: assetName, // capitalize the first letter
      displayOrder: displayOrder,
      series: [
        {
          name: 'High Pass results',
          assetName: assetName,
          value: 0,
          percent: 0,
        },
        {
          name: 'Low Pass results',
          assetName: assetName,
          value: 0,
          percent: 0,
        },
      ],
    };
    return result;
  }

  private formatFailureRateChartDetail(
    assetName: string,
    displayOrder: number,
    type: string,
  ) {
    const result = {
      name: assetName, // capitalize the first letter
      displayOrder: displayOrder,
      series: [
        {
          name: type.charAt(0).toUpperCase() + type.slice(1),
          assetName: assetName,
          value: 0,
          percent: 0,
        },
      ],
    };
    return result;
  }

  private getMaxForActivityTrendGeneral(data) {
    let max = 0;
    data.forEach(row =>
      row.series.forEach(entry => {
        if (entry.value > max) {
          max = entry.value;
        }
      }),
    );
    return max;
  }
  sortDataForFailureRateChart(data: ReportTypes): ReportTypes {
    const keys = Object.keys(data);
    keys.forEach(key => {
      data[key].sort((a, b) => {
        if (a.name === b.name) {
          return (
            this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(a.entryName) -
            this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(b.entryName)
          );
        } else {
          return a.name.localeCompare(b.name);
        }
      });
    });
    return data;
  }
  sortDataForDetailActivityTrendChart(data) {
    data.sort(
      (a, b) =>
        this.ASSET_CLASS_ORDERED.indexOf(a.name) -
        this.ASSET_CLASS_ORDERED.indexOf(b.name),
    );
    return data;
  }
  sortDataForHighPassChart(data) {
    data.sort(
      (a, b) =>
        this.ASSET_CLASS_ORDERED.indexOf(a.assetName) -
        this.ASSET_CLASS_ORDERED.indexOf(b.assetName),
    );
    return data;
  }
  sortDataForDetailHighPassChart(data) {
    Object.keys(data).forEach(key => {
      data[key].sort(
        (a, b) =>
          this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(a.name) -
          this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(b.name),
      );
    });
    return data;
  }
  sortDataForDetailTotalRecordTable(data) {
    Object.keys(data)
      .filter(key => key !== 'assetClasses')
      .forEach(key => {
        if (data && data[key]) {
          data[key].sort((a, b) => {
            if (this.RESPONSIBLE_PARTY_ORDERED.hasOwnProperty(key)) {
              return (
                this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(a.Report) -
                this.RESPONSIBLE_PARTY_ORDERED[key].indexOf(b.Report)
              );
            }
          });
        }
      });
    return data;
  }
  private getMaxForActivityTrendDetail(data) {
    let max = 0;
    data.forEach(row =>
      row.chartData.forEach(entry => {
        entry.series.forEach(e => {
          if (e.value > max) {
            max = e.value;
          }
        });
      }),
    );
    return max;
  }
  private getActivityTrendGeneralDateRange() {
    const end = moment()
      .utc()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    const start = this.calculateDateForActivityTrend(end, 'subtract');
    return { start_date: start, end_date: end };
  }
  private calculateDateForActivityTrend(old: string, type: string) {
    let res = moment.utc(old);
    if (type === 'add') {
      res = res.add(7, 'weeks');
    } else {
      res = res.subtract(7, 'weeks');
    }
    return res.format('YYYY-MM-DD');
  }
}
