import { ToastColorOption } from '../types';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { UserService, ToastService } from '@services/index';

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private toast: ToastService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      route.queryParams.verificationToken &&
      route.queryParams.uid &&
      route.queryParams.product
    ) {
      const obj = route.queryParams;
      this.userService
        .verifyEmail({
          product: obj.product,
          userId: obj.uid,
          emailVerificationToken: obj.verificationToken,
        })
        .subscribe(
          res => {
            if (res.status === 'SUCCESS') {
              this.router.navigate(['/profile'], { replaceUrl: true });
            } else {
              this.toast.show(`${res.message}`, ToastColorOption.ERROR);
              console.error(res);
            }
          },
          (err: Error) => {
            this.toast.show(`${err.message}`, ToastColorOption.ERROR);
            console.error(err);
            this.router.navigate(['/welcome']);
          },
        );
    } else {
      this.toast.show(
        'No validation params, redirect to root route.',
        ToastColorOption.ERROR,
      );
      this.router.navigate(['/'], { replaceUrl: true });
      return true;
    }
  }
}
