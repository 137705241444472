import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { MatMenuTrigger, MatDialog } from '@angular/material';
import {
  SessionService,
  FeatureFlagService,
  UserService,
} from '@services/index';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: true })
  selectedRegulator$: BehaviorSubject<String>;
  availableRegulators$ = new BehaviorSubject<String[]>([]);
  isAdmin$: Observable<boolean>;
  canUploadReport: boolean;
  navList: Array<{ title: string; path: string }> = [];
  canChangeJurisdiction$ = new BehaviorSubject<Boolean>(false);
  selectShow = false;
  isHover = false;
  displayTooltip = false;
  toggleableJurisdtionPaths = ['/dashboard', '/summary'];
  unsubscribe$ = new Subject();
  showDropdown = false;
  isLoadingSelectedRegulator = true;
  isUserLoaded = false;

  featureFlagInitialized = this.featureFlagService.initialized$;
  isInAdminPage = false;
  user: any;

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.isAdmin$ = this.userService.hasPermission('view_admin_portal');
    this.handleNavigationEvents();
    this.handleJurisdictionSettings();
  }

  signOut(): void {
    this.sessionService.signOutUser();
  }
  notLoginPage(): boolean {
    return !(
      window.location.href.indexOf('/welcome') > -1 ||
      window.location.href.indexOf('/terms-of-service') > -1 ||
      window.location.href.indexOf('/terms') > -1 ||
      window.location.href.indexOf('/privacy') > -1 ||
      window.location.href.indexOf('/unauthorized') > -1 ||
      window.location.href.indexOf('/register') > -1
    );
  }

  hideActionItems(): boolean {
    return this.notLoginPage() && window.location.href.indexOf('/admin') <= -1;
  }

  mouseEnter(): void {
    this.isHover = true;
  }
  mouseLeave(): void {
    this.isHover = false;
  }

  handleAdminClicked() {
    this.router.navigateByUrl('/admin');
  }
  handleNavigationEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(e => e instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        if (this.router.url.includes('admin')) {
          this.isInAdminPage = true;
        } else {
          this.isInAdminPage = false;
        }
        const canChange = this.toggleableJurisdtionPaths.includes(
          event.urlAfterRedirects,
        )
          ? true
          : false;
        this.canChangeJurisdiction$.next(canChange);
      });
  }

  handleJurisdictionSettings(): void {
    this.userService.user$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(res => res !== null),
      )
      .subscribe(user => {
        let regs: string[] = [];
        if (user) {
          this.user = user;
          if (user['settings']) {
            if (user['settings'].endGuestSession) {
              this.sessionService.showTenantBarWithSetting.next(true);
            }
          }
          this.canUploadReport = user.can_upload_report;
          regs = user.tenant.regulators.map(reg => reg.nme);
          this.availableRegulators$.next(regs);
          const localRegulator = localStorage.getItem('regulator');
          const selectedRegulator =
            regs &&
            regs.filter((item, index) => item === localRegulator && index === 0)
              .length > 0
              ? localRegulator
              : regs[0];
          localStorage.setItem('regulator', selectedRegulator);
          this.sessionService.handleRegulatorChanged(selectedRegulator);
          this.isLoadingSelectedRegulator = false;
          this.isUserLoaded = true;
        }
      });
  }

  hoverSelect(isHovered): void {
    this.displayTooltip = isHovered && !this.canChangeJurisdiction$.value;
  }

  handleShowDropdown(): void {
    if (this.canChangeJurisdiction$.value && !this.isLoadingSelectedRegulator) {
      this.showDropdown = !this.showDropdown;
    }
  }
  handleClickOutside(event): void {
    this.showDropdown = false;
  }
  handleSelectedRegulatorChanged(newReg): void {
    if (newReg && newReg !== undefined) {
      localStorage.setItem('regulator', newReg);
      this.sessionService.selectedRegulator$.next(newReg);
      this.showDropdown = false;
    }
  }

  handleEndSessionClicked() {
    if (this.user) {
      const userInput = {
        email: this.user.email,
        user_key: this.user.user_key,
        tenant_key: this.user.settings.endGuestSession,
        settings: {},
      };
      this.userService.editUser(userInput).subscribe(res => {
        if (res) {
          this.sessionService.showNavBar$.next(true);
          this.sessionService.showTenantBarWithSetting.next(false);
          this.router.navigate(['/admin/manage-tenant'], {
            queryParams: { tenant: this.user.tenant.tenant_key },
          });
        }
      });
    }
  }

  judgeExistSetting(setting: {}) {
    if (setting !== null) {
      if (Object.keys(setting).length > 0) {
        return true;
      }
    } else {
      return false;
    }
  }
  goToUploadReports(): void {
    if (this.canUploadReport) {
      this.router.navigateByUrl('/uploadReport');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
