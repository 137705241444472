import { Injectable } from '@angular/core';
import {
  GetReportEntryTypeAllGQL,
  GetReportEntryTypeAll,
} from '@generatedTypes/graphql';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResultDetailService {
  constructor(private getReportEntryTypeAllGQL: GetReportEntryTypeAllGQL) {}

  getAllReportEntryType(): Observable<
    GetReportEntryTypeAll.GetReportEntryType[]
  > {
    return this.getReportEntryTypeAllGQL
      .watch()
      .valueChanges.pipe(map(response => response.data.getReportEntryType));
  }
}
