import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '@services/util.service';
import { DashboardService } from '@services/dashboard.service';
import { upperCase } from 'lodash';
@Component({
  selector: 'app-activity-trend-chart',
  templateUrl: './activity-trend-chart.component.html',
  styleUrls: ['./activity-trend-chart.component.scss'],
})
export class ActivityTrendChartComponent implements OnInit {
  // data goes here
  @Input() yAxisTicks;
  @Input() singleChartData;
  // options for the chart
  @Input() chartTitle: string;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'WEEK OF';
  showYAxisLabel = true;
  yAxisLabel = 'NUMBER OF RESULTS';
  colorScheme = {
    domain: ['#F1A636', '#398F90', '#8DA656'],
  };
  view = [1000, 300];

  yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);
  xAxisTickFormattingFn = this.xAxisTickFormatting.bind(this);

  xAxisTicks: [any];

  constructor(
    private utilService: UtilService,
    private dashboardService: DashboardService,
  ) {
    this.xAxisTicks = <any>(
      this.dashboardService.formatDisplayedTicksForActivityTrend(
        this.dashboardService.getActivityTrendDateRange(),
      )
    );
  }

  ngOnInit() {}

  yAxisTickFormatting(value) {
    return parseInt(value, 10);
  }
  xAxisTickFormatting(value) {
    if (this.xAxisTicks.includes(value)) {
      return upperCase(value);
    } else {
      return '';
    }
  }
}
