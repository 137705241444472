import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface Item {
  display: string;
  value: string | number;
}

@Component({
  selector: 'luna-pill-group',
  templateUrl: './luna-pill-group.html',
  styleUrls: ['./luna-pill-group.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class LunaPillGroup {
  @Input() lunaPillGroupData: Item[] = [];
  @Output() removeOne = new EventEmitter();
  @Output() removeAll = new EventEmitter();

  private expanded = false;

  shownItems() {
    if (this.expanded) {
      return this.lunaPillGroupData;
    }
    return this.lunaPillGroupData.slice(0, 5);
  }
}
