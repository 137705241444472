import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrayColumn',
})
export class FilterArrayColumnPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return (
      value &&
      value.filter(item => item.display === args[0] || item.key === args[0])
        .length > 0
    );
  }
}
