import { Injectable } from '@angular/core';
import { Observable, iif, of, throwError, combineLatest } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import {
  GetTenants,
  GetTenantsGQL,
  GetTenantByIdGQL,
  GetTenantById,
  TenantInput,
  TenantByIdInput,
  UpsertTenantInput,
  UpsertTenantGQL,
  UpsertTenant,
  OnboardOrganizationGQL,
} from '@generatedTypes/graphql';
import envVars from '@config/envVars';
import { get, lowerCase } from 'lodash';
import { OrganizationService } from '@services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    private getTenantsGQL: GetTenantsGQL,
    private upsertTenantGQL: UpsertTenantGQL,
    private getTenantByIdGQL: GetTenantByIdGQL,
    private onboardOrganizationGQL: OnboardOrganizationGQL,
    private orgService: OrganizationService,
  ) {}

  getTenantById(
    input: TenantByIdInput,
  ): Observable<GetTenantById.GetTenantById> {
    return this.getTenantByIdGQL
      .watch({ tenantKey: input.tenant_key }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getTenantById;
        }),
      );
  }

  getTenants(input?: TenantInput): Observable<GetTenants.GetTenants[]> {
    return this.getTenantsGQL
      .watch({ input }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          const tenants = data.getTenants;
          return tenants.map(item => {
            return {
              ...item,
              indexing: lowerCase(`${get(item, 'nme')}`),
            };
          });
        }),
      );
  }

  upsertTenant(
    upsertTenantInput: UpsertTenantInput,
    email: string,
    action?: string,
  ): Observable<UpsertTenant.UpsertTenant> {
    if (action === 'create') {
      return this.onboardOrganizationGQL
        .mutate(
          {
            input: {
              product: envVars().IAM_PRODUCT,
              organizationName: upsertTenantInput.nme,
              adminEmail: email,
              allowDuplicateOrganizationName: true,
            },
          },
          {
            fetchPolicy: 'no-cache',
            refetchQueries: [
              {
                query: this.getTenantsGQL.document,
              },
            ],
          },
        )
        .pipe(
          flatMap(({ data }) =>
            iif(
              () => data.IAMonboardOrganization.status === 'SUCCESS',
              of(true),
              of(false),
            ),
          ),
          flatMap(_ => this.upsertTenantGQL.mutate({ upsertTenantInput })),
          map((res: any) => res.data.upsertTenant),
        );
    } else {
      return this.upsertTenantGQL
        .mutate(
          { upsertTenantInput },
          {
            fetchPolicy: 'no-cache',
            refetchQueries: [
              {
                query: this.getTenantsGQL.document,
              },
            ],
          },
        )
        .pipe(map((res: any) => res.data.upsertTenant));
    }
  }
}
