import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UtilService } from '@services/util.service';
import { SessionService } from '@services/session.service';

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
})
export class DetailHeaderComponent implements OnInit {
  currentType: string;
  positionKey: string;
  @Input() title: string;
  @Input() desc: string;
  @Output() handleTypeClickChanged: EventEmitter<any> = new EventEmitter();
  @Output() handleDialogClosedClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.currentType = this.utilService.getPositionKey();
    this.positionKey = this.currentType;
  }

  handleTypeClicked(type: string) {
    if (type) {
      type = this.utilService.getPositionKey(type);
      this.currentType = type;
      this.handleTypeClickChanged.emit({ type: type });
    }
  }

  handleDialogClosed(event) {
    if (event) {
      this.handleDialogClosedClicked.emit();
    }
  }
}
