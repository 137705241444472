import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GetRegulatorsGQL, GetRegulators } from '@generatedTypes/graphql';

@Injectable({
  providedIn: 'root',
})
export class RegulatorService {
  constructor(private getRegulatorsGQL: GetRegulatorsGQL) {}

  getRegulators(): Observable<GetRegulators.GetRegulators[]> {
    return this.getRegulatorsGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getRegulators;
        }),
      );
  }
}
