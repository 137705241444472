import { Injectable } from '@angular/core';
import {
  BatchTestInput,
  GetAllIssueAssignments,
  GetAllIssueAssignmentsGQL,
  GetBatchTestGQL,
  GetIssues,
  GetIssuesGQL,
  GetRemediations,
  GetRemediationsGQL,
  GetRiskAssessmentsGQL,
  GetUserAssignments,
  GetUserAssignmentsGQL,
  QueryIssueInput,
  UpdateAssignmentStatusGQL,
  UpdateAssignmentStatus,
  UpsertIssueInput,
  UpsertIssuesGQL,
  UpsertIssues,
  GetRiskAssessments,
} from '@generatedTypes/graphql';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { get, sortBy, lowerCase, orderBy, head } from 'lodash';
import { TestStatusEnum } from '@types';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  riskAssessments;
  defaultTestOfItem = { result_id: -1 };
  issues$: Observable<GetRemediations.AssetTests[] | null> = of();
  constructor(
    private getIssuesGQL: GetIssuesGQL,
    private getRemediationsGQL: GetRemediationsGQL,
    private upsertIssuesGQL: UpsertIssuesGQL,
    private getRiskAssessmentsGQL: GetRiskAssessmentsGQL,
    private getBatchTestGQL: GetBatchTestGQL,
    private getUserAssignmentsGQL: GetUserAssignmentsGQL,
    private getAllIssueAssignmentsGQL: GetAllIssueAssignmentsGQL,
    private updateAssignmentStatusGQL: UpdateAssignmentStatusGQL,
  ) {
    this.getRiskAssessments().subscribe(data => {
      this.riskAssessments = data;
    });
  }
  getIssues(input: QueryIssueInput): Observable<GetIssues.GetIssues[]> {
    return this.getIssuesGQL
      .watch(
        { asset_test_key: [...input.asset_test_key] },
        { fetchPolicy: 'network-only' },
      )
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getIssues;
        }),
      );
  }

  getRemediations(
    input: BatchTestInput,
  ): Observable<GetRemediations.GetBatchTest> {
    return this.getRemediationsGQL
      .watch({ input }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getBatchTest;
        }),
      );
  }
  getUserAssignments(): Observable<GetUserAssignments.GetUserAssignments[]> {
    return this.getUserAssignmentsGQL
      .watch({}, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          const getUserAssignments = orderBy(
            data.getUserAssignments,
            [
              'test.result_id',
              'asset_class.order',
              'report_entry_type[0].order',
            ],
            ['asc', 'asc', 'asc'],
          );
          return getUserAssignments.map(item => {
            return {
              key: !!item.issue_key
                ? `i${item.issue_key}`
                : `r${item.remediation_key}`,
              id: (item.test || this.defaultTestOfItem).result_id,
              indexing: lowerCase(
                `${get(item, 'asset_class.nme')},${get(
                  item,
                  'test.nme',
                )}, ${get(item, 'role')}, ${item.priority}, ${get(
                  item,
                  'report_entry_type.nme',
                )}, ${moment(get(item, 'target_resolution_date')).format(
                  'DD MMM YYYY ',
                )}, ${get(item, 'status')} `,
              ),
              ...item,
              report_entry_type_nme:
                item.remediation_key === null
                  ? null
                  : item.is_all_submission
                  ? 'All Submissions'
                  : item.report_entry_type.length
                  ? head(item.report_entry_type).nme // we return the first "responsible party" in the array
                  : null,
            };
          });
        }),
      );
  }
  getAllIssueAssignments(): Observable<
    GetAllIssueAssignments.GetAllIssueAssignments[]
  > {
    return this.getAllIssueAssignmentsGQL
      .watch({}, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          const getAllIssueAssignments = orderBy(
            data.getAllIssueAssignments,
            ['test.result_id', 'asset_class.order'],
            ['asc', 'asc'],
          );

          return getAllIssueAssignments.map(item => {
            return {
              key: !!item.issue_key
                ? `i${item.issue_key}`
                : `r${item.remediation_key}`,
              id: (item.test || this.defaultTestOfItem).result_id,
              indexing: lowerCase(
                `${get(item, 'asset_class.nme')},  ${get(
                  item,
                  'priority',
                )}, ${get(item, 'issue_owner.full_nme')}, ${get(
                  item,
                  'status',
                )} `,
              ),
              ...item,
            };
          });
        }),
      );
  }

  updateAssignmentStatus(input): Observable<boolean> {
    return this.updateAssignmentStatusGQL
      .mutate(
        {
          ...input,
        },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getUserAssignmentsGQL.document,
              variables: {},
            },
          ],
        },
      )
      .pipe(
        map(({ data }) => {
          return data.updateAssignmentStatus;
        }),
      );
  }

  setRemediations(
    input,
  ): Observable<
    GetRemediations.AssetTests[] | Observable<GetRemediations.AssetTests[]>
  > {
    return this.getRemediations(input).pipe(
      map(data => {
        if (data.asset_tests) {
          data.asset_tests = sortBy(
            data.asset_tests,
            ['asset_class.asset_class_key'],
            ['asc'],
          );
          return of(data.asset_tests);
        }
      }),
      tap(issues => {
        this.issues$ = <Observable<GetRemediations.AssetTests[]>>issues;
        return this.issues$;
      }),
    );
  }

  getRiskAssessments(): Observable<GetRiskAssessments.GetRiskAssessments[]> {
    return this.getRiskAssessmentsGQL
      .watch({}, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getRiskAssessments;
        }),
      );
  }

  upsertIssues(
    upsertIssueInput: UpsertIssueInput[],
    batchTestKey: string,
  ): Observable<any> {
    return this.upsertIssuesGQL
      .mutate(
        { upsertIssueInput },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getRemediationsGQL.document,
              variables: {
                input: {
                  batch_test_key: batchTestKey,
                  batch_key: null,
                  result_id: null,
                },
              },
            },
            {
              query: this.getBatchTestGQL.document,
              variables: {
                input: {
                  batch_test_key: batchTestKey,
                  batch_key: null,
                  result_id: null,
                },
              },
            },
          ],
        },
      )
      .pipe(
        map(({ data }) => {
          return data.upsertIssues;
        }),
      );
  }

  packageEditIssueInput(
    assetTestStatuses,
    allReportEntryTypes: any,
    userOptions?: any,
  ) {
    const upsertIssuesInput = [];
    const inCompletes = [];
    const completeStatus = [];
    const pastStatus = [];
    console.log('states', assetTestStatuses);
    assetTestStatuses
      .filter(asset => asset.state === TestStatusEnum.ISSUE_CONFIRMED)
      .map((item, index) => {
        if (get(item, 'remediations', null)) {
          get(item, 'remediations', [])
            .filter(rem => !rem.passed)
            .map(rem => {
              if (
                moment()
                  .startOf('day')
                  .toDate() > moment(rem.targetResolutionDate).toDate()
              ) {
                pastStatus.push(false);
              }
              if (
                rem.priority === null ||
                rem.resolutionOwner === null ||
                rem.targetResolutionDate === null
              ) {
                inCompletes.push(true);
              }
            });
        }
        if (
          item.issueOwner === null ||
          item.riskAssessment === null ||
          inCompletes.length >= 1
        ) {
          completeStatus.push(false);
        }
        const inputCreateItem = this.formatEditOrUpdateIssuesInput(
          item,
          userOptions,
          allReportEntryTypes,
        );
        upsertIssuesInput.push(inputCreateItem);
      });

    return [upsertIssuesInput, completeStatus, pastStatus];
  }

  formatEditOrUpdateIssuesInput(issue, userOptions, allReportEntryTypes) {
    const inputItem = {
      issue_key: get(issue, 'issueKey', null),
      issue_owner_key:
        issue.issueOwner !== ''
          ? get(
              userOptions.filter(user => user.fullname === issue.issueOwner)[0],
              'userKey',
              null,
            )
          : null,
      asset_test_key: get(issue, 'assetTestKey', null),
      risk_assessment_key: issue.riskAssessment
        ? this.riskAssessments.filter(
            risk =>
              risk.nme === get(issue, 'riskAssessment', null).toUpperCase(),
          )[0].risk_assessment_key
        : null,
      remediations: get(issue, 'remediations', [])
        .filter(x => !x.failed)
        .map((rem, i) => {
          return this.formatRemediationsInput(
            rem,
            userOptions,
            issue,
            allReportEntryTypes,
            rem.remediationKey,
          );
        }),
    };
    return inputItem;
  }

  formatRemediationsInput(
    rem,
    userOptions,
    issue,
    allReportEntryTypes,
    remediation?: any,
  ) {
    return {
      remediation_key: remediation ? remediation : null,
      user_key:
        rem.resolutionOwner && issue.resolutionOwner !== ''
          ? userOptions.filter(user => user.fullname === rem.resolutionOwner)[0]
              .userKey
          : null,
      priority: get(rem, 'priority', null)
        ? this.riskAssessments.filter(
            risk => risk.nme === get(rem, 'priority').toUpperCase(),
          )[0].nme
        : null,
      report_entry_type_key:
        rem.report_entry_type !== ''
          ? allReportEntryTypes.filter(
              entry => rem.report_entry_type === entry.nme,
            )[0].report_entry_type_key
          : null,
      target_resolution_date: get(rem, 'targetResolutionDate', null),
      is_all_submission: get(rem, 'isAllSubmission', null),
    };
  }
}
