import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import {
  GoogleAnalyticsEventsService,
  SessionService,
  UtilService,
  ToastService,
} from '@services/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { ApolloLink, Observable, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { withClientState } from 'apollo-link-state';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import envVars from './config/envVars';
import { TermsOfServiceComponent } from './routes/terms-of-service/terms-of-service.component';
import { TermsComponent } from './routes/terms-of-service/terms/terms.component';

import { AuthRedirectComponent } from './routes/auth-redirect/auth-redirect.component';
import { Router } from '@angular/router';
import { MatRadioModule } from '@angular/material';
import { ToastColorOption } from '@types';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    TermsOfServiceComponent,
    TermsComponent,
    AuthRedirectComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ApolloModule,
    HttpClientModule,
    HttpLinkModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSlideToggleModule,
    NgSelectModule,
  ],
  providers: [
    GoogleAnalyticsEventsService,
    SessionService,
    ToastService,
    UtilService,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private injector: Injector,
    private sessionService: SessionService,
    private toast: ToastService,
    private router: Router,
  ) {
    const GRAPHQL_API_URL =
      envVars().GRAPHQL_API_URL || 'http://localhost:3000/graphql/';
    const _httpLink = httpLink.create({ uri: GRAPHQL_API_URL });
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: [],
        },
      },
    });
    const cache = new InMemoryCache({
      fragmentMatcher,
    });
    const request = async operation => {
      const token = await this.sessionService.getAuthorizationHeaders();
      const headers =
        (envVars().ENABLE_AUTH as any) === 1 ||
        (envVars().ENABLE_AUTH as any) === '1'
          ? token
          : { email: 'lee.li@cnsdc01.pwc.com' }; // any authenticated user email

      operation.setContext({
        headers,
      });
    };

    const requestLink = new ApolloLink(
      (operation, forward) =>
        new Observable(observer => {
          let handle;
          Promise.resolve(operation)
            .then(oper => request(oper))
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch(observer.error.bind(observer));

          return () => {
            if (handle) {
              handle.unsubscribe();
            }
          };
        }),
    );
    const uploadLink = createUploadLink({
      uri: GRAPHQL_API_URL,
    });

    const isFile = value =>
      value instanceof Array ||
      (typeof FileList !== 'undefined' && value instanceof FileList) ||
      (typeof File !== 'undefined' && value instanceof File) ||
      (typeof Blob !== 'undefined' && value instanceof Blob);
    const isUpload = ({ variables }) => Object.values(variables).some(isFile);
    const terminalLink = split(isUpload, uploadLink, _httpLink);
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      // ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((err: any) => {
          console.error('GraphQL Error: ', err);
          switch (err.status) {
            case 'FILE_FORMAT_ERROR': {
              // handle error with modal on upload-report.component.ts
              break;
            }
            case 'UNAUTHORIZED':
              this.toast.show(
                `You are not authorized for this action. Please contact administator (Error: ${err.status})`,
                ToastColorOption.ERROR,
              );
              setTimeout(() => {
                this.router.navigateByUrl('/unauthorized');
              }, 3000);
              break;
            case 'QUERY':
              this.toast.show(
                `We had an issue getting your data. Please contact administrator (Error: ${err.status})`,
                ToastColorOption.ERROR,
              );
              break;
            default:
              this.toast.show(
                `We had an unexpected error. Please contact administrator (Error: ${err.status})`,
                ToastColorOption.ERROR,
              );
          }
        });
      }
      if (networkError) {
        switch (networkError.name) {
          case 'HttpErrorResponse':
            {
              const httpError = networkError as HttpErrorResponse;

              if (httpError.error.errors) {
                httpError.error.errors.forEach(err => {
                  console.error(`${err.status}: ${err.message}`);
                  switch (err.status) {
                    case 'UNAUTHENTICATED':
                    case 'USER_NOT_FOUND': {
                      if (!location.pathname.includes('/register')) {
                        const msg = `Your session is not currently active. Please try logging in again.`;
                        setTimeout(() => {
                          // gives the user a second to read the toast message
                          this.sessionService.signOutUser();
                        }, 1000);
                        this.toast.show(msg, ToastColorOption.ERROR);
                      }
                      break;
                    }
                    case 'USER_DISABLED':
                    case 'TENANT_DISABLED': {
                      const msg = `Your account is currently deactivated. Please contact administrator.`;
                      setTimeout(() => {
                        this.sessionService.signOutUser();
                      }, 1000);
                      this.toast.show(msg, ToastColorOption.ERROR);
                      break;
                    }
                    default: {
                      const msg = `
                        There was an issue handling your request. Please contact an administrator. (Error:${httpError.statusText})
                      `;
                      this.toast.show(msg, ToastColorOption.ERROR);
                      break;
                    }
                  }
                });
              }
            }
            break;
          default:
            const msg = `There was a network issue handling your request. Please contact an administrator.`;
            this.toast.show(msg, ToastColorOption.ERROR);
        }
      }
    });
    const stateLink = withClientState({
      defaults: {
        isConnected: true,
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected } });
            return null;
          },
        },
      },
      cache,
    });

    apollo.create({
      link: ApolloLink.from([errorLink, requestLink, stateLink, terminalLink]),
      cache,
    });
  }
}
