import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { FeatureFlagService } from '@services/feature-flag.service';

@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('toggleFeature') featureName: string;

  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
