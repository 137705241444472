import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceIllegalCharacters',
})
export class FormatBreadcrumbsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value = value.replace(/#/g, '/').trim();
    return value;
  }
}
