import { Injectable } from '@angular/core';
import { uniq, get } from 'lodash';
import { of } from 'rxjs';
import { flatMap, toArray, take, map } from 'rxjs/operators';
import {
  GetTradeDetails,
  GetBatchSummary,
  GetBatchTest,
} from '@generatedTypes/graphql';
import {
  AdminManagementActionResult,
  ClassifyResultType,
  NotificationDialogType,
  RemediationManagement,
  SortAsType,
} from '@types';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SessionService } from './session.service';
const moment = _rollupMoment || _moment;
import { fill } from 'lodash';
import { MatDialogRef, MatDialog } from '@angular/material';
import { NotificationDialogComponent } from '@shared/components/notification-dialog/notification-dialog.component';
import { SelectGroup } from '@shared/components/filter-selector/filter-selector.component';

type BatchTest = GetBatchTest.GetBatchTest;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  notificationDialogRef: MatDialogRef<NotificationDialogComponent>;

  constructor(
    private sessionService: SessionService,
    private dialog: MatDialog,
  ) {}

  sortFactor = 'test.result_id';
  sortOrder = 0;
  scrollingMap: any = {};
  saveScrollPositionsForKey({ x, y }, key) {
    this.scrollingMap[key] = { x, y };
  }
  getScrollPositionForKey(key) {
    return this.scrollingMap[key];
  }

  sortArray(
    array: [any],
    property?: string,
    direction: 'DESC' | 'ASC' = 'DESC',
    sortAsType: SortAsType = SortAsType.STRING,
  ) {
    const sortedArray = Object.assign(array);
    return sortedArray.sort((a, b) => {
      let aComp = a;
      let bComp = b;
      if (property) {
        aComp = get(a, property);
        bComp = get(b, property);
      }
      let sortOrder = 1;
      sortOrder = direction === 'DESC' ? 1 : -1;

      if (sortAsType === SortAsType.DATE) {
        return moment.utc(aComp).diff(moment(bComp)) * sortOrder;
      } else if (sortAsType === SortAsType.TIME) {
        return (
          moment(aComp, 'hh:mm A').diff(moment(bComp, 'hh:mm A')) * sortOrder
        );
      } else if (sortAsType === SortAsType.NUMBER) {
        return (aComp - bComp) * sortOrder;
      } else if (sortAsType === SortAsType.STRING) {
        if (aComp === bComp) {
          return 0;
        } else if (!aComp) {
          return 1;
        } else if (!bComp) {
          return -1;
        } else {
          return direction === 'DESC'
            ? aComp.localeCompare(bComp)
            : bComp.localeCompare(aComp);
        }
      } else {
        return (aComp - bComp) * sortOrder;
      }
    });
  }

  titleCase(str: string) {
    const words: Array<string> = str.toLowerCase().split(' ');

    for (const i in words) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }
    return words.join(' ');
  }

  findSmallestAndLargestAxis(axisData: any, xAxisTicks) {
    const domain = [];
    for (const item of axisData) {
      if (item && item.data) {
        for (const group of item.data) {
          let smallestSum = 0;
          let biggestSum = 0;
          for (const d of group.series) {
            if (d.value < 0) {
              smallestSum += d.value;
            } else {
              biggestSum += d.value;
            }
          }
          domain.push(smallestSum);
          domain.push(biggestSum);
        }
      }
    }

    const min = Math.min(0, ...domain);
    const max = xAxisTicks
      ? Math.max(...xAxisTicks, ...domain)
      : Math.max(...domain);
    return [min, max];
  }

  setXAxis(
    xAxisTicks: Array<number>,
    ticks: number,
    maxFromChart: Array<number>,
  ) {
    const maxValue = Math.max(...xAxisTicks, ...maxFromChart);
    const xScaleMax = Math.ceil(maxValue / 100) * 100;
    const tick = xScaleMax / ticks;
    const initTicks = Array.apply(null, Array(ticks + 1)).map((item, i) => {
      return i * tick;
    });

    return {
      xScaleMax,
      initTicks,
    };
  }
  dateWithinRange(
    start: string | _moment.Moment,
    end: string | _moment.Moment,
    target: string | _moment.Moment,
  ) {
    if (typeof start === 'string') {
      start = moment.utc(start);
    }
    if (typeof end === 'string') {
      end = moment.utc(end);
    }
    return (<_moment.Moment>target).isBetween(start, end, null, '[]');
  }
  getPositionKey(type?: string) {
    // when under ESMA (EMIR, MIFID, etc.), "Position" reports are called "Trade State" Reports

    if (!type) {
      type = 'position';
    }
    if (type.toLowerCase() !== 'position') {
      return 'submission';
    } else {
      if (
        this.sessionService.selectedRegulator$.value.toUpperCase() === 'EMIR'
      ) {
        return 'trade state';
      } else {
        return 'position';
      }
    }
  }

  packageFieldDetailsData(
    tradeDetails: Array<GetTradeDetails.GetTradeDetails>,
  ) {
    const keys = [];
    const fieldDetails = [];
    const executions = [];
    const fields = Object.assign([], tradeDetails);
    of(fields)
      .pipe(
        take(1),
        flatMap(items => items),
        map((item: any) => {
          if (item && item.executions) {
            item.executions.map(field => {
              const key = `${moment(field.report_date).format('D MMM YYYY')}`;
              if (!keys.includes(key)) {
                keys.push(key);
                executions.push({
                  key: key,
                  value: fill(Array(fields.length), ''),
                });
              }
            });
          }
          if (item && item.field_details) {
            item.field_details.map(field => {
              if (!keys.includes(field.key)) {
                keys.push(field.key);
                fieldDetails.push({
                  key: field.key,
                  value: fill(Array(fields.length), ''),
                });
              }
            });
          }
        }),
        toArray(),
      )
      .subscribe(_ => {
        tradeDetails.map((item, index) => {
          if (item && item.executions) {
            item.executions.map(field => {
              executions.filter(
                value =>
                  value.key ===
                  `${moment(field.report_date).format('D MMM YYYY')}`,
              )[0].value[index] = field.nbr_failed;
            });
          }
          if (item && item.field_details) {
            item.field_details.map(field => {
              fieldDetails.filter(value => value.key === field.key)[0].value[
                index
              ] = field.value;
            });
          }
        });
      });
    return [fieldDetails, executions];
  }

  getPackagedFilter(
    data: any,
    display: string,
    selector: string,
    format?: any,
  ): SelectGroup {
    const uniqs = uniq<string>(
      data.map(d => {
        return format ? format(d) : get(d, `${selector}`);
      }),
    );
    return {
      display: `${display}`,
      selector: `${selector}`,
      items: uniqs
        .filter(item => item !== null)
        .map(display => ({ display: display })),
    };
  }

  openDialog(
    notificationType:
      | ClassifyResultType
      | AdminManagementActionResult
      | NotificationDialogType
      | RemediationManagement,
  ) {
    this.notificationDialogRef = this.dialog.open(NotificationDialogComponent, {
      data: notificationType,
      panelClass: 'border-none',
      width: '530px',
    });
    return this.notificationDialogRef.componentInstance.btnClick.pipe(take(1));
  }

  openModal(batchTest: BatchTest, target, currentBatchTestIndex) {
    const assetTest = batchTest.asset_tests[currentBatchTestIndex];
    const viewMoreDate = {
      type: `Diagnostics #${batchTest.test.result_id}: ${assetTest.asset_class.nme}`,
    };

    if (target === 0) {
      Object.assign(viewMoreDate, {
        title: 'Fields impacted: Position report',
        content: (<any>assetTest.fields_impacted).Position,
      });
    } else if (target === 1) {
      Object.assign(viewMoreDate, {
        title: 'Applicable products',
        content: assetTest.applicable_products,
      });
    } else if (target === 2) {
      Object.assign(viewMoreDate, {
        title: 'Fields impacted: Submission report',
        content: (<any>assetTest.fields_impacted).Submission,
      });
    }

    return viewMoreDate;
  }

  sortFilterItems(display, assignments) {
    const ASSET_CLASS_ORDER = [
      'Credit',
      'Rates',
      'Equities',
      'Foreign Exchange',
      'Commodities',
      'ETD',
    ];
    const PRIORITY_ORDER = ['LOW', 'MEDIUM', 'HIGH'];
    const RESPONSIBLE_PARTY_ORDER = [
      'Our Firm',
      '3rd Party',
      'SEF/DCO',
      'Counterparty',
      'Submission report',
    ];
    let result;
    if (display === 'Priority') {
      result = assignments.sort((assignmentA, assignmentB) => {
        return (
          PRIORITY_ORDER.indexOf(assignmentA.priority) -
          PRIORITY_ORDER.indexOf(assignmentB.priority)
        );
      });
    } else if (display === 'Asset class') {
      result = assignments.sort((assignmentA, assignmentB) => {
        return (
          ASSET_CLASS_ORDER.indexOf(assignmentA.asset_class.nme) -
          ASSET_CLASS_ORDER.indexOf(assignmentB.asset_class.nme)
        );
      });
    } else if (display === 'Responsible party') {
      result = assignments.sort((assignmentA, assignmentB) => {
        return (
          RESPONSIBLE_PARTY_ORDER.indexOf(assignmentA.report_entry_type_nme) -
          RESPONSIBLE_PARTY_ORDER.indexOf(assignmentB.report_entry_type_nme)
        );
      });
    } else {
      result = assignments;
    }
    return result;
  }
}
