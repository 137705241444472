export interface OAuthConfig {

  clientId?: string;

  url?: string;

  scope?: string;

  tokenEndpoint?: string;

  userinfoEndpoint?: string;

  logoutPortal?: string;

  responseType?: string;

  loginUrl?: string;

  logoutUrl?: string;

  redirectUri?: string;

  logoutRedirectUri?: string;

  silentRefreshRedirectUri?: string;

  silentRefreshInterval?: number;

  silentRefreshTimeout?: number;

  logoutTimeout?: number;

  sessionTimeout?: number;

  accessTokenKey?: string;

  idTokenKey?: string;

  expiresAtKey?: string;

  userActionEventNames?: string[];

  loginSuccess?: () => void;

  silentRefreshSuccess?: () => void;

  silentRefreshError?: () => void;

  stayConnectConfirm?: () => Promise<boolean>;

}

const defaultOAuthConfig: OAuthConfig = {
  scope: 'ventures',
  tokenEndpoint: '/oauth2/realms/venturessso/authorize',
  userinfoEndpoint: '/oauth2/realms/venturessso/userinfo',
  logoutPortal: '/XUI/?realm=%2Fventuressso#logout/&goto=',
  responseType: 'id_token token',
  silentRefreshInterval: 10 * 60 * 1000, // 10 mins
  silentRefreshTimeout: 60 * 1000, // 60 secs
  logoutTimeout: 60 * 1000, // 60 secs
  sessionTimeout: 20 * 60 * 1000, // 20 mins
  accessTokenKey: 'access_token',
  idTokenKey: 'id_token',
  expiresAtKey: 'expires_at',
  userActionEventNames: ['scroll', 'resize', 'mousemove', 'touchstart', 'keydown', 'mousedown'],
  silentRefreshError: () => nvOAuthService.logout(),
  stayConnectConfirm: () => nvOAuthService.extendSessionDialog(),

}

export class nvOAuthService {

  private static config: OAuthConfig;

  private static storage: Storage;

  private static silentRefreshPostMessageEventListener: EventListener;

  private static userActionEventListener: EventListener;

  private static userSessionTimer: any;

  /**
   * Use this method to configure the service
   * @param config the configuration
   * @param storage localStorage or sessionStorage
   */
  public static initial(config: OAuthConfig, storage: Storage = localStorage): void {
    this.config = { ...defaultOAuthConfig, ...config };
    this.storage = storage;
    if (this.tryLoginImplicitFlow()) {
      this.loginSuccess();
    }
    this.setupTimer();
  }

  /**
   * Checks whether there are tokens in the hash fragment
   * as a result of the implicit flow. These tokens are
   * parsed, validated and used to sign the user in to the
   * current client.
   */
  public static tryLoginImplicitFlow(customHashFragment?: string): boolean {
    const parts = this.getHashFragmentParams(customHashFragment);
    const accessToken = parts['access_token'];
    const idToken = parts['id_token'];
    const expiresIn = parts['expires_in'];

    if (parts['error']) {
      return false;
    }

    if (accessToken) {
      this.storage.setItem(this.config.accessTokenKey, accessToken);
    }

    if (idToken) {
      this.storage.setItem(this.config.idTokenKey, idToken);
    }

    if (expiresIn) {
      const expiresAt = new Date().getTime() +
        (this.config.sessionTimeout ? Math.min(this.config.sessionTimeout, expiresIn * 1000) : expiresIn * 1000);
      this.storage.setItem(this.config.expiresAtKey, expiresAt.toString());
    }

    if (accessToken && idToken && expiresIn) {
      location.hash = '';
      return true;
    }
    return false;
  }

  /**
    * Starts the implicit flow and redirects to user to
    * the auth servers' login url.
    */
  public static initImplicitFlow(): void {
    location.href = this.createLoginUrl(this.config.redirectUri);
  }

  /**
   * Removes all tokens and logs the user out.
   * If a logout url is configured, the user is
   * redirected to it.
   */
  public static logout(): void {
    this.storage.removeItem(this.config.accessTokenKey);
    this.storage.removeItem(this.config.idTokenKey);
    this.storage.removeItem(this.config.expiresAtKey);
    location.href = this.createLogoutUrl(this.config.logoutRedirectUri);
  }

  /**
   * Displays a dialog to ask user to extend session
   */
  public static extendSessionDialog(): Promise<boolean> {
    return new Promise((resolve)=>{
      const response = confirm('You have been inactive for a little. Would you like to extend your session?')
      response ? resolve(true): resolve(false);
    })
  }


  /**
   * Performs a silent refresh for implicit flow.
   * Use this method to get new tokens when/before
   * the existing tokens expire.
   */
  public static silentRefresh(): void {
    const silentRefreshIFrameName = 'slient-refreseh-iframe';
    const existingIframe = document.getElementById(silentRefreshIFrameName);

    if (existingIframe) {
      document.body.removeChild(existingIframe);
    }

    this.setupSilentRefreshEventListener();

    const iframe = document.createElement('iframe');
    iframe.id = silentRefreshIFrameName;
    iframe.style.display = 'none';
    iframe.src = this.createLoginUrl(this.config.silentRefreshRedirectUri);
    document.body.appendChild(iframe);

    setTimeout(() => {
      if (this.silentRefreshPostMessageEventListener) {
        this.removeSilentRefreshEventListener();
        this.silentRefreshError();
      }
    }, this.config.silentRefreshTimeout);
  }

  /**
   * Returns the current id_token.
   */
  public static getIdToken(): string {
    return this.storage ? this.storage.getItem(this.config.idTokenKey) : null;
  }

  /**
   * Returns the current access_token.
   */
  public static getAccessToken(): string {
    return this.storage ? this.storage.getItem(this.config.accessTokenKey) : null;
  }

  /**
   * Returns the expiration date
   * as milliseconds since 1970.
   */
  public static getExpiration(): number {
    if (!this.storage || !this.storage.getItem(this.config.expiresAtKey)) {
      return null;
    }
    return parseInt(this.storage.getItem(this.config.expiresAtKey), 10);
  }

  /**
   * Checks whether there is authenticated.
   */
  public static isAuthenticated(): boolean {
    if (this.getIdToken() && this.getAccessToken()) {
      const expiresAt = this.getExpiration();
      const now = new Date();
      return expiresAt && expiresAt >= now.getTime();
    }

    return false;
  }

  private static setupTimer(): void {
    const expiration = this.getExpiration();
    if (expiration && this.getIdToken() && this.getAccessToken()) {
      this.clearTimer();
      this.setupUserActionEventListener();
      const diff = expiration - new Date().getTime() - this.config.logoutTimeout;
      const interval = this.config.silentRefreshInterval > 0 ? this.config.silentRefreshInterval : Number.MAX_SAFE_INTEGER;
      const timeout = Math.min(diff, interval);
      this.userSessionTimer = setTimeout(() => {
        if (!this.userActionEventListener) {
          // silent refresh when user has action
          this.silentRefresh();
          return;
        }

        this.removeUserActionEventListener();
        if (this.getExpiration() - new Date().getTime() > this.config.logoutTimeout) {
          this.setupTimer();
          return;
        }


        Promise.race([
          this.config.stayConnectConfirm(), // wait for user response
          new Promise(resolve => setTimeout(() => resolve(false), this.config.logoutTimeout)) // or default period
        ]).then((res: boolean) => {
          if (res) {
            this.silentRefresh();
          } else {
            this.logout();
          }
        });
      }, timeout);
    }
  }

  private static setupUserActionEventListener(): void {
    this.removeUserActionEventListener();

    this.userActionEventListener = () => {
      this.removeUserActionEventListener();
    };

    this.config.userActionEventNames.forEach(eventName => document.addEventListener(eventName, this.userActionEventListener));
  }

  private static removeUserActionEventListener(): void {
    if (this.userActionEventListener) {
      this.config.userActionEventNames.forEach(eventName => document.removeEventListener(eventName, this.userActionEventListener));
      this.userActionEventListener = null;
    }
  }

  private static clearTimer(): void {
    if (this.userSessionTimer) {
      clearTimeout(this.userSessionTimer);
      this.userSessionTimer = null;
    }
  }

  private static createLoginUrl(redirectUri: string): string {
    const { url, tokenEndpoint, responseType, clientId, scope, loginUrl } = this.config;
    const query = `response_type=${encodeURIComponent(responseType)}&client_id=${clientId}&scope=${scope}&redirect_uri=${encodeURIComponent(redirectUri)}`;
    return `${loginUrl || (url + tokenEndpoint)}?${query}`;
  }

  private static createLogoutUrl(logoutRedirectUri: string): string {
    const { url, logoutPortal, logoutUrl } = this.config;
    return `${logoutUrl || (url + logoutPortal)}${logoutRedirectUri}`;
  }

  private static getHashFragmentParams(customHashFragment?: string): object {
    let hash = customHashFragment || window.location.hash;

    hash = decodeURIComponent(hash);

    if (hash.indexOf('#') !== 0) {
      return {};
    }

    const questionMarkPosition = hash.indexOf('?');

    if (questionMarkPosition > -1) {
      hash = hash.substr(questionMarkPosition + 1);
    } else {
      hash = hash.substr(1);
    }

    return this.parseQueryString(hash);
  }

  private static parseQueryString(queryString: string): object {
    const data = {};
    let
      pairs: string[],
      pair: string,
      separatorIndex: number,
      escapedKey: string,
      escapedValue: string,
      key: string,
      value: string;

    if (queryString === null) {
      return data;
    }

    pairs = queryString.split('&');

    for (let i = 0; i < pairs.length; i++) {
      pair = pairs[i];
      separatorIndex = pair.indexOf('=');

      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }

      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);

      if (key.substr(0, 1) === '/') { key = key.substr(1); }

      data[key] = value;
    }

    return data;
  }

  private static processMessageEventMessage(e: MessageEvent): string {
    let expectedPrefix = '#';

    if (!e || !e.data || typeof e.data !== 'string') {
      return;
    }

    const prefixedMessage: string = e.data;

    if (!prefixedMessage.startsWith(expectedPrefix)) {
      return;
    }

    return '#' + prefixedMessage.substr(expectedPrefix.length);
  }

  private static loginSuccess(): void {
    if (this.config.loginSuccess) {
      this.config.loginSuccess();
    }
  }

  private static removeSilentRefreshEventListener(): void {
    if (this.silentRefreshPostMessageEventListener) {
      window.removeEventListener('message', this.silentRefreshPostMessageEventListener);
      this.silentRefreshPostMessageEventListener = null;
    }
  }

  private static setupSilentRefreshEventListener(): void {
    this.removeSilentRefreshEventListener();

    this.silentRefreshPostMessageEventListener = (e: MessageEvent) => {
      const message = this.processMessageEventMessage(e);
      if (!this.tryLoginImplicitFlow(message)) {
        this.silentRefreshError();
      } else {
        this.silentRefreshSuccess();
        this.setupTimer();
      }
      this.removeSilentRefreshEventListener();
    };

    window.addEventListener('message', this.silentRefreshPostMessageEventListener);
  }

  private static silentRefreshError(): void {
    if (this.config.silentRefreshError) {
      this.config.silentRefreshError();
    }
  }

  private static silentRefreshSuccess(): void {
    if (this.config.silentRefreshSuccess) {
      this.config.silentRefreshSuccess();
    }
  }
}