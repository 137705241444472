import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter',
  pure: false,
})
export class CustomFilterPipe implements PipeTransform {
  transform(values: any, args?: any): any {
    const result = _.find<any>(values, args);
    if (!result) {
      return { nbr_failed: 0, nbr_total: 0, failed_percent: 0 };
    }

    return {
      ...result,
      nbr_total: result.nbr_failed + result.nbr_passed,
      failed_percent:
        result.nbr_failed / (result.nbr_failed + result.nbr_passed),
    };
  }
}
