import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPercent',
  pure: false,
})
export class FormatPercentPipe implements PipeTransform {
  transform(value: number[] | number, args?: any): any {
    let numerator = 0,
      denominator = 1;

    if (typeof value === 'number' && !isNaN(value)) {
      numerator = value;
    } else if (Array.isArray(value) && value.length > 1) {
      numerator = value[0];
      denominator = value[1];
    }

    const quotient =
      denominator === 0 ? 0 : _.clamp(numerator / denominator, 0, 1) * 100;
    if (quotient < 1) {
      return '0%';
    }
    if (quotient > 99 && quotient < 100) {
      return '>99%';
    }
    return Math.round(quotient) + '%';
  }
}
