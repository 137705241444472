import { Injectable } from '@angular/core';
import {
  FetchOrganizationDetailsGQL,
  ListOrganizationsGQL,
  FetchOrganizationDetailsInput,
  ListOrganizationsInput,
  ChangeOrganizationNameInput,
  ChangeOrganizationStatusInput,
  ChangeOrganizationStatusGQL,
  ChangeOrganizationNameGQL,
  UpsertTenantGQL,
  GetTenantsGQL,
} from '@generatedTypes/graphql';
import { map, catchError, take, flatMap } from 'rxjs/operators';
import envVars from '@config/envVars';
import { of, Observable, iif, throwError } from 'rxjs';
import { OrganizationOperationResult } from '@types';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(
    private fetchOrganizationDetailsGQL: FetchOrganizationDetailsGQL,
    private listOrganizationsGQL: ListOrganizationsGQL,
    private changeOrganizationStatusGQL: ChangeOrganizationStatusGQL,
    private changeOrganizationNameGQL: ChangeOrganizationNameGQL,
    private upsertTenantGQL: UpsertTenantGQL,
    private getTenantsGQL: GetTenantsGQL,
  ) {}

  fetchOrganizationDetails(input: FetchOrganizationDetailsInput) {
    return this.fetchOrganizationDetailsGQL
      .watch(
        {
          input: {
            ...input,
            product: envVars().IAM_PRODUCT,
          },
        },
        { fetchPolicy: 'network-only' },
      )
      .valueChanges.pipe(
        map(({ data }) => {
          return data.IAMfetchOrganizationDetails;
        }),
        catchError(err => {
          return of(err);
        }),
      );
  }

  listOrganizations(input?: ListOrganizationsInput) {
    return this.listOrganizationsGQL
      .watch(
        {
          input: {
            ...input,
            product: envVars().IAM_PRODUCT,
          },
        },
        { fetchPolicy: 'network-only' },
      )
      .valueChanges.pipe(
        map(({ data }) => {
          return data.IAMlistOrganizations;
        }),
      );
  }

  changeOrganizationStatus(
    input: ChangeOrganizationStatusInput,
  ): Observable<OrganizationOperationResult> {
    return this.changeOrganizationStatusGQL
      .mutate(
        {
          input: {
            ...input,
            product: envVars().IAM_CLIENT_ID,
          },
        },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.listOrganizationsGQL.document,
              variables: {
                input: {
                  product: envVars().IAM_CLIENT_ID,
                },
              },
            },
          ],
        },
      )
      .pipe(
        take(1),
        map(({ data }) => {
          return data.IAMchangeOrganizationStatus;
        }),
        catchError(err => of(err)),
      );
  }

  changeOrganizationName(
    input: ChangeOrganizationNameInput,
    tenantKey: string,
  ): Observable<OrganizationOperationResult> {
    return this.changeOrganizationNameGQL
      .mutate(
        {
          input: {
            ...input,
            product: envVars().IAM_CLIENT_ID,
          },
        },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getTenantsGQL.document,
            },
          ],
        },
      )
      .pipe(
        take(1),
        flatMap(({ data }) =>
          iif(
            () => data.IAMchangeOrganizationName.status === 'SUCCESS',
            of(true),
            of(false),
          ),
        ),
        map(_ => ({
          upsertTenantInput: { nme: input.newName, tenant_key: tenantKey },
        })),
        flatMap((input: any) => this.upsertTenantGQL.mutate(input)),
        map((res: any) => res.data.upsertTenant),
      );
  }
}
