import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-modal-close',
  templateUrl: './modal-close.component.html',
  styleUrls: ['./modal-close.component.scss'],
})
export class ModalCloseComponent implements OnInit {
  data: string;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalCloseComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {}

  ngOnInit() {
    this.data = this.dialogData;
  }
}
