export type Maybe<T> = T | null;

export interface GetAuditLogsInput {
  batch_test_key?: Maybe<Uuid>;

  test_key?: Maybe<Uuid>;

  tenant_key?: Maybe<Uuid>;
}

export interface BatchInput {
  limit: number;

  offset?: Maybe<number>;

  order?: Maybe<string>;

  orderDirection?: Maybe<string>;

  regulator_name?: Maybe<string>;
}

export interface BatchTestWhere {
  result_status_key?: Maybe<(Maybe<number>)[]>;

  test_key?: Maybe<Uuid>;
}

export interface TradeDetailInput {
  asset_test_key?: Maybe<Uuid>;

  batch_test_key?: Maybe<Uuid>;

  limit?: Maybe<number>;
}

export interface CommentWhere {
  test_key?: Maybe<Uuid>;
}

export interface AuditLogWhere {
  test_key?: Maybe<Uuid>;
}

export interface BatchTestInput {
  batch_test_key?: Maybe<Uuid>;

  batch_key?: Maybe<Uuid>;

  result_id?: Maybe<number>;
}

export interface GetCommentInput {
  test_key?: Maybe<Uuid>;

  tenant_key?: Maybe<Uuid>;

  limit?: Maybe<number>;

  offset?: Maybe<number>;
}

export interface QueryIssueInput {
  asset_test_key: (Maybe<number>)[];
}

export interface JobInput {
  job_key?: Maybe<string>;
}

export interface JobPayload {
  job_key?: Maybe<string>;

  payload?: Maybe<Json>;

  status?: Maybe<JobStatus>;
}

export interface RegulationInput {
  result_id: number;
}

export interface FailureRateDetailInput {
  regulator_name: string;
}

export interface HighPassInput {
  regulator_name: string;
}

export interface HighPassDetailInput {
  regulator_name: string;
}

export interface FailureRateInput {
  regulator_name: string;
}

export interface ActivityTrendsInput {
  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  regulator_name?: Maybe<string>;
}

export interface ActivityTrendsDetailInput {
  start_date?: Maybe<string>;

  end_date?: Maybe<string>;

  regulator_name?: Maybe<string>;
}

export interface TotalRecordsInput {
  regulator_name?: Maybe<string>;
}

export interface ReportEntryTypeInput {
  report_entry_name?: Maybe<string>;
}

export interface TenantInput {
  active?: Maybe<boolean>;
}

export interface TenantByIdInput {
  tenant_key?: Maybe<Uuid>;
}

export interface TradeDetailsInput {
  limit?: Maybe<number>;

  asset_test_result_key?: Maybe<Uuid>;
}

export interface GetUsersInput {
  tenant_key?: Maybe<Uuid>;
}

export interface GetUserByIdInput {
  user_key?: Maybe<Uuid>;
}

export interface ListUsersInput {
  product: string;

  organizationId?: Maybe<string>;

  email?: Maybe<string>;

  registrationStatus?: Maybe<RegistrationStatusEnum>;

  disabled?: Maybe<boolean>;
}

export interface FetchUserInput {
  email?: Maybe<string>;

  userId?: Maybe<string>;

  organizationId: string;

  product?: Maybe<string>;

  requestingUserEmail?: Maybe<string>;

  accessToken?: Maybe<string>;
}

export interface ListUserHistoryInput {
  email: string;

  product: string;

  organization: string;

  accessToken?: Maybe<string>;
}

export interface AccessTokenInput {
  accessToken: string;
}

export interface FetchOrganizationDetailsInput {
  /** Unique identifier for the requested organization */
  organizationId: string;

  product?: Maybe<string>;
}

export interface ListOrganizationsInput {
  product: string;
}

export interface UpsertIssueInput {
  issue_key?: Maybe<Uuid>;

  issue_owner_key?: Maybe<Uuid>;

  asset_test_key: Uuid;

  risk_assessment_key?: Maybe<Uuid>;

  remediations: (Maybe<UpsertRemediationInput>)[];
}

export interface UpsertRemediationInput {
  remediation_key?: Maybe<Uuid>;

  user_key?: Maybe<Uuid>;

  priority?: Maybe<RemediationPriority>;

  report_entry_type_key?: Maybe<Uuid>;

  target_resolution_date?: Maybe<Date>;

  is_all_submission?: Maybe<boolean>;
}

export interface ChangeAssignmentStatusInput {
  issue_key?: Maybe<Uuid>;

  remediation_key?: Maybe<Uuid>;

  status: IssueStatus;
}

export interface UpsertTenantInput {
  tenant_key?: Maybe<Uuid>;

  nme: string;

  regulators?: Maybe<(Maybe<string>)[]>;

  features?: Maybe<FeaturesInput>;

  active?: Maybe<boolean>;

  type?: Maybe<TenantTypes>;
}

export interface FeaturesInput {
  uploadReport?: Maybe<UploadReportConfigurationInput>;

  directDownloadTradeDetails?: Maybe<DirectDownloadConfigurationInput>;
}

export interface UploadReportConfigurationInput {
  enabled: boolean;

  options?: Maybe<Json>;
}

export interface DirectDownloadConfigurationInput {
  enabled: boolean;

  options?: Maybe<Json>;
}

export interface AcceptTermsOfAgreementsMutation {
  terms_key?: Maybe<Uuid>;
}

export interface TradeDetailsMutationInput {
  limit?: Maybe<number>;

  asset_test_result_key?: Maybe<Uuid>;
}

export interface TradeDetailConfigurationInput {
  batch_test_key?: Maybe<Uuid>;

  configuration?: Maybe<Json>;
}

export interface UserInput {
  email: string;

  first_nme?: Maybe<string>;

  last_nme?: Maybe<string>;

  phone?: Maybe<string>;

  password?: Maybe<string>;

  job_title?: Maybe<string>;

  department?: Maybe<string>;

  avatar_img?: Maybe<string>;

  tenant_key?: Maybe<Uuid>;

  user_role_key?: Maybe<Uuid>;

  user_status_key?: Maybe<Uuid>;

  company?: Maybe<string>;

  settings?: Maybe<Json>;

  email_alias?: Maybe<string>;

  user_key?: Maybe<Uuid>;

  type?: Maybe<UserFromType>;

  configuration?: Maybe<Json>;
}

export interface InviteUserInput {
  users: (Maybe<InviteUserType>)[];
  /** New - OrganizationId of target organization (v2r0) */
  organizationId: string;

  product: string;

  requestingUserEmail?: Maybe<string>;

  accessToken?: Maybe<string>;
}

export interface InviteUserType {
  email: string;

  role: UserRoleEnum;
}

export interface RegisterUserInput {
  email: string;

  firstName: string;

  lastName: string;

  password?: Maybe<string>;

  mobileNumber?: Maybe<string>;

  registrationToken: string;

  product: string;
}

export interface DeleteUserInput {
  email: string;

  organizationId: string;

  requestingUserEmail?: Maybe<string>;

  accessToken?: Maybe<string>;

  product?: Maybe<string>;
}

export interface UpdateUserInput {
  email: string;
  /** New - OrganizationId of target organization (v2r0) */
  organizationId: string;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  mobileNumber?: Maybe<string>;

  product?: Maybe<string>;

  accessToken?: Maybe<string>;
}

export interface ResendUserInviteInput {
  email: string;
  /** New - OrganizationId of target organization (v2r0) */
  organizationId: string;

  requestingUserEmail?: Maybe<string>;

  product?: Maybe<string>;

  accessToken?: Maybe<string>;
}

export interface ChangeUserAccessStatusInput {
  email: string;

  organizationId: string;

  accessStatus: AccessStatusEnum;

  requestingUserEmail: string;

  accessToken?: Maybe<string>;

  product?: Maybe<string>;
}

export interface ChangeUserRoleInput {
  email: string;

  organizationId: string;

  role: UserRoleEnum;

  requestingUserEmail: string;

  product?: Maybe<string>;

  accessToken?: Maybe<string>;
}

export interface ChangeEmailInput {
  userId: string;

  requestedEmail: string;

  organizationId: string;

  product: string;
}

export interface VerifyEmailInput {
  userId: string;

  product: string;

  emailVerificationToken: string;
}

export interface OnboardOrganizationInput {
  product: string;

  organizationName: string;

  adminEmail: string;
  /** Optional - Comma separated list of ALLOWED domains for user registration '@pwc.com, @pwc.co.us, @pwc.co.in' */
  allowedDomains?: Maybe<string>;
  /** Optional - Comma separated list of RESTRICTED domains for user registration '@pwc.co.uk' */
  restrictedDomains?: Maybe<string>;
  /** Optional - Set this to true to allow creation of an organization which is a duplicate of an existing organization */
  allowDuplicateOrganizationName?: Maybe<boolean>;
}

export interface ChangeOrganizationNameInput {
  organizationId: string;

  newName: string;

  product?: Maybe<string>;
  /** Optional - allow change even if duplicate (default: false) */
  allowDuplicateName?: Maybe<boolean>;
}

export interface ChangeOrganizationStatusInput {
  organizationId: string;

  action: OrganizationStatusEnum;

  product?: Maybe<string>;
}

export interface BatchesInput {
  limit?: Maybe<number>;

  order?: Maybe<string>;

  orderDirection?: Maybe<string>;

  regulator_name?: Maybe<string>;
}

export interface ProductUserCountInput {
  requestingAdminEmail: string;

  product: string;
}

export enum IssueStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export enum RiskAssessmentEnum {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export enum RemediationPriority {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export enum UserFromType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum JobStatus {
  Failed = 'failed',
  Success = 'success',
  Pending = 'pending',
}

export enum RegistrationStatusEnum {
  Registered = 'REGISTERED',
  InviteSent = 'INVITE_SENT',
  VerificationEmailSent = 'VERIFICATION_EMAIL_SENT',
  SendEmailFailed = 'SEND_EMAIL_FAILED',
}

export enum UserStatusEnum {
  UserEnabled = 'USER_ENABLED',
  UserDisabled = 'USER_DISABLED',
}

export enum UserRoleEnum {
  Member = 'MEMBER',
  Admin = 'ADMIN',
}

export enum ResponseStatusEnum {
  InviteSentSuccess = 'INVITE_SENT_SUCCESS',
  InviteSentFailed = 'INVITE_SENT_FAILED',
  RegistrationSuccess = 'REGISTRATION_SUCCESS',
  RegistrationFailed = 'REGISTRATION_FAILED',
  UserDisabled = 'USER_DISABLED',
  UserEnabled = 'USER_ENABLED',
  ResendInviteSuccess = 'RESEND_INVITE_SUCCESS',
  ResendInviteFailed = 'RESEND_INVITE_FAILED',
  UserOrgDisabled = 'USER_ORG_DISABLED',
  UserOrgEnabled = 'USER_ORG_ENABLED',
}

export enum ListOrganizationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AllowedStatuses {
  ReviewRequired = 'REVIEW_REQUIRED',
  IssueConfirmed = 'ISSUE_CONFIRMED',
  NoIssue = 'NO_ISSUE',
  Passed = 'PASSED',
}

export enum TenantTypes {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum AccessStatusEnum {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum OrganizationStatusEnum {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

/** UUID custom scalar type */
export type Uuid = any;

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type Json = any;

export type Date = any;

/** The `Upload` scalar type represents a file upload. */
export type Upload = any;

// ====================================================
// Documents
// ====================================================

export namespace AcceptTermsOfAgreements {
  export type Variables = {
    termsKey?: Maybe<Uuid>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    acceptTermsOfAgreements: Maybe<AcceptTermsOfAgreements>;
  };

  export type AcceptTermsOfAgreements = {
    __typename?: 'User';

    user_key: Uuid;
  };
}

export namespace ChangeAssetTestStatus {
  export type Variables = {
    assetTestKey: Uuid;
    resultStatus: AllowedStatuses;
    comment: string;
    batchTestKey: Uuid;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    changeAssetTestStatus: Maybe<ChangeAssetTestStatus>;
  };

  export type ChangeAssetTestStatus = {
    __typename?: 'AssetTest';

    asset_test_key: Maybe<Uuid>;

    asset_test_activity: Maybe<(Maybe<AssetTestActivity>)[]>;
  };

  export type AssetTestActivity = {
    __typename?: 'AssetTestActivity';

    asset_test_activity_key: Maybe<Uuid>;

    result_status: Maybe<string>;

    created_at: Maybe<Date>;
  };
}

export namespace ChangeEmail {
  export type Variables = {
    input?: Maybe<ChangeEmailInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMchangeEmail: Maybe<IaMchangeEmail>;
  };

  export type IaMchangeEmail = {
    __typename?: 'ChangeEmailResult';

    message: Maybe<string>;

    status: Maybe<string>;

    user: Maybe<User>;
  };

  export type User = {
    __typename?: 'ChangeEmailType';

    email: Maybe<string>;

    newEmail: Maybe<string>;
  };
}

export namespace ChangeOrganizationName {
  export type Variables = {
    input?: Maybe<ChangeOrganizationNameInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMchangeOrganizationName: Maybe<IaMchangeOrganizationName>;
  };

  export type IaMchangeOrganizationName = {
    __typename?: 'OrganizationOperationResult';

    status: string;

    message: string;

    organization: Maybe<Organization>;
  };

  export type Organization = {
    __typename?: 'OrganizationDetails';

    product: string;

    organizationId: string;

    organizationName: string;

    organizationStatus: Maybe<ListOrganizationStatusEnum>;
  };
}

export namespace ChangeOrganizationStatus {
  export type Variables = {
    input?: Maybe<ChangeOrganizationStatusInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMchangeOrganizationStatus: Maybe<IaMchangeOrganizationStatus>;
  };

  export type IaMchangeOrganizationStatus = {
    __typename?: 'OrganizationOperationResult';

    status: string;

    message: string;

    organization: Maybe<Organization>;
  };

  export type Organization = {
    __typename?: 'OrganizationDetails';

    product: string;

    organizationId: string;

    organizationName: string;

    organizationStatus: Maybe<ListOrganizationStatusEnum>;
  };
}

export namespace CreateBatchTestComment {
  export type Variables = {
    batchTestKey: Uuid;
    commentText: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createBatchTestComment: Maybe<CreateBatchTestComment>;
  };

  export type CreateBatchTestComment = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;

    text: Maybe<string>;

    created_at: Maybe<Date>;

    user: Maybe<User>;

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;

    asset_test_activity: Maybe<(Maybe<AssetTestActivity>)[]>;
  };

  export type User = {
    __typename?: 'User';

    user_key: Uuid;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    email: string;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    asset_test_key: Maybe<Uuid>;

    asset_class: Maybe<AssetClass>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };

  export type AssetTestActivity = {
    __typename?: 'AssetTestActivity';

    asset_test_key: Maybe<Uuid>;

    result_status: Maybe<string>;

    asset_test: Maybe<AssetTest>;
  };

  export type AssetTest = {
    __typename?: 'AssetTest';

    asset_class: Maybe<_AssetClass>;
  };

  export type _AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };
}

export namespace CreateUser {
  export type Variables = {
    userInfo?: Maybe<UserInput>;
    file?: Maybe<Upload>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    createUser: Maybe<boolean>;
  };
}

export namespace DeleteComment {
  export type Variables = {
    commentKey: Uuid;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    deleteComment: Maybe<DeleteComment>;
  };

  export type DeleteComment = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;
  };
}

export namespace DownloadTradeDetails {
  export type Variables = {
    asset_test_result_key?: Maybe<Uuid>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    downloadTradeDetails: Maybe<string>;
  };
}

export namespace FetchOrganizationDetails {
  export type Variables = {
    input?: Maybe<FetchOrganizationDetailsInput>;
  };

  export type Query = {
    __typename?: 'Query';

    IAMfetchOrganizationDetails: Maybe<IaMfetchOrganizationDetails>;
  };

  export type IaMfetchOrganizationDetails = {
    __typename?: 'FullOrganizationOperationResult';

    status: string;

    message: string;

    organization: Maybe<Organization>;
  };

  export type Organization = {
    __typename?: 'FullOrganizationDetails';

    product: string;

    organizationId: string;

    organizationName: string;

    organizationStatus: Maybe<ListOrganizationStatusEnum>;

    onboardAdminEmail: Maybe<string>;

    allowedDomains: Maybe<string>;

    restrictedDomains: Maybe<string>;
  };
}

export namespace FetchUser {
  export type Variables = {
    input?: Maybe<FetchUserInput>;
  };

  export type Query = {
    __typename?: 'Query';

    IAMfetchUser: Maybe<IaMfetchUser>;
  };

  export type IaMfetchUser = {
    __typename?: 'FetchUserResult';

    status: string;

    message: string;

    user: Maybe<User>;
  };

  export type User = {
    __typename?: 'UserInFetchUser';

    userId: Maybe<string>;

    email: Maybe<string>;

    firstName: Maybe<string>;

    lastName: Maybe<string>;

    mobileNumber: Maybe<string>;

    organizationId: Maybe<string>;

    product: Maybe<string>;

    role: Maybe<UserRoleEnum>;

    status: Maybe<string>;

    isProfileCompleted: Maybe<boolean>;
  };
}

export namespace IaMfetchUserDetails {
  export type Variables = {
    input?: Maybe<AccessTokenInput>;
  };

  export type Query = {
    __typename?: 'Query';

    IAMfetchUserDetails: Maybe<IaMfetchUserDetails>;
  };

  export type IaMfetchUserDetails = {
    __typename?: 'UserDetailsResult';

    given_name: Maybe<string>;

    family_name: Maybe<string>;

    email: Maybe<string>;

    group_list: Maybe<(Maybe<string>)[]>;

    organization_id_list: Maybe<(Maybe<string>)[]>;

    name: Maybe<string>;

    userId: Maybe<string>;

    product_list: Maybe<(Maybe<string>)[]>;

    sub: Maybe<string>;

    message: Maybe<string>;

    status: Maybe<string>;

    user_disabled_by_admin: Maybe<(Maybe<string>)[]>;

    user_org_disabled: Maybe<(Maybe<string>)[]>;
  };
}

export namespace GetActivityTrendsDetailChart {
  export type Variables = {
    start_date?: Maybe<string>;
    end_date?: Maybe<string>;
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    activity_trends_detail_report: Maybe<(Maybe<ActivityTrendsDetailReport>)[]>;
  };

  export type ActivityTrendsDetailReport = {
    __typename?: 'ActivityTrendsDetailResults';

    result_status: Maybe<string>;

    week: Maybe<Date>;

    count: Maybe<number>;

    asset_class: Maybe<string>;
  };
}

export namespace GetActivityTrendsChart {
  export type Variables = {
    start_date?: Maybe<string>;
    end_date?: Maybe<string>;
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    activity_trends_report: Maybe<(Maybe<ActivityTrendsReport>)[]>;
  };

  export type ActivityTrendsReport = {
    __typename?: 'ActivityTrendsResults';

    result_status: Maybe<string>;

    week: Maybe<Date>;

    count: Maybe<number>;
  };
}

export namespace GetAllIssueAssignments {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAllIssueAssignments: Maybe<(Maybe<GetAllIssueAssignments>)[]>;
  };

  export type GetAllIssueAssignments = {
    __typename?: 'Assignment';

    issue_key: Maybe<Uuid>;

    remediation_key: Maybe<Uuid>;

    asset_class: Maybe<AssetClass>;

    test: Maybe<Test>;

    issue_owner: Maybe<IssueOwner>;

    priority: Maybe<RemediationPriority>;

    status: Maybe<IssueStatus>;

    batch_test_key: Maybe<Uuid>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    asset_class_key: Maybe<Uuid>;

    order: Maybe<number>;
  };

  export type Test = {
    __typename?: 'Test';

    nme: Maybe<string>;

    test_id: Maybe<string>;

    result_id: Maybe<number>;
  };

  export type IssueOwner = {
    __typename?: 'User';

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    full_nme: Maybe<string>;
  };
}

export namespace GetAssetClasses {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getAssetClasses: Maybe<(Maybe<GetAssetClasses>)[]>;
  };

  export type GetAssetClasses = {
    __typename?: 'AssetClass';

    asset_class_key: Maybe<Uuid>;

    nme: Maybe<string>;

    order: Maybe<number>;
  };
}

export namespace GetAuditLogs {
  export type Variables = {
    input?: Maybe<GetAuditLogsInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getAuditLogs: Maybe<(Maybe<GetAuditLogs>)[]>;
  };

  export type GetAuditLogs = {
    __typename?: 'AuditLog';

    audit_log_key: Maybe<Uuid>;

    user: Maybe<User>;

    event: Maybe<Event>;

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;

    created_at: Maybe<Date>;
  };

  export type User = {
    __typename?: 'User';

    user_key: Uuid;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    email: string;
  };

  export type Event = {
    __typename?: 'Event';

    nme: Maybe<string>;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    asset_class: Maybe<AssetClass>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    prefix: Maybe<string>;

    nme: Maybe<string>;
  };
}

export namespace GetBatches {
  export type Variables = {
    test_key?: Maybe<Uuid>;
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getBatches: Maybe<(Maybe<GetBatches>)[]>;
  };

  export type GetBatches = {
    __typename?: 'Batch';

    report_date: Maybe<Date>;

    batch_key: Maybe<Uuid>;

    batch_tests: Maybe<(Maybe<BatchTests>)[]>;

    regulator: Maybe<Regulator>;

    comments: Maybe<(Maybe<Comments>)[]>;

    other_executions: Maybe<(Maybe<OtherExecutions>)[]>;
  };

  export type BatchTests = {
    __typename?: 'BatchTest';

    batch_test_key: Maybe<Uuid>;
  };

  export type Regulator = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };

  export type Comments = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;

    text: Maybe<string>;

    created_at: Maybe<Date>;

    user: Maybe<User>;

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;

    asset_test_activity: Maybe<(Maybe<AssetTestActivity>)[]>;
  };

  export type User = {
    __typename?: 'User';

    user_key: Uuid;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    email: string;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    asset_test_key: Maybe<Uuid>;

    asset_class: Maybe<AssetClass>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };

  export type AssetTestActivity = {
    __typename?: 'AssetTestActivity';

    asset_test_key: Maybe<Uuid>;

    result_status: Maybe<string>;

    asset_test: Maybe<AssetTest>;
  };

  export type AssetTest = {
    __typename?: 'AssetTest';

    asset_class: Maybe<_AssetClass>;
  };

  export type _AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };

  export type OtherExecutions = {
    __typename?: 'Batch';

    report_date: Maybe<Date>;

    batch_key: Maybe<Uuid>;

    batch_tests: Maybe<(Maybe<_BatchTests>)[]>;
  };

  export type _BatchTests = {
    __typename?: 'BatchTest';

    batch_test_key: Maybe<Uuid>;
  };
}

export namespace GetBatchSummary {
  export type Variables = {
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getBatches: Maybe<(Maybe<GetBatches>)[]>;
  };

  export type GetBatches = {
    __typename?: 'Batch';

    batch_key: Maybe<Uuid>;

    report_date: Maybe<Date>;

    asset_classes: Maybe<(Maybe<AssetClasses>)[]>;

    tenant: Maybe<Tenant>;

    regulator: Maybe<Regulator>;

    batch_tests: Maybe<(Maybe<BatchTests>)[]>;
  };

  export type AssetClasses = {
    __typename?: 'AssetClass';

    asset_class_key: Maybe<Uuid>;

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };

  export type Tenant = {
    __typename?: 'Tenant';

    nme: Maybe<string>;

    tenant_key: Maybe<Uuid>;
  };

  export type Regulator = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };

  export type BatchTests = {
    __typename?: 'BatchTest';

    batch_test_key: Maybe<Uuid>;

    nbr_failed: Maybe<number>;

    nbr_evaluated: Maybe<number>;

    result_status: Maybe<string>;

    test: Maybe<Test>;

    failures_impacting: Maybe<(Maybe<FailuresImpacting>)[]>;
  };

  export type Test = {
    __typename?: 'Test';

    test_key: Maybe<Uuid>;

    nme: Maybe<string>;

    test_id: Maybe<string>;

    result_id: Maybe<number>;

    asset_classes: Maybe<(Maybe<_AssetClasses>)[]>;

    regulations: Maybe<(Maybe<Regulations>)[]>;

    test_kind: Maybe<string>;

    priority: Maybe<string>;
  };

  export type _AssetClasses = {
    __typename?: 'AssetClass';

    prefix: Maybe<string>;

    nme: Maybe<string>;
  };

  export type Regulations = {
    __typename?: 'Regulation';

    regulator: Maybe<string>;

    regulation_header: Maybe<string>;

    regulation_header_dsc: Maybe<string>;

    regulation_url: Maybe<string>;

    exhibit_nme: Maybe<string>;

    short_nme: Maybe<string>;

    related_exhibits: Maybe<(Maybe<RelatedExhibits>)[]>;

    related_descriptions: Maybe<(Maybe<RelatedDescriptions>)[]>;
  };

  export type RelatedExhibits = {
    __typename?: 'RegulationExhibit';

    exhibit_nme: Maybe<string>;

    fields: Maybe<(Maybe<Fields>)[]>;
  };

  export type Fields = {
    __typename?: 'RegulationExhibitField';

    data_field: Maybe<string>;

    comment: Maybe<string>;
  };

  export type RelatedDescriptions = {
    __typename?: 'RegulationDescription';

    short_dsc: Maybe<string>;

    long_dsc: Maybe<string>;
  };

  export type FailuresImpacting = {
    __typename?: 'FailureImpacting';

    nbr_failed: Maybe<number>;

    report_entry_type_nme: Maybe<string>;

    order: Maybe<number>;

    report_type_nme: Maybe<string>;
  };
}

export namespace GetBatchTest {
  export type Variables = {
    input?: Maybe<BatchTestInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getBatchTest: Maybe<GetBatchTest>;
  };

  export type GetBatchTest = {
    __typename?: 'BatchTest';

    batch_test_key: Maybe<Uuid>;

    batch: Maybe<Batch>;

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;

    test: Maybe<_Test>;
  };

  export type Batch = {
    __typename?: 'Batch';

    batch_key: Maybe<Uuid>;

    tenant: Maybe<Tenant>;

    report_date: Maybe<Date>;

    other_executions: Maybe<(Maybe<OtherExecutions>)[]>;
  };

  export type Tenant = {
    __typename?: 'Tenant';

    tenant_key: Maybe<Uuid>;
  };

  export type OtherExecutions = {
    __typename?: 'Batch';

    report_date: Maybe<Date>;

    batch_key: Maybe<Uuid>;

    batch_tests: Maybe<(Maybe<BatchTests>)[]>;

    regulator: Maybe<Regulator>;
  };

  export type BatchTests = {
    __typename?: 'BatchTest';

    batch_test_key: Maybe<Uuid>;

    test: Maybe<Test>;
  };

  export type Test = {
    __typename?: 'Test';

    test_key: Maybe<Uuid>;
  };

  export type Regulator = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    earliest_impacted_trade_date: Maybe<string>;

    applicable_products: Maybe<Json>;

    fields_impacted: Maybe<Json>;

    issue: Maybe<Issue>;

    asset_class: Maybe<AssetClass>;

    asset_test_activity: Maybe<(Maybe<AssetTestActivity>)[]>;

    asset_test_key: Maybe<Uuid>;

    asset_test_results: Maybe<(Maybe<AssetTestResults>)[]>;
  };

  export type Issue = {
    __typename?: 'Issue';

    issue_key: Maybe<Uuid>;

    issue_creator: Maybe<IssueCreator>;

    risk_assessment: Maybe<RiskAssessment>;

    issue_owner: Maybe<IssueOwner>;

    remediations: Maybe<(Maybe<Remediations>)[]>;
  };

  export type IssueCreator = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type RiskAssessment = {
    __typename?: 'RiskAssessment';

    nme: Maybe<RiskAssessmentEnum>;
  };

  export type IssueOwner = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type Remediations = {
    __typename?: 'Remediation';

    remediation_key: Maybe<Uuid>;

    report_entry_type_name: Maybe<string>;

    is_all_submission: Maybe<boolean>;

    user: Maybe<User>;

    priority: Maybe<RemediationPriority>;

    target_resolution_date: Maybe<Date>;
  };

  export type User = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    prefix: Maybe<string>;

    nme: Maybe<string>;

    asset_class_key: Maybe<Uuid>;
  };

  export type AssetTestActivity = {
    __typename?: 'AssetTestActivity';

    comment: Maybe<Comment>;

    user: Maybe<_User>;

    result_status: Maybe<string>;

    created_at: Maybe<Date>;
  };

  export type Comment = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;

    text: Maybe<string>;
  };

  export type _User = {
    __typename?: 'User';

    user_key: Uuid;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;
  };

  export type AssetTestResults = {
    __typename?: 'AssetTestResult';

    nbr_na: Maybe<number>;

    nbr_failed: Maybe<number>;

    nbr_passed: Maybe<number>;

    report_type: Maybe<string>;

    report_entry_type: Maybe<string>;

    asset_test_result_key: Maybe<Uuid>;
  };

  export type _Test = {
    __typename?: 'Test';

    test_id: Maybe<string>;

    test_key: Maybe<Uuid>;

    test_kind: Maybe<string>;

    result_id: Maybe<number>;

    nme: Maybe<string>;

    long_dsc: Maybe<string>;

    repository: Maybe<string>;

    data_source: Maybe<string>;

    regulations: Maybe<(Maybe<Regulations>)[]>;
  };

  export type Regulations = {
    __typename?: 'Regulation';

    regulation_key: Maybe<Uuid>;

    regulation_id: Maybe<string>;

    regulation_header: Maybe<string>;

    regulation_header_dsc: Maybe<string>;

    regulation_url: Maybe<string>;

    short_dsc: Maybe<string>;

    long_dsc: Maybe<string>;

    short_nme: Maybe<string>;

    related_exhibits: Maybe<(Maybe<RelatedExhibits>)[]>;
  };

  export type RelatedExhibits = {
    __typename?: 'RegulationExhibit';

    exhibit_nme: Maybe<string>;

    fields: Maybe<(Maybe<Fields>)[]>;
  };

  export type Fields = {
    __typename?: 'RegulationExhibitField';

    data_field: Maybe<string>;

    comment: Maybe<string>;
  };
}

export namespace GetComments {
  export type Variables = {
    input?: Maybe<GetCommentInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getComments: Maybe<GetComments>;
  };

  export type GetComments = {
    __typename?: 'paginationComment';

    comments: Maybe<(Maybe<Comments>)[]>;

    total_count: Maybe<number>;
  };

  export type Comments = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;

    text: Maybe<string>;

    created_at: Maybe<Date>;

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;

    user: Maybe<User>;

    asset_test_activity: Maybe<(Maybe<AssetTestActivity>)[]>;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    asset_class: Maybe<AssetClass>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    user_key: Uuid;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;
  };

  export type AssetTestActivity = {
    __typename?: 'AssetTestActivity';

    result_status: Maybe<string>;

    created_at: Maybe<Date>;
  };
}

export namespace GetEarliestAssetActivityDate {
  export type Variables = {
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getBatches: Maybe<(Maybe<GetBatches>)[]>;
  };

  export type GetBatches = {
    __typename?: 'Batch';

    report_date: Maybe<Date>;
  };
}

export namespace GetFailureRateRecordsChart {
  export type Variables = {
    regulator_name: string;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    failure_rate_report: Maybe<(Maybe<FailureRateReport>)[]>;
  };

  export type FailureRateReport = {
    __typename?: 'FailureRateResults';

    test_kind_name: Maybe<string>;

    report_name: Maybe<string>;

    failure_rate: Maybe<number>;
  };
}

export namespace GetFailureRateRecordsDeatilChart {
  export type Variables = {
    regulator_name: string;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    failure_rate_detail_report: Maybe<(Maybe<FailureRateDetailReport>)[]>;
  };

  export type FailureRateDetailReport = {
    __typename?: 'FailureRateDetailResults';

    asset_class_name: Maybe<string>;

    report_name: Maybe<string>;

    test_kind_name: Maybe<string>;

    report_entry_name: Maybe<string>;

    failure_rate: Maybe<number>;
  };
}

export namespace GetFilterInfo {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getTestKinds: Maybe<(Maybe<GetTestKinds>)[]>;

    getResultStatuses: Maybe<(Maybe<GetResultStatuses>)[]>;

    getAssetClasses: Maybe<(Maybe<GetAssetClasses>)[]>;
  };

  export type GetTestKinds = {
    __typename?: 'TestKind';

    nme: Maybe<string>;
  };

  export type GetResultStatuses = {
    __typename?: 'ResultStatus';

    nme: Maybe<string>;
  };

  export type GetAssetClasses = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    prefix: Maybe<string>;
  };
}

export namespace GetHighPassesChart {
  export type Variables = {
    regulator_name: string;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    high_pass_report: Maybe<(Maybe<HighPassReport>)[]>;
  };

  export type HighPassReport = {
    __typename?: 'HighPassResults';

    asset_class_name: Maybe<string>;

    report_name: Maybe<string>;

    high_pass: Maybe<boolean>;

    count: Maybe<number>;
  };
}

export namespace GetHighPassesDetailChart {
  export type Variables = {
    regulator_name: string;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    high_pass_detail_report: Maybe<(Maybe<HighPassDetailReport>)[]>;
  };

  export type HighPassDetailReport = {
    __typename?: 'HighPassDetailResults';

    asset_class_name: Maybe<string>;

    report_name: Maybe<string>;

    test_kind_name: Maybe<string>;

    report_entry_name: Maybe<string>;

    count: Maybe<number>;

    nbr_passed: Maybe<number>;

    nbr_failed: Maybe<number>;

    nbr_evaluated: Maybe<number>;

    high_pass: Maybe<boolean>;
  };
}

export namespace GetIssues {
  export type Variables = {
    asset_test_key: (Maybe<number>)[];
  };

  export type Query = {
    __typename?: 'Query';

    getIssues: Maybe<(Maybe<GetIssues>)[]>;
  };

  export type GetIssues = {
    __typename?: 'Issue';

    issue_owner: Maybe<IssueOwner>;

    asset_test: Maybe<AssetTest>;

    issue_creator: Maybe<IssueCreator>;

    risk_assessment: Maybe<RiskAssessment>;

    remediations: Maybe<(Maybe<Remediations>)[]>;
  };

  export type IssueOwner = {
    __typename?: 'User';

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;
  };

  export type AssetTest = {
    __typename?: 'AssetTest';

    fields_impacted: Maybe<Json>;

    applicable_products: Maybe<Json>;

    asset_class: Maybe<AssetClass>;

    result_status: Maybe<ResultStatus>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    asset_class_key: Maybe<Uuid>;
  };

  export type ResultStatus = {
    __typename?: 'ResultStatus';

    nme: Maybe<string>;

    result_ind: Maybe<string>;
  };

  export type IssueCreator = {
    __typename?: 'User';

    email_alias: Maybe<string>;

    password: Maybe<string>;
  };

  export type RiskAssessment = {
    __typename?: 'RiskAssessment';

    risk_assessment_key: Maybe<Uuid>;

    nme: Maybe<RiskAssessmentEnum>;
  };

  export type Remediations = {
    __typename?: 'Remediation';

    remediation_key: Maybe<Uuid>;

    target_resolution_date: Maybe<Date>;

    report_entry_type: Maybe<ReportEntryType>;

    user: Maybe<User>;

    priority: Maybe<RemediationPriority>;
  };

  export type ReportEntryType = {
    __typename?: 'ReportEntryType';

    nme: Maybe<string>;
  };

  export type User = {
    __typename?: 'User';

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;
  };
}

export namespace GetRegulators {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getRegulators: Maybe<(Maybe<GetRegulators>)[]>;
  };

  export type GetRegulators = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };
}

export namespace GetRemediations {
  export type Variables = {
    input?: Maybe<BatchTestInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getBatchTest: Maybe<GetBatchTest>;
  };

  export type GetBatchTest = {
    __typename?: 'BatchTest';

    asset_tests: Maybe<(Maybe<AssetTests>)[]>;
  };

  export type AssetTests = {
    __typename?: 'AssetTest';

    asset_class: Maybe<AssetClass>;

    issue: Maybe<Issue>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    asset_class_key: Maybe<Uuid>;
  };

  export type Issue = {
    __typename?: 'Issue';

    issue_key: Maybe<Uuid>;

    issue_owner: Maybe<IssueOwner>;

    issue_creator: Maybe<IssueCreator>;

    asset_test: Maybe<AssetTest>;

    risk_assessment: Maybe<RiskAssessment>;

    remediations: Maybe<(Maybe<Remediations>)[]>;
  };

  export type IssueOwner = {
    __typename?: 'User';

    full_nme: Maybe<string>;

    user_key: Uuid;
  };

  export type IssueCreator = {
    __typename?: 'User';

    full_nme: Maybe<string>;

    user_key: Uuid;
  };

  export type AssetTest = {
    __typename?: 'AssetTest';

    asset_test_results: Maybe<(Maybe<AssetTestResults>)[]>;

    result_status: Maybe<ResultStatus>;

    asset_test_key: Maybe<Uuid>;
  };

  export type AssetTestResults = {
    __typename?: 'AssetTestResult';

    nbr_failed: Maybe<number>;

    report_entry_type: Maybe<string>;
  };

  export type ResultStatus = {
    __typename?: 'ResultStatus';

    nme: Maybe<string>;
  };

  export type RiskAssessment = {
    __typename?: 'RiskAssessment';

    risk_assessment_key: Maybe<Uuid>;

    nme: Maybe<RiskAssessmentEnum>;
  };

  export type Remediations = {
    __typename?: 'Remediation';

    remediation_key: Maybe<Uuid>;

    is_all_submission: Maybe<boolean>;

    user: Maybe<User>;

    report_entry_type: Maybe<ReportEntryType>;

    priority: Maybe<RemediationPriority>;

    target_resolution_date: Maybe<Date>;
  };

  export type User = {
    __typename?: 'User';

    full_nme: Maybe<string>;

    user_key: Uuid;
  };

  export type ReportEntryType = {
    __typename?: 'ReportEntryType';

    report_entry_type_key: Maybe<Uuid>;

    report_type_name: Maybe<string>;

    nme: Maybe<string>;
  };
}

export namespace GetReportEntryType {
  export type Variables = {
    report_entry_name?: Maybe<string>;
    asset_test_key?: Maybe<Uuid>;
    batch_test_key?: Maybe<Uuid>;
  };

  export type Query = {
    __typename?: 'Query';

    getReportEntryType: Maybe<(Maybe<GetReportEntryType>)[]>;
  };

  export type GetReportEntryType = {
    __typename?: 'ReportEntryType';

    nme: Maybe<string>;

    report_type_name: Maybe<string>;

    trade_detail: Maybe<(Maybe<TradeDetail>)[]>;
  };

  export type TradeDetail = {
    __typename?: 'TradeDetail';

    usi_prefix: Maybe<string>;

    usi_value: Maybe<string>;

    execution_timestamp: Maybe<Date>;

    submission_timestamp: Maybe<Date>;

    updated_on_date_time: Maybe<Date>;

    data_submitter_value: Maybe<string>;

    trade_party_1_value: Maybe<string>;

    trade_party_1_name: Maybe<string>;

    trade_party_2_value: Maybe<string>;

    trade_party_2_name: Maybe<string>;
  };
}

export namespace GetReportEntryTypeAll {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getReportEntryType: Maybe<(Maybe<GetReportEntryType>)[]>;
  };

  export type GetReportEntryType = {
    __typename?: 'ReportEntryType';

    nme: Maybe<string>;

    report_type_name: Maybe<string>;

    report_entry_type_key: Maybe<Uuid>;
  };
}

export namespace GetResultStatuses {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getResultStatuses: Maybe<(Maybe<GetResultStatuses>)[]>;
  };

  export type GetResultStatuses = {
    __typename?: 'ResultStatus';

    nme: Maybe<string>;

    result_status_key: Maybe<Uuid>;

    result_ind: Maybe<string>;
  };
}

export namespace GetRiskAssessments {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getRiskAssessments: Maybe<(Maybe<GetRiskAssessments>)[]>;
  };

  export type GetRiskAssessments = {
    __typename?: 'RiskAssessment';

    nme: Maybe<RiskAssessmentEnum>;

    risk_assessment_key: Maybe<Uuid>;
  };
}

export namespace GetTenantById {
  export type Variables = {
    tenantKey?: Maybe<Uuid>;
  };

  export type Query = {
    __typename?: 'Query';

    getTenantById: Maybe<GetTenantById>;
  };

  export type GetTenantById = {
    __typename?: 'Tenant';

    nme: Maybe<string>;

    tenant_key: Maybe<Uuid>;

    active: Maybe<string>;

    type: Maybe<string>;

    regulators: Maybe<(Maybe<Regulators>)[]>;

    features: Maybe<Json>;
  };

  export type Regulators = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };
}

export namespace GetTenants {
  export type Variables = {
    input?: Maybe<TenantInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getTenants: Maybe<(Maybe<GetTenants>)[]>;
  };

  export type GetTenants = {
    __typename?: 'Tenant';

    nme: Maybe<string>;

    tenant_key: Maybe<Uuid>;

    active: Maybe<string>;

    type: Maybe<string>;

    regulators: Maybe<(Maybe<Regulators>)[]>;

    features: Maybe<Json>;
  };

  export type Regulators = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };
}

export namespace GetTermsOfAgreements {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getTermsOfAgreements: Maybe<GetTermsOfAgreements>;
  };

  export type GetTermsOfAgreements = {
    __typename?: 'TermsOfAgreements';

    terms_key: Maybe<Uuid>;

    html: Maybe<string>;
  };
}

export namespace GetTestKinds {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getTestKinds: Maybe<(Maybe<GetTestKinds>)[]>;
  };

  export type GetTestKinds = {
    __typename?: 'TestKind';

    nme: Maybe<string>;

    test_kind_key: Maybe<Uuid>;
  };
}

export namespace GetTotalRecordsChart {
  export type Variables = {
    regulator_name?: Maybe<string>;
  };

  export type Query = {
    __typename?: 'Query';

    getReport: Maybe<GetReport>;
  };

  export type GetReport = {
    __typename?: 'Report';

    total_records_report: Maybe<(Maybe<TotalRecordsReport>)[]>;
  };

  export type TotalRecordsReport = {
    __typename?: 'TotalRecordsResults';

    report_type: Maybe<string>;

    asset_class: Maybe<string>;

    report_entry_type: Maybe<string>;

    count: Maybe<number>;
  };
}

export namespace GetTradeDetailConfiguration {
  export type Variables = {
    batch_test_key?: Maybe<Uuid>;
  };

  export type Query = {
    __typename?: 'Query';

    getTradeDetailConfiguration: Maybe<GetTradeDetailConfiguration>;
  };

  export type GetTradeDetailConfiguration = {
    __typename?: 'TradeDetailConfiguration';

    batch_test_key: Maybe<Uuid>;

    configuration: Maybe<Json>;
  };
}

export namespace GetTradeDetails {
  export type Variables = {
    asset_test_result_key?: Maybe<Uuid>;
    limit?: Maybe<number>;
  };

  export type Query = {
    __typename?: 'Query';

    getTradeDetails: Maybe<(Maybe<GetTradeDetails>)[]>;
  };

  export type GetTradeDetails = {
    __typename?: 'TradeDetail';

    usi_prefix: Maybe<string>;

    usi_value: Maybe<string>;

    uti_prefix: Maybe<string>;

    uti_value: Maybe<string>;

    execution_timestamp: Maybe<Date>;

    submission_timestamp: Maybe<Date>;

    updated_on_date_time: Maybe<Date>;

    data_submitter_value: Maybe<string>;

    trade_party_1_value: Maybe<string>;

    trade_party_1_name: Maybe<string>;

    trade_party_2_value: Maybe<string>;

    trade_party_2_name: Maybe<string>;

    product_id_value: Maybe<string>;

    tiw_submission: Maybe<string>;

    sef_prefix: Maybe<string>;

    sef_submitted: Maybe<string>;

    field_details: Maybe<(Maybe<FieldDetails>)[]>;

    executions: Maybe<(Maybe<Executions>)[]>;
  };

  export type FieldDetails = {
    __typename?: 'FieldDetail';

    key: Maybe<string>;

    value: Maybe<string>;
  };

  export type Executions = {
    __typename?: 'TradeDetailExecution';

    report_date: Maybe<Date>;

    nbr_passed: Maybe<number>;

    nbr_failed: Maybe<number>;

    nbr_na: Maybe<number>;
  };
}

export namespace GetUserAssignments {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getUserAssignments: Maybe<(Maybe<GetUserAssignments>)[]>;
  };

  export type GetUserAssignments = {
    __typename?: 'Assignment';

    issue_key: Maybe<Uuid>;

    remediation_key: Maybe<Uuid>;

    is_all_submission: Maybe<boolean>;

    asset_class: Maybe<AssetClass>;

    test: Maybe<Test>;

    role: Maybe<string>;

    priority: Maybe<RemediationPriority>;

    report_entry_type: Maybe<(Maybe<ReportEntryType>)[]>;

    target_resolution_date: Maybe<Date>;

    status: Maybe<IssueStatus>;

    batch_test_key: Maybe<Uuid>;
  };

  export type AssetClass = {
    __typename?: 'AssetClass';

    nme: Maybe<string>;

    order: Maybe<number>;
  };

  export type Test = {
    __typename?: 'Test';

    nme: Maybe<string>;

    test_id: Maybe<string>;

    result_id: Maybe<number>;
  };

  export type ReportEntryType = {
    __typename?: 'ReportEntryType';

    nme: Maybe<string>;

    order: Maybe<number>;
  };
}

export namespace GetUserById {
  export type Variables = {
    user_key?: Maybe<Uuid>;
  };

  export type Query = {
    __typename?: 'Query';

    getUserById: Maybe<GetUserById>;
  };

  export type GetUserById = {
    __typename?: 'User';

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    tenant: Maybe<Tenant>;

    email: string;

    user_role: Maybe<string>;

    user_status: Maybe<string>;

    email_alias: Maybe<string>;

    user_key: Uuid;

    type: Maybe<UserFromType>;

    job_title: Maybe<string>;

    department: Maybe<string>;
  };

  export type Tenant = {
    __typename?: 'Tenant';

    nme: Maybe<string>;
  };
}

export namespace GetUser {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getUser: Maybe<GetUser>;
  };

  export type GetUser = UserFragment.Fragment;
}

export namespace GetUserRoles {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getUserRoles: Maybe<(Maybe<GetUserRoles>)[]>;
  };

  export type GetUserRoles = {
    __typename?: 'UserRole';

    user_role_key: Maybe<Uuid>;

    nme: Maybe<string>;
  };
}

export namespace GetUsers {
  export type Variables = {
    input?: Maybe<GetUsersInput>;
  };

  export type Query = {
    __typename?: 'Query';

    getUsers: Maybe<(Maybe<GetUsers>)[]>;
  };

  export type GetUsers = {
    __typename?: 'User';

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    full_nme: Maybe<string>;

    tenant: Maybe<Tenant>;

    email: string;

    user_role: Maybe<string>;

    user_status: Maybe<string>;

    email_alias: Maybe<string>;

    user_key: Uuid;

    type: Maybe<UserFromType>;

    job_title: Maybe<string>;

    department: Maybe<string>;

    indexing: Maybe<string>;
  };

  export type Tenant = {
    __typename?: 'Tenant';

    nme: Maybe<string>;
  };
}

export namespace GetUserStatuses {
  export type Variables = {};

  export type Query = {
    __typename?: 'Query';

    getUserStatuses: Maybe<(Maybe<GetUserStatuses>)[]>;
  };

  export type GetUserStatuses = {
    __typename?: 'UserStatus';

    user_status_key: Maybe<Uuid>;

    nme: Maybe<string>;
  };
}

export namespace InviteUser {
  export type Variables = {
    input?: Maybe<InviteUserInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMinviteUser: Maybe<(Maybe<IaMinviteUser>)[]>;
  };

  export type IaMinviteUser = {
    __typename?: 'InviteUserResult';

    message: string;

    status: string;
  };
}

export namespace ListOrganizations {
  export type Variables = {
    input?: Maybe<ListOrganizationsInput>;
  };

  export type Query = {
    __typename?: 'Query';

    IAMlistOrganizations: Maybe<IaMlistOrganizations>;
  };

  export type IaMlistOrganizations = {
    __typename?: 'ListOrganizationsResult';

    status: string;

    message: string;

    organizations: (Maybe<Organizations>)[];
  };

  export type Organizations = {
    __typename?: 'OrganizationDetails';

    product: string;

    organizationId: string;

    organizationName: string;

    organizationStatus: Maybe<ListOrganizationStatusEnum>;
  };
}

export namespace OnboardOrganization {
  export type Variables = {
    input?: Maybe<OnboardOrganizationInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMonboardOrganization: Maybe<IaMonboardOrganization>;
  };

  export type IaMonboardOrganization = {
    __typename?: 'OnboardOrganizationResult';

    status: string;

    message: string;

    organization: Maybe<Organization>;
  };

  export type Organization = {
    __typename?: 'FullOrganizationDetails';

    product: string;

    organizationId: string;

    organizationName: string;

    onboardAdminEmail: Maybe<string>;

    allowedDomains: Maybe<string>;

    restrictedDomains: Maybe<string>;
  };
}

export namespace RegisterUser {
  export type Variables = {
    input?: Maybe<RegisterUserInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMregisterUser: Maybe<IaMregisterUser>;
  };

  export type IaMregisterUser = {
    __typename?: 'UserOperationResult';

    status: string;

    message: string;

    user: Maybe<User>;
  };

  export type User = {
    __typename?: 'UserInUserOperationResult';

    userId: string;

    email: string;
  };
}

export namespace UpdateAssignmentStatus {
  export type Variables = {
    issue_key?: Maybe<Uuid>;
    remediation_key?: Maybe<Uuid>;
    status: IssueStatus;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateAssignmentStatus: Maybe<boolean>;
  };
}

export namespace UpdateComment {
  export type Variables = {
    commentKey: Uuid;
    commentText: string;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    updateComment: Maybe<UpdateComment>;
  };

  export type UpdateComment = {
    __typename?: 'Comment';

    comment_key: Maybe<Uuid>;

    text: Maybe<string>;
  };
}

export namespace UploadFile {
  export type Variables = {
    files?: Maybe<Upload[]>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    uploadFile: Maybe<(Maybe<UploadFile>)[]>;
  };

  export type UploadFile = {
    __typename?: 'File';

    name: Maybe<string>;
  };
}

export namespace UpsertIssues {
  export type Variables = {
    upsertIssueInput: (Maybe<UpsertIssueInput>)[];
  };

  export type Mutation = {
    __typename?: 'Mutation';

    upsertIssues: Maybe<(Maybe<UpsertIssues>)[]>;
  };

  export type UpsertIssues = {
    __typename?: 'Issue';

    issue_key: Maybe<Uuid>;

    issue_owner: Maybe<IssueOwner>;

    issue_creator: Maybe<IssueCreator>;

    asset_test: Maybe<AssetTest>;

    risk_assessment: Maybe<RiskAssessment>;

    remediations: Maybe<(Maybe<Remediations>)[]>;
  };

  export type IssueOwner = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type IssueCreator = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type AssetTest = {
    __typename?: 'AssetTest';

    result_status: Maybe<ResultStatus>;
  };

  export type ResultStatus = {
    __typename?: 'ResultStatus';

    nme: Maybe<string>;
  };

  export type RiskAssessment = {
    __typename?: 'RiskAssessment';

    risk_assessment_key: Maybe<Uuid>;

    nme: Maybe<RiskAssessmentEnum>;
  };

  export type Remediations = {
    __typename?: 'Remediation';

    user: Maybe<User>;

    report_entry_type: Maybe<ReportEntryType>;

    priority: Maybe<RemediationPriority>;

    target_resolution_date: Maybe<Date>;
  };

  export type User = {
    __typename?: 'User';

    full_nme: Maybe<string>;
  };

  export type ReportEntryType = {
    __typename?: 'ReportEntryType';

    report_type_name: Maybe<string>;

    nme: Maybe<string>;
  };
}

export namespace UpsertTenant {
  export type Variables = {
    upsertTenantInput?: Maybe<UpsertTenantInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    upsertTenant: Maybe<UpsertTenant>;
  };

  export type UpsertTenant = {
    __typename?: 'Tenant';

    tenant_key: Maybe<Uuid>;

    nme: Maybe<string>;

    active: Maybe<string>;

    regulators: Maybe<(Maybe<Regulators>)[]>;

    features: Maybe<Json>;

    type: Maybe<string>;
  };

  export type Regulators = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };
}

export namespace UpsertTradeDetailConfiguration {
  export type Variables = {
    input?: Maybe<TradeDetailConfigurationInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    upsertTradeDetailConfiguration: Maybe<boolean>;
  };
}

export namespace EditUser {
  export type Variables = {
    userInfo?: Maybe<UserInput>;
    file?: Maybe<Upload>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    editUser: Maybe<boolean>;
  };
}

export namespace VerifyEmail {
  export type Variables = {
    input?: Maybe<VerifyEmailInput>;
  };

  export type Mutation = {
    __typename?: 'Mutation';

    IAMverifyEmail: Maybe<IaMverifyEmail>;
  };

  export type IaMverifyEmail = {
    __typename?: 'VerifyEmailResult';

    status: Maybe<string>;

    message: Maybe<string>;
  };
}

export namespace UserFragment {
  export type Fragment = {
    __typename?: 'User';

    user_key: Uuid;

    email: string;

    first_nme: Maybe<string>;

    last_nme: Maybe<string>;

    phone: Maybe<string>;

    initials: Maybe<string>;

    password: Maybe<string>;

    job_title: Maybe<string>;

    department: Maybe<string>;

    avatar_img: Maybe<string>;

    tenant: Maybe<Tenant>;

    user_role: Maybe<string>;

    user_status: Maybe<string>;

    permissions: Maybe<(Maybe<Permissions>)[]>;

    agreed_latest_terms: Maybe<boolean>;

    can_upload_report: Maybe<boolean>;

    settings: Maybe<Json>;
  };

  export type Tenant = {
    __typename?: 'Tenant';

    tenant_key: Maybe<Uuid>;

    nme: Maybe<string>;

    regulators: Maybe<(Maybe<Regulators>)[]>;

    features: Maybe<Json>;
  };

  export type Regulators = {
    __typename?: 'Regulator';

    nme: Maybe<string>;
  };

  export type Permissions = {
    __typename?: 'Permission';

    nme: Maybe<string>;
  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';

import gql from 'graphql-tag';

// ====================================================
// GraphQL Fragments
// ====================================================

export const UserFragmentFragment = gql`
  fragment userFragment on User {
    user_key
    email
    first_nme
    last_nme
    phone
    initials
    password
    job_title
    department
    avatar_img
    tenant {
      tenant_key
      nme
      regulators {
        nme
      }
      features
    }
    user_role
    user_status
    permissions {
      nme
    }
    agreed_latest_terms
    can_upload_report
    settings
  }
`;

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
  providedIn: 'root',
})
export class AcceptTermsOfAgreementsGQL extends Apollo.Mutation<
  AcceptTermsOfAgreements.Mutation,
  AcceptTermsOfAgreements.Variables
> {
  document: any = gql`
    mutation acceptTermsOfAgreements($termsKey: UUID) {
      acceptTermsOfAgreements(input: { terms_key: $termsKey }) {
        user_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class ChangeAssetTestStatusGQL extends Apollo.Mutation<
  ChangeAssetTestStatus.Mutation,
  ChangeAssetTestStatus.Variables
> {
  document: any = gql`
    mutation changeAssetTestStatus(
      $assetTestKey: UUID!
      $resultStatus: AllowedStatuses!
      $comment: String!
      $batchTestKey: UUID!
    ) {
      changeAssetTestStatus(
        asset_test_key: $assetTestKey
        result_status_nme: $resultStatus
        comment_text: $comment
        batch_test_key: $batchTestKey
      ) {
        asset_test_key
        asset_test_activity {
          asset_test_activity_key
          result_status
          created_at
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class ChangeEmailGQL extends Apollo.Mutation<
  ChangeEmail.Mutation,
  ChangeEmail.Variables
> {
  document: any = gql`
    mutation changeEmail($input: ChangeEmailInput) {
      IAMchangeEmail(input: $input) {
        message
        status
        user {
          email
          newEmail
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class ChangeOrganizationNameGQL extends Apollo.Mutation<
  ChangeOrganizationName.Mutation,
  ChangeOrganizationName.Variables
> {
  document: any = gql`
    mutation changeOrganizationName($input: ChangeOrganizationNameInput) {
      IAMchangeOrganizationName(input: $input) {
        status
        message
        organization {
          product
          organizationId
          organizationName
          organizationStatus
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class ChangeOrganizationStatusGQL extends Apollo.Mutation<
  ChangeOrganizationStatus.Mutation,
  ChangeOrganizationStatus.Variables
> {
  document: any = gql`
    mutation changeOrganizationStatus($input: ChangeOrganizationStatusInput) {
      IAMchangeOrganizationStatus(input: $input) {
        status
        message
        organization {
          product
          organizationId
          organizationName
          organizationStatus
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class CreateBatchTestCommentGQL extends Apollo.Mutation<
  CreateBatchTestComment.Mutation,
  CreateBatchTestComment.Variables
> {
  document: any = gql`
    mutation createBatchTestComment(
      $batchTestKey: UUID!
      $commentText: String!
    ) {
      createBatchTestComment(
        batch_test_key: $batchTestKey
        comment_text: $commentText
      ) {
        comment_key
        text
        created_at
        user {
          user_key
          first_nme
          last_nme
          email
        }
        asset_tests {
          asset_test_key
          asset_class {
            nme
            prefix
          }
        }
        asset_test_activity {
          asset_test_key
          result_status
          asset_test {
            asset_class {
              nme
              prefix
            }
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class CreateUserGQL extends Apollo.Mutation<
  CreateUser.Mutation,
  CreateUser.Variables
> {
  document: any = gql`
    mutation createUser($userInfo: UserInput, $file: Upload) {
      createUser(userInfo: $userInfo, fileObj: $file)
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class DeleteCommentGQL extends Apollo.Mutation<
  DeleteComment.Mutation,
  DeleteComment.Variables
> {
  document: any = gql`
    mutation deleteComment($commentKey: UUID!) {
      deleteComment(comment_key: $commentKey) {
        comment_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class DownloadTradeDetailsGQL extends Apollo.Mutation<
  DownloadTradeDetails.Mutation,
  DownloadTradeDetails.Variables
> {
  document: any = gql`
    mutation downloadTradeDetails($asset_test_result_key: UUID) {
      downloadTradeDetails(
        input: { asset_test_result_key: $asset_test_result_key }
      )
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class FetchOrganizationDetailsGQL extends Apollo.Query<
  FetchOrganizationDetails.Query,
  FetchOrganizationDetails.Variables
> {
  document: any = gql`
    query fetchOrganizationDetails($input: FetchOrganizationDetailsInput) {
      IAMfetchOrganizationDetails(input: $input) {
        status
        message
        organization {
          product
          organizationId
          organizationName
          organizationStatus
          onboardAdminEmail
          allowedDomains
          restrictedDomains
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class FetchUserGQL extends Apollo.Query<
  FetchUser.Query,
  FetchUser.Variables
> {
  document: any = gql`
    query fetchUser($input: FetchUserInput) {
      IAMfetchUser(input: $input) {
        status
        message
        user {
          userId
          email
          firstName
          lastName
          mobileNumber
          organizationId
          product
          role
          status
          isProfileCompleted
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class IaMfetchUserDetailsGQL extends Apollo.Query<
  IaMfetchUserDetails.Query,
  IaMfetchUserDetails.Variables
> {
  document: any = gql`
    query IAMfetchUserDetails($input: AccessTokenInput) {
      IAMfetchUserDetails(input: $input) {
        given_name
        family_name
        email
        group_list
        organization_id_list
        name
        userId
        product_list
        sub
        message
        status
        user_disabled_by_admin
        user_org_disabled
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetActivityTrendsDetailChartGQL extends Apollo.Query<
  GetActivityTrendsDetailChart.Query,
  GetActivityTrendsDetailChart.Variables
> {
  document: any = gql`
    query getActivityTrendsDetailChart(
      $start_date: String
      $end_date: String
      $regulator_name: String
    ) {
      getReport {
        activity_trends_detail_report(
          input: {
            start_date: $start_date
            end_date: $end_date
            regulator_name: $regulator_name
          }
        ) {
          result_status
          week
          count
          asset_class
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetActivityTrendsChartGQL extends Apollo.Query<
  GetActivityTrendsChart.Query,
  GetActivityTrendsChart.Variables
> {
  document: any = gql`
    query getActivityTrendsChart(
      $start_date: String
      $end_date: String
      $regulator_name: String
    ) {
      getReport {
        activity_trends_report(
          input: {
            start_date: $start_date
            end_date: $end_date
            regulator_name: $regulator_name
          }
        ) {
          result_status
          week
          count
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetAllIssueAssignmentsGQL extends Apollo.Query<
  GetAllIssueAssignments.Query,
  GetAllIssueAssignments.Variables
> {
  document: any = gql`
    query getAllIssueAssignments {
      getAllIssueAssignments {
        issue_key
        remediation_key
        asset_class {
          nme
          asset_class_key
          order
        }
        test {
          nme
          test_id
          result_id
        }
        issue_owner {
          first_nme
          last_nme
          full_nme
        }
        priority
        status
        batch_test_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetAssetClassesGQL extends Apollo.Query<
  GetAssetClasses.Query,
  GetAssetClasses.Variables
> {
  document: any = gql`
    query getAssetClasses {
      getAssetClasses {
        asset_class_key
        nme
        order
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetAuditLogsGQL extends Apollo.Query<
  GetAuditLogs.Query,
  GetAuditLogs.Variables
> {
  document: any = gql`
    query getAuditLogs($input: GetAuditLogsInput) {
      getAuditLogs(input: $input) {
        audit_log_key
        user {
          user_key
          first_nme
          last_nme
          email
        }
        event {
          nme
        }
        asset_tests {
          asset_class {
            prefix
            nme
          }
        }
        created_at
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetBatchesGQL extends Apollo.Query<
  GetBatches.Query,
  GetBatches.Variables
> {
  document: any = gql`
    query getBatches($test_key: UUID, $regulator_name: String = "none") {
      getBatches(
        input: {
          limit: 1
          order: "created_at"
          orderDirection: "DESC"
          regulator_name: $regulator_name
        }
      ) {
        report_date
        batch_key
        batch_tests(where: { test_key: $test_key }) {
          batch_test_key
        }
        regulator {
          nme
        }
        comments(where: { test_key: $test_key }) {
          comment_key
          text
          created_at
          user {
            user_key
            first_nme
            last_nme
            email
          }
          asset_tests {
            asset_test_key
            asset_class {
              nme
              prefix
            }
          }
          asset_test_activity {
            asset_test_key
            result_status
            asset_test {
              asset_class {
                nme
                prefix
              }
            }
          }
        }
        other_executions {
          report_date
          batch_key
          batch_tests(where: { test_key: $test_key }) {
            batch_test_key
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetBatchSummaryGQL extends Apollo.Query<
  GetBatchSummary.Query,
  GetBatchSummary.Variables
> {
  document: any = gql`
    query getBatchSummary($regulator_name: String) {
      getBatches(
        input: {
          limit: 1
          order: "created_at"
          orderDirection: "DESC"
          regulator_name: $regulator_name
        }
      ) {
        batch_key
        report_date
        asset_classes {
          asset_class_key
          nme
          prefix
        }
        tenant {
          nme
          tenant_key
        }
        regulator {
          nme
        }
        batch_tests(where: { result_status_key: [1, 2, 3, 4, 5] }) {
          batch_test_key
          nbr_failed
          nbr_evaluated
          result_status
          test {
            test_key
            nme
            test_id
            result_id
            asset_classes {
              prefix
              nme
            }
            regulations {
              regulator
              regulation_header
              regulation_header_dsc
              regulation_url
              exhibit_nme
              short_nme
              related_exhibits {
                exhibit_nme
                fields {
                  data_field
                  comment
                }
              }
              related_descriptions {
                short_dsc
                long_dsc
              }
            }
            test_kind
            priority
          }
          failures_impacting {
            nbr_failed
            report_entry_type_nme
            order
            report_type_nme
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetBatchTestGQL extends Apollo.Query<
  GetBatchTest.Query,
  GetBatchTest.Variables
> {
  document: any = gql`
    query getBatchTest($input: BatchTestInput) {
      getBatchTest(input: $input) {
        batch_test_key
        batch {
          batch_key
          tenant {
            tenant_key
          }
          report_date
          other_executions {
            report_date
            batch_key
            batch_tests {
              batch_test_key
              test {
                test_key
              }
            }
            regulator {
              nme
            }
          }
        }
        asset_tests {
          earliest_impacted_trade_date
          applicable_products
          fields_impacted
          issue {
            issue_key
            issue_creator {
              full_nme
            }
            risk_assessment {
              nme
            }
            issue_owner {
              full_nme
            }
            remediations {
              remediation_key
              report_entry_type_name
              is_all_submission
              user {
                full_nme
              }
              priority
              target_resolution_date
            }
          }
          asset_class {
            prefix
            nme
            asset_class_key
          }
          asset_test_activity {
            comment {
              comment_key
              text
            }
            user {
              user_key
              first_nme
              last_nme
            }
            result_status
            created_at
          }
          asset_test_key
          asset_test_results {
            nbr_na
            nbr_failed
            nbr_passed
            report_type
            report_entry_type
            asset_test_result_key
          }
        }
        test {
          test_id
          test_key
          test_kind
          result_id
          nme
          long_dsc
          repository
          data_source
          regulations {
            regulation_key
            regulation_id
            regulation_header
            regulation_header_dsc
            regulation_url
            short_dsc
            long_dsc
            short_nme
            related_exhibits {
              exhibit_nme
              fields {
                data_field
                comment
              }
            }
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetCommentsGQL extends Apollo.Query<
  GetComments.Query,
  GetComments.Variables
> {
  document: any = gql`
    query getComments($input: GetCommentInput) {
      getComments(input: $input) {
        comments {
          comment_key
          text
          created_at
          asset_tests {
            asset_class {
              nme
            }
          }
          user {
            user_key
            first_nme
            last_nme
          }
          asset_test_activity {
            result_status
            created_at
          }
        }
        total_count
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetEarliestAssetActivityDateGQL extends Apollo.Query<
  GetEarliestAssetActivityDate.Query,
  GetEarliestAssetActivityDate.Variables
> {
  document: any = gql`
    query getEarliestAssetActivityDate($regulator_name: String) {
      getBatches(
        input: {
          limit: 1
          order: "created_at"
          orderDirection: "DESC"
          regulator_name: $regulator_name
        }
      ) {
        report_date
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetFailureRateRecordsChartGQL extends Apollo.Query<
  GetFailureRateRecordsChart.Query,
  GetFailureRateRecordsChart.Variables
> {
  document: any = gql`
    query getFailureRateRecordsChart($regulator_name: String!) {
      getReport {
        failure_rate_report(input: { regulator_name: $regulator_name }) {
          test_kind_name
          report_name
          failure_rate
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetFailureRateRecordsDeatilChartGQL extends Apollo.Query<
  GetFailureRateRecordsDeatilChart.Query,
  GetFailureRateRecordsDeatilChart.Variables
> {
  document: any = gql`
    query getFailureRateRecordsDeatilChart($regulator_name: String!) {
      getReport {
        failure_rate_detail_report(input: { regulator_name: $regulator_name }) {
          asset_class_name
          report_name
          test_kind_name
          report_entry_name
          failure_rate
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetFilterInfoGQL extends Apollo.Query<
  GetFilterInfo.Query,
  GetFilterInfo.Variables
> {
  document: any = gql`
    query getFilterInfo {
      getTestKinds {
        nme
      }
      getResultStatuses {
        nme
      }
      getAssetClasses {
        nme
        prefix
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetHighPassesChartGQL extends Apollo.Query<
  GetHighPassesChart.Query,
  GetHighPassesChart.Variables
> {
  document: any = gql`
    query getHighPassesChart($regulator_name: String!) {
      getReport {
        high_pass_report(input: { regulator_name: $regulator_name }) {
          asset_class_name
          report_name
          high_pass
          count
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetHighPassesDetailChartGQL extends Apollo.Query<
  GetHighPassesDetailChart.Query,
  GetHighPassesDetailChart.Variables
> {
  document: any = gql`
    query getHighPassesDetailChart($regulator_name: String!) {
      getReport {
        high_pass_detail_report(input: { regulator_name: $regulator_name }) {
          asset_class_name
          report_name
          test_kind_name
          report_entry_name
          count
          nbr_passed
          nbr_failed
          nbr_evaluated
          high_pass
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetIssuesGQL extends Apollo.Query<
  GetIssues.Query,
  GetIssues.Variables
> {
  document: any = gql`
    query getIssues($asset_test_key: [Int]!) {
      getIssues(input: { asset_test_key: $asset_test_key }) {
        issue_owner {
          first_nme
          last_nme
        }
        asset_test {
          fields_impacted
          applicable_products
          asset_class {
            nme
            asset_class_key
          }
          result_status {
            nme
            result_ind
          }
        }
        issue_creator {
          email_alias
          password
        }
        risk_assessment {
          risk_assessment_key
          nme
        }
        remediations {
          remediation_key
          target_resolution_date
          report_entry_type {
            nme
          }
          user {
            first_nme
            last_nme
          }
          priority
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetRegulatorsGQL extends Apollo.Query<
  GetRegulators.Query,
  GetRegulators.Variables
> {
  document: any = gql`
    query getRegulators {
      getRegulators {
        nme
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetRemediationsGQL extends Apollo.Query<
  GetRemediations.Query,
  GetRemediations.Variables
> {
  document: any = gql`
    query getRemediations($input: BatchTestInput) {
      getBatchTest(input: $input) {
        asset_tests {
          asset_class {
            nme
            asset_class_key
          }
          issue {
            issue_key
            issue_owner {
              full_nme
              user_key
            }
            issue_creator {
              full_nme
              user_key
            }
            asset_test {
              asset_test_results {
                nbr_failed
                report_entry_type
              }
              result_status {
                nme
              }
              asset_test_key
            }
            risk_assessment {
              risk_assessment_key
              nme
            }
            remediations {
              remediation_key
              is_all_submission
              user {
                full_nme
                user_key
              }
              report_entry_type {
                report_entry_type_key
                report_type_name
                nme
              }
              priority
              target_resolution_date
            }
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetReportEntryTypeGQL extends Apollo.Query<
  GetReportEntryType.Query,
  GetReportEntryType.Variables
> {
  document: any = gql`
    query getReportEntryType(
      $report_entry_name: String
      $asset_test_key: UUID
      $batch_test_key: UUID
    ) {
      getReportEntryType(input: { report_entry_name: $report_entry_name }) {
        nme
        report_type_name
        trade_detail(
          input: {
            asset_test_key: $asset_test_key
            batch_test_key: $batch_test_key
          }
        ) {
          usi_prefix
          usi_value
          execution_timestamp
          submission_timestamp
          updated_on_date_time
          data_submitter_value
          trade_party_1_value
          trade_party_1_name
          trade_party_2_value
          trade_party_2_name
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetReportEntryTypeAllGQL extends Apollo.Query<
  GetReportEntryTypeAll.Query,
  GetReportEntryTypeAll.Variables
> {
  document: any = gql`
    query getReportEntryTypeAll {
      getReportEntryType {
        nme
        report_type_name
        report_entry_type_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetResultStatusesGQL extends Apollo.Query<
  GetResultStatuses.Query,
  GetResultStatuses.Variables
> {
  document: any = gql`
    query getResultStatuses {
      getResultStatuses {
        nme
        result_status_key
        result_ind
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetRiskAssessmentsGQL extends Apollo.Query<
  GetRiskAssessments.Query,
  GetRiskAssessments.Variables
> {
  document: any = gql`
    query getRiskAssessments {
      getRiskAssessments {
        nme
        risk_assessment_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTenantByIdGQL extends Apollo.Query<
  GetTenantById.Query,
  GetTenantById.Variables
> {
  document: any = gql`
    query getTenantById($tenantKey: UUID) {
      getTenantById(input: { tenant_key: $tenantKey }) {
        nme
        tenant_key
        active
        type
        regulators {
          nme
        }
        features
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTenantsGQL extends Apollo.Query<
  GetTenants.Query,
  GetTenants.Variables
> {
  document: any = gql`
    query getTenants($input: TenantInput) {
      getTenants(input: $input) {
        nme
        tenant_key
        active
        type
        regulators {
          nme
        }
        features
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTermsOfAgreementsGQL extends Apollo.Query<
  GetTermsOfAgreements.Query,
  GetTermsOfAgreements.Variables
> {
  document: any = gql`
    query getTermsOfAgreements {
      getTermsOfAgreements {
        terms_key
        html
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTestKindsGQL extends Apollo.Query<
  GetTestKinds.Query,
  GetTestKinds.Variables
> {
  document: any = gql`
    query getTestKinds {
      getTestKinds {
        nme
        test_kind_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTotalRecordsChartGQL extends Apollo.Query<
  GetTotalRecordsChart.Query,
  GetTotalRecordsChart.Variables
> {
  document: any = gql`
    query getTotalRecordsChart($regulator_name: String) {
      getReport {
        total_records_report(input: { regulator_name: $regulator_name }) {
          report_type
          asset_class
          report_entry_type
          count
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTradeDetailConfigurationGQL extends Apollo.Query<
  GetTradeDetailConfiguration.Query,
  GetTradeDetailConfiguration.Variables
> {
  document: any = gql`
    query getTradeDetailConfiguration($batch_test_key: UUID) {
      getTradeDetailConfiguration(batch_test_key: $batch_test_key) {
        batch_test_key
        configuration
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetTradeDetailsGQL extends Apollo.Query<
  GetTradeDetails.Query,
  GetTradeDetails.Variables
> {
  document: any = gql`
    query getTradeDetails($asset_test_result_key: UUID, $limit: Int) {
      getTradeDetails(
        input: { asset_test_result_key: $asset_test_result_key, limit: $limit }
      ) {
        usi_prefix
        usi_value
        uti_prefix
        uti_value
        execution_timestamp
        submission_timestamp
        updated_on_date_time
        data_submitter_value
        trade_party_1_value
        trade_party_1_name
        trade_party_2_value
        trade_party_2_name
        product_id_value
        tiw_submission
        sef_prefix
        sef_submitted
        field_details {
          key
          value
        }
        executions {
          report_date
          nbr_passed
          nbr_failed
          nbr_na
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUserAssignmentsGQL extends Apollo.Query<
  GetUserAssignments.Query,
  GetUserAssignments.Variables
> {
  document: any = gql`
    query getUserAssignments {
      getUserAssignments {
        issue_key
        remediation_key
        is_all_submission
        asset_class {
          nme
          order
        }
        test {
          nme
          test_id
          result_id
        }
        role
        priority
        report_entry_type {
          nme
          order
        }
        target_resolution_date
        status
        batch_test_key
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUserByIdGQL extends Apollo.Query<
  GetUserById.Query,
  GetUserById.Variables
> {
  document: any = gql`
    query getUserById($user_key: UUID) {
      getUserById(input: { user_key: $user_key }) {
        first_nme
        last_nme
        tenant {
          nme
        }
        email
        user_role
        user_status
        email_alias
        user_key
        type
        job_title
        department
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUserGQL extends Apollo.Query<GetUser.Query, GetUser.Variables> {
  document: any = gql`
    query getUser {
      getUser {
        ...userFragment
      }
    }

    ${UserFragmentFragment}
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUserRolesGQL extends Apollo.Query<
  GetUserRoles.Query,
  GetUserRoles.Variables
> {
  document: any = gql`
    query getUserRoles {
      getUserRoles {
        user_role_key
        nme
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUsersGQL extends Apollo.Query<
  GetUsers.Query,
  GetUsers.Variables
> {
  document: any = gql`
    query getUsers($input: GetUsersInput) {
      getUsers(input: $input) {
        first_nme
        last_nme
        full_nme
        tenant {
          nme
        }
        email
        user_role
        user_status
        email_alias
        user_key
        type
        job_title
        department
        indexing
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class GetUserStatusesGQL extends Apollo.Query<
  GetUserStatuses.Query,
  GetUserStatuses.Variables
> {
  document: any = gql`
    query getUserStatuses {
      getUserStatuses {
        user_status_key
        nme
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class InviteUserGQL extends Apollo.Mutation<
  InviteUser.Mutation,
  InviteUser.Variables
> {
  document: any = gql`
    mutation inviteUser($input: InviteUserInput) {
      IAMinviteUser(input: $input) {
        message
        status
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class ListOrganizationsGQL extends Apollo.Query<
  ListOrganizations.Query,
  ListOrganizations.Variables
> {
  document: any = gql`
    query listOrganizations($input: ListOrganizationsInput) {
      IAMlistOrganizations(input: $input) {
        status
        message
        organizations {
          product
          organizationId
          organizationName
          organizationStatus
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class OnboardOrganizationGQL extends Apollo.Mutation<
  OnboardOrganization.Mutation,
  OnboardOrganization.Variables
> {
  document: any = gql`
    mutation onboardOrganization($input: OnboardOrganizationInput) {
      IAMonboardOrganization(input: $input) {
        status
        message
        organization {
          product
          organizationId
          organizationName
          onboardAdminEmail
          allowedDomains
          restrictedDomains
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class RegisterUserGQL extends Apollo.Mutation<
  RegisterUser.Mutation,
  RegisterUser.Variables
> {
  document: any = gql`
    mutation RegisterUser($input: RegisterUserInput) {
      IAMregisterUser(input: $input) {
        status
        message
        user {
          userId
          email
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UpdateAssignmentStatusGQL extends Apollo.Mutation<
  UpdateAssignmentStatus.Mutation,
  UpdateAssignmentStatus.Variables
> {
  document: any = gql`
    mutation updateAssignmentStatus(
      $issue_key: UUID
      $remediation_key: UUID
      $status: IssueStatus!
    ) {
      updateAssignmentStatus(
        input: {
          issue_key: $issue_key
          remediation_key: $remediation_key
          status: $status
        }
      )
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UpdateCommentGQL extends Apollo.Mutation<
  UpdateComment.Mutation,
  UpdateComment.Variables
> {
  document: any = gql`
    mutation updateComment($commentKey: UUID!, $commentText: String!) {
      updateComment(comment_key: $commentKey, comment_text: $commentText) {
        comment_key
        text
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UploadFileGQL extends Apollo.Mutation<
  UploadFile.Mutation,
  UploadFile.Variables
> {
  document: any = gql`
    mutation uploadFile($files: [Upload!]) {
      uploadFile(files: $files) {
        name
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UpsertIssuesGQL extends Apollo.Mutation<
  UpsertIssues.Mutation,
  UpsertIssues.Variables
> {
  document: any = gql`
    mutation upsertIssues($upsertIssueInput: [UpsertIssueInput]!) {
      upsertIssues(input: $upsertIssueInput) {
        issue_key
        issue_owner {
          full_nme
        }
        issue_creator {
          full_nme
        }
        asset_test {
          result_status {
            nme
          }
        }
        risk_assessment {
          risk_assessment_key
          nme
        }
        remediations {
          user {
            full_nme
          }
          report_entry_type {
            report_type_name
            nme
          }
          priority
          target_resolution_date
        }
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UpsertTenantGQL extends Apollo.Mutation<
  UpsertTenant.Mutation,
  UpsertTenant.Variables
> {
  document: any = gql`
    mutation upsertTenant($upsertTenantInput: UpsertTenantInput) {
      upsertTenant(input: $upsertTenantInput) {
        tenant_key
        nme
        active
        regulators {
          nme
        }
        features
        type
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class UpsertTradeDetailConfigurationGQL extends Apollo.Mutation<
  UpsertTradeDetailConfiguration.Mutation,
  UpsertTradeDetailConfiguration.Variables
> {
  document: any = gql`
    mutation upsertTradeDetailConfiguration(
      $input: TradeDetailConfigurationInput
    ) {
      upsertTradeDetailConfiguration(input: $input)
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class EditUserGQL extends Apollo.Mutation<
  EditUser.Mutation,
  EditUser.Variables
> {
  document: any = gql`
    mutation editUser($userInfo: UserInput, $file: Upload) {
      editUser(userInfo: $userInfo, fileObj: $file)
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class VerifyEmailGQL extends Apollo.Mutation<
  VerifyEmail.Mutation,
  VerifyEmail.Variables
> {
  document: any = gql`
    mutation verifyEmail($input: VerifyEmailInput) {
      IAMverifyEmail(input: $input) {
        status
        message
      }
    }
  `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================
