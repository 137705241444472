import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { map, catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.userService.getUser().pipe(
      take(1),
      map(val => {
        if (!val) {
          return false;
        } else {
          return true;
        }
      }),
      catchError(_ => {
        sessionStorage.setItem('redirectUrl', state.url);
        this.router.navigate(['welcome']);
        return of(false);
      }),
    );
  }
}
