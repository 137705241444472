import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, iif, throwError, of } from 'rxjs';
import { map, take, tap, flatMap, catchError } from 'rxjs/operators';
import {
  CreateUserGQL,
  EditUserGQL,
  GetUserGQL,
  GetUsersGQL,
  GetUsersInput,
  GetUserRolesGQL,
  GetUserByIdGQL,
  GetUserByIdInput,
  InviteUserGQL,
  GetUserStatusesGQL,
  UserInput,
  UserFragment,
  GetUserStatuses,
  GetUserRoles,
  GetUsers,
  RegisterUserInput,
  RegisterUser,
  RegisterUserGQL,
  UserRoleEnum,
  FetchUserInput,
  FetchUser,
  FetchUserGQL,
  IaMfetchUserDetails,
  IaMfetchUserDetailsGQL,
  AccessTokenInput,
  VerifyEmail,
  VerifyEmailInput,
  VerifyEmailGQL,
  ChangeEmail,
  ChangeEmailInput,
  ChangeEmailGQL,
} from '@generatedTypes/graphql';
import { FeatureFlagService } from '@services/feature-flag.service';
import envVars from 'app/config/envVars';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public signedIn = new BehaviorSubject(false);
  public agreedLatestTerms$ = new BehaviorSubject(false);
  hasViewAdminPermission = new BehaviorSubject(false);
  public user: BehaviorSubject<UserFragment.Fragment> = new BehaviorSubject(
    null,
  );
  // asObservable prevents other components/services from modifying User
  public user$: Observable<UserFragment.Fragment> = this.user.asObservable();

  constructor(
    private getUserGQL: GetUserGQL,
    private editUserGQL: EditUserGQL,
    private getUsersGQL: GetUsersGQL,
    private getUserRolesGQL: GetUserRolesGQL,
    private featureFlagService: FeatureFlagService,
    private createUserGQL: CreateUserGQL,
    private getUserByIdGQL: GetUserByIdGQL,
    private inviteUserGQL: InviteUserGQL,
    private getUserStatusesGQL: GetUserStatusesGQL,
    private fetchUserGQL: FetchUserGQL,
    private registerUserGQL: RegisterUserGQL,
    private iamFetchUserDetailsGQL: IaMfetchUserDetailsGQL,
    private verifyEmailGQL: VerifyEmailGQL,
    private changeEmailGQL: ChangeEmailGQL,
  ) {}

  login(): Observable<UserFragment.Fragment> {
    return this.getUserGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getUser;
          // return plainToClass(UserFragment.Fragment, data.getUser);
        }),
        tap(user => {
          this.user.next(user);
          this.featureFlagService.initialize(user.tenant.features);
          this.signedIn.next(true);
        }),
      );
  }

  clearUser(): void {
    this.user.next(null);
  }

  getUser(): Observable<UserFragment.Fragment> {
    return this.getUserGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          // const user = plainToClass(UserFragment.Fragment, data.getUser);
          const user = data.getUser;
          // TOGGLE: Force user to have certain attributes
          // user.tenant.regulators = [{ nme: 'CFTC' }, { nme: 'EMIR 2' }];
          this.featureFlagService.initialize(user.tenant.features);
          this.user.next(user);
          return user;
        }),
      );
  }

  getUserStatuses(): Observable<GetUserStatuses.GetUserStatuses[]> {
    return this.getUserStatusesGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getUserStatuses;
        }),
      );
  }

  hasPermission(permission: string) {
    return this.getUser().pipe(
      take(1),
      map(user => {
        return user.permissions.some(
          permissions => permissions.nme === permission,
        )
          ? true
          : false;
      }),
    );
  }

  getUserById(input: GetUserByIdInput) {
    return this.getUserByIdGQL
      .watch(
        {
          user_key: input.user_key,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getUserById;
        }),
      );
  }
  editUser(userInfo: UserInput, file?: File): Observable<boolean> {
    return this.editUserGQL
      .mutate(
        {
          userInfo,
          file,
        },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getUserGQL.document,
            },
            {
              query: this.getUsersGQL.document,
            },
          ],
        },
      )
      .pipe(
        map(({ data }) => {
          return data.editUser;
        }),
      );
  }

  registerUser(registerUserInput: RegisterUserInput) {
    return this.registerUserGQL.mutate({ input: registerUserInput });
  }

  createUser(userInfo: UserInput, file?: File): Observable<any> {
    return this.createUserGQL
      .mutate(
        { userInfo, file },
        {
          refetchQueries: [{ query: this.getUsersGQL.document }],
        },
      )
      .pipe(
        flatMap(({ data }) => iif(() => data.createUser, of(true), of(false))),
        flatMap(() => this.getUser()),
        map(currentUser => ({
          requestingUserEmail: currentUser.email,
          product: envVars().IAM_PRODUCT,
          users: [
            {
              role: UserRoleEnum.Member,
              email: userInfo.email,
            },
          ],
          organizationId:
            JSON.parse(localStorage.getItem('profile')).organization_id || '',
        })),
        flatMap((input: any) => this.inviteUserGQL.mutate({ input })),
        map(res => res.data.IAMinviteUser[0]),
      );
  }

  getAllUsers(getUsersInput?: GetUsersInput): Observable<GetUsers.GetUsers[]> {
    return this.getUsersGQL
      .watch({ input: getUsersInput }, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getUsers;
        }),
      );
  }

  getAllRoles(): Observable<GetUserRoles.GetUserRoles[]> {
    return this.getUserRolesGQL
      .watch({ fetchPolicy: 'network-only' })
      .valueChanges.pipe(map(({ data }) => data.getUserRoles));
  }

  fetchUser(input: FetchUserInput): Observable<FetchUser.IaMfetchUser> {
    return this.fetchUserGQL
      .watch({
        input,
      })
      .valueChanges.pipe(
        take(1),
        map(({ data }) => data.IAMfetchUser),
        catchError(err => of(err)),
      );
  }

  iamFetchUserDetails(
    input: AccessTokenInput,
  ): Observable<IaMfetchUserDetails.IaMfetchUserDetails> {
    return this.iamFetchUserDetailsGQL
      .watch({
        input,
      })
      .valueChanges.pipe(
        take(1),
        map(({ data }) => data.IAMfetchUserDetails),
        catchError(err => of(err)),
      );
  }

  verifyEmail(input: VerifyEmailInput): Observable<VerifyEmail.IaMverifyEmail> {
    return this.verifyEmailGQL
      .mutate({
        input,
      })
      .pipe(
        take(1),
        map(({ data }) => {
          return data.IAMverifyEmail;
        }),
      );
  }

  changeEmail(input: ChangeEmailInput): Observable<ChangeEmail.IaMchangeEmail> {
    return this.changeEmailGQL
      .mutate({
        input,
      })
      .pipe(
        map(({ data }) => {
          return data.IAMchangeEmail;
        }),
      );
  }
}
