import _ from 'lodash';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MultiSelectGroup } from 'app/shared/modules/luna-multi-select/luna-multi-select';
import { Filter } from 'app/shared/modules/table-sort/type';
import { Item as SelectItemForPill } from 'app/shared/modules/luna-pill-group/luna-pill-group';

export interface SelectGroup {
  display: string;
  selector?: any;
  items: SelectGroupItem[];
}
export interface SelectGroupItem {
  display: string;
  value?: any;
  count?: number;
  __id?: string;
  __groupDisplayName?: string;
}

@Component({
  selector: 'filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
})
export class FilterSelectorComponent {
  private _data: SelectGroup[] = [];
  private _dataForMultiSelect: MultiSelectGroup[] = [];
  private _indexes = new Map<string, SelectGroupItem>();

  private _selectedForPills: SelectItemForPill[] = [];
  private _defaultSelected: Filter[] = [];
  private _selected: string[] = [];
  private _multiSelectSeleted: string[] = [];

  constructor() {
    this.selectDefault = _.debounce(this.selectDefault, 100);
  }

  @Input() set filterSelectorData(data: SelectGroup[]) {
    this._data = data.map(group => {
      group.items.forEach(item => {
        item.__groupDisplayName = group.display;
        item.__id = `${group.display}-_-${item.display}`;
      });
      return group;
    });

    this._dataForMultiSelect = data.map(group => ({
      display: group.display,
      items: group.items.map(item => ({
        display: item.display,
        value: item.__id,
        count: item.count,
      })),
    }));
    this._indexes = new Map(
      data.flatMap(group => group.items).map(item => [item.__id, item]),
    );

    this.selectDefault();
  }
  @Input() set defaultSelected(value: Filter[]) {
    this._defaultSelected = value;
    this.selectDefault();
  }
  @Input() showFilter = false;
  @Output() selectChange = new EventEmitter<Filter[]>();

  private set selected(ids: string[]) {
    this._selected = ids;
    this._selectedForPills = ids.map(id => {
      const item = this._indexes.get(id);
      return {
        display: `${item.__groupDisplayName}: ${item.display}`,
        value: item.__id,
      };
    });

    const filters = _(ids)
      .map(id => this._indexes.get(id))
      .groupBy(item => item.__groupDisplayName)
      .map((items, groupName) => {
        const group = this._data.find(g => g.display === groupName);
        return {
          selector: group.selector || groupName,
          values: items.map(i => i.value || i.display),
          ids: items.map(i => i.__id),
        };
      })
      .value();
    this.selectChange.emit(filters);
  }

  private selectDefault() {
    if (
      !_.size(this._data) ||
      !_.size(this._defaultSelected) ||
      _.size(this._selected)
    ) {
      return;
    }
    this.selected = _.flatMap(
      this._defaultSelected,
      (filter: any) => filter.ids,
    );
    this._defaultSelected = [];
  }

  remove(id: string) {
    this.selected = _.pull(this._selected, id);
  }
  reset() {
    this.selected = [];
  }
  multiSelectChange(value: string[]) {
    this._multiSelectSeleted = value;
  }
  submit() {
    this.selected = this._multiSelectSeleted;
  }
}
