import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { fill } from 'lodash';
import { SessionService, UtilService } from '@services/index';
import { ModalTradeDetailComponent } from '../modal/modal-trade-detail/modal-trade-detail.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-fixed-table',
  templateUrl: './fixed-table.component.html',
  styleUrls: ['./fixed-table.component.scss'],
})
export class FixedTableComponent implements OnInit, OnChanges {
  @ViewChild('sticky', { static: true }) sticky: ElementRef;
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() data: Array<any>;
  @Input() fixedColumns: number;
  @Input() isHistory: boolean;
  @Input() defaultColumns?: Array<{ display: string; selected: boolean }> = [];
  @Input() additionColumns?: Array<{ key: string; selected: boolean }> = [];
  maxfixedColumns = 6;
  minfixedColumns = 2;
  scrollerMarginLeft: number;
  scrollLeft: number;
  scrollFixed: boolean;
  scrollHeaderMarginLeft: string;
  isEMIR = false;
  fieldDetails: Array<{ key: string; value: Array<string> }> = [];
  fieldHistoryDetails = [];
  autoCompleteColumnArray = [];

  constructor(
    private sessionService: SessionService,
    private utilService: UtilService,
    private dialog: MatDialog,
  ) {}
  ngOnInit() {
    if (this.sessionService.selectedRegulator$.value.toUpperCase() === 'EMIR') {
      this.isEMIR = true;
    }
    this.calculatePositionForFixed();

    [
      this.fieldDetails,
      this.fieldHistoryDetails,
    ] = this.utilService.packageFieldDetailsData(this.data);

    localStorage.setItem('fieldDetails', JSON.stringify(this.fieldDetails));
    this.autoCompleteColumnArray = fill(
      Array(
        Math.ceil(
          this.container.nativeElement.clientWidth /
            this.sticky.nativeElement.clientWidth,
        ) -
          this.fieldHistoryDetails.length -
          1,
      ),
      {
        value: fill(Array(this.data.length), ''),
      },
    );
  }

  calculatePositionForFixed() {
    if (this.fixedColumns < this.minfixedColumns) {
      this.fixedColumns = this.minfixedColumns;
    } else {
      if (this.fixedColumns > this.maxfixedColumns) {
        this.fixedColumns = this.maxfixedColumns;
      }
    }
    if (this.sticky) {
      const cellWidth = this.sticky.nativeElement.offsetWidth;
      this.scrollerMarginLeft = cellWidth * this.fixedColumns;
      this.scrollLeft = (cellWidth * this.fixedColumns) / this.fixedColumns;
      this.scrollHeaderMarginLeft = -cellWidth * this.fixedColumns + 'px';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isHistory) {
      this.isHistory = changes.isHistory.currentValue;
    }
  }

  openTradeDetail(event, tradeDetail) {
    this.dialog.open(ModalTradeDetailComponent, {
      maxWidth: '600px',
      maxHeight: '70%',
      minWidth: '500px',
      minHeight: '300px',
      data: [this.fieldDetails, tradeDetail],
    });
    event.stopPropagation();
    event.preventDefault();
  }
}
