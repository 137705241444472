import { Component, OnInit, OnDestroy } from '@angular/core';
import { TermsService, UserService } from '@services/index';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit, OnDestroy {
  hasAgreed = false;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private termsService: TermsService,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.hasAgreed = false;
  }
  continueLogin() {
    this.termsService
      .getTermsOfAgreements()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          if (this.hasAgreed) {
            this.termsService
              .acceptTermsOfAgreements(data.terms_key)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(data => {
                if (data && data.user_key) {
                  this.userService.agreedLatestTerms$.next(true);
                  this.router.navigateByUrl('/summary');
                }
              });
          }
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
