import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-password-tooltip',
  templateUrl: './password-tooltip.component.html',
  styleUrls: ['./password-tooltip.component.scss'],
})
export class PasswordTooltipComponent implements OnInit {
  @Input() isShowToolTip: boolean;
  @Input() pwdHasLowerLetter: boolean;
  @Input() pwdHasUperLetter: boolean;
  @Input() pwdhasNumber: boolean;
  @Input() pwdValidSpecial: boolean;
  @Input() pwdValidLength: boolean;
  @Input() pwdValidNoInputName: boolean;

  constructor() {}

  ngOnInit() {}
}
