import { Component, OnInit } from '@angular/core';
import { SessionService, UserService } from '@services/index';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-auth-redirect',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.scss'],
})
export class AuthRedirectComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.userService
      .login()
      .pipe(take(1))
      .subscribe(user => {
        if (user) {
          const redirectUrl = sessionStorage.getItem('redirectUrl');
          redirectUrl
            ? this.router.navigateByUrl(redirectUrl)
            : this.router.navigateByUrl('/summary');
          sessionStorage.removeItem('redirectUrl');
        }
      });
  }
}
