import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableDataSource } from './TableDataSource';
import { Sort } from './type';

@Directive({
  selector: '[tableSort]',
  exportAs: 'tableSort',
})
// tslint:disable-next-line: directive-class-suffix
export class TableSort implements OnInit {
  public current: Sort;

  @Input('tableSort')
  private readonly defaultSort: Sort;

  @Input('dataSource')
  private dataSource: TableDataSource<any>;

  @Output('tableSortChange')
  readonly sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  // register(sort: Sort): void {
  //   if (!sort.columnId) {
  //     throw new Error('tableSortHeader should be used with cdkColumnDef.')
  //   }
  //   this.sorts.set(sort.columnId, sort);
  // }

  // deregister(columnId: string): void {
  //   this.sorts.delete(columnId);
  // }

  setSort(sort: Sort): void {
    this.sortChange.emit(sort);
    this.current = sort;
    this.dataSource.sort = sort;
  }

  ngOnInit() {
    if (this.defaultSort && this.defaultSort.columnId) {
      this.setSort(this.defaultSort);
    }
  }
}
