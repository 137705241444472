import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { GetBatchTest, GetReportEntryType } from '@generatedTypes/graphql';
@Component({
  selector: 'app-modal-trade-detail',
  templateUrl: './modal-trade-detail.component.html',
  styleUrls: ['./modal-trade-detail.component.scss'],
})
export class ModalTradeDetailComponent implements OnInit {
  defaultColumns: Array<{ display: string; selected: boolean }> = [];
  additionColumns: Array<{ key: string; selected: boolean }> = [];
  fieldDetails: Array<{ key: string; value: Array<string> }> = [];
  trade: {};
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalTradeDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  ngOnInit(): void {
    this.getConfigurationData();
  }

  getConfigurationData() {
    [this.fieldDetails, this.trade] = [...this.data];
  }

  close() {
    this.dialogRef.close(true);
  }
}
