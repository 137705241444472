import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public features: {};
  private _featureFlags: {};
  private _initialized = new BehaviorSubject(false);
  public initialized$ = this._initialized.asObservable();

  constructor() {}

  featureOff(featureName: string): boolean {
    return !this.featureOn(featureName);
  }

  featureOn(featureName: string): boolean {
    // if (!this._featureFlags[featureName]) {
    //   return false;
    // }
    return (
      this._featureFlags &&
      get(this._featureFlags[featureName], 'enabled') === true
    );
  }

  get initialized(): Observable<boolean> {
    return this._initialized;
  }

  initialize(features): Promise<boolean> {
    this._featureFlags = features;
    return new Promise((resolve, reject) => {
      if (this._featureFlags) {
        this._initialized.next(true);
        resolve(true);
      }
    });
  }
}
