import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface BreadcrumbsInputParams {
  resultId: number;
  class: string;
  report_type: string;
  batch_test: number;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() navLinks: BreadcrumbsInputParams;
  @Input() fromPage?: any;
  mapClickedType = {};

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.mapClickedType = {
      diagnostic: `/result/${this.navLinks.resultId}/Credit`,
      assetClassName: `/result/${this.navLinks.resultId}/${this.navLinks.class}`,
      results: '/summary',
    };
  }

  handleBreadcrumbClicked(event: string) {
    const destination = this.mapClickedType[event];
    this.router.navigate([destination], {
      queryParams: { batch_test: this.navLinks.batch_test },
      relativeTo: this.activatedRoute,
    });
  }
}
