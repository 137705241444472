import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TermsService } from '@services/terms.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { default as data_html } from './terms_template_v1.html';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TermsComponent implements OnInit, OnDestroy {
  termsText = '';
  unsubscribe$: Subject<void> = new Subject();

  constructor(private termService: TermsService) {}

  ngOnInit() {
    this.termService
      .getTermsOfAgreements()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        // console.log(res);
        this.termsText = res.html;
        // TODO: demo only
        this.termsText = data_html;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
