const ENVIRONMENT_VARIABLES = {
  // ****************
  // IF ADDING/REMOVING, MODIFY THE "extra-webpack.config.js"
  // ****************

  PRODUCTION: true,
  GRAPHQL_API_URL: 'http://localhost:3000/graphql',
  ENVIRONMENT: 'development',
  MAX_FILES_UPLOAD_LIMIT: 20,
  ENABLE_AUTH: 1,
  IAM_PRODUCT: 'luna_dev',
  IAM_LOGIN_URI:
    'https://signin.test.access.pwc.com/openam/oauth2/realms/venturessso/authorize',

  IAM_USER_INFO_PORTAL:
    'https://signin.test.access.pwc.com/openam/oauth2/realms/venturessso/userinfo',
  IAM_LOGOUT_URI:
    'https://signin.test.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout',
  APP_URL: 'http://localhost:4200',
  IAM_CLIENT_ID: 'luna_dev',
  IAM_RESPONSE_TYPE: 'id_token',
  IAM_SECOND_RESPONSE_TYPE: 'token',
  IAM_SCOPE: 'ventures',
  OPENAM_URL: 'https://signin.test.access.pwc.com/openam',
};

let fetched = false;

const fetchEnvVar = envVarName => {
  ENVIRONMENT_VARIABLES[envVarName] =
    (config as Config).env[envVarName] ||
    ENVIRONMENT_VARIABLES[envVarName] ||
    '';
};

export default function envVars(): { [key: string]: any } {
  if (fetched) {
    return ENVIRONMENT_VARIABLES;
  }
  Object.keys(ENVIRONMENT_VARIABLES).map(fetchEnvVar);
  Object.freeze(ENVIRONMENT_VARIABLES);
  fetched = true;
  return ENVIRONMENT_VARIABLES;
}
