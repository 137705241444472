import { Component, Input, EventEmitter, Output } from '@angular/core';

export interface MultiSelectGroup {
  display: string;
  items: MultiSelectGroupItem[];
}
export interface MultiSelectGroupItem {
  display: string;
  value: string | number;
  count?: number;
}

@Component({
  selector: 'luna-multi-select',
  exportAs: 'LunaMultiSelect',
  templateUrl: 'luna-multi-select.html',
  styleUrls: ['luna-multi-select.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class LunaMultiSelect {
  private _data: MultiSelectGroup[] = [];
  private _selected = new Set<string | number>();

  @Input() set multiSelectData(data: MultiSelectGroup[]) {
    this._data = data;
    this._selected.clear();
  }

  @Input() set selected(value: (string | number)[]) {
    this._selected = new Set(value);
    this.selectionChange.emit(value);
  }

  @Output() selectionChange = new EventEmitter<(string | number)[]>();

  itemChange(event, value: string | number) {
    if (event.checked) {
      this._selected.add(value);
    } else {
      this._selected.delete(value);
    }
    this.selectionChange.emit(Array.from(this._selected));
  }
}
