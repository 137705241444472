import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { UploadFileGQL, GetUserGQL, UploadFile } from '@generatedTypes/graphql';
import { ToastColorOption } from '@types';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private uploadFileGQL: UploadFileGQL,
    private toastService: ToastService,
    private getUserGQL: GetUserGQL,
  ) {}

  uploadFiles(files: [any]): Observable<any> {
    return this.uploadFileGQL
      .mutate(
        { files },
        {
          fetchPolicy: 'no-cache',
          refetchQueries: [
            {
              query: this.getUserGQL.document,
            },
          ],
        },
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }
}
