import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare var ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {
  constructor(public router: Router) {
    router.events.subscribe(
      event => {
        if (typeof ga === 'function') {
          if (event instanceof NavigationEnd) {
            this.emitEvent('navigation', 'visit', router.url);
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
          }
        }
      },
      err => {
        console.error(err);
      },
    );
  }

  private emitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
  ) {
    if (typeof ga === 'function') {
      ga('send', 'event', {
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
        eventValue: eventValue,
      });
    }
  }
}
