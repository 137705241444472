import { Injectable } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    const featureFlag = route.data['featureFlag'];
    if (featureFlag) {
      return this.featureFlagService.featureOn(featureFlag);
    } else {
      return false;
    }
  }
}
