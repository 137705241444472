import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { parseQuery } from 'query-string-parser';
import { UserService } from '@services/user.service';
import envVars from '@config/envVars';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastColorOption } from '@types';
import { head } from 'lodash';
import { ToastService } from '@services/toast.service';
import { nvOAuthService } from './nv-oauth-service';

// import { nvOAuthService } from 'nv-oauth2-oidc'; TODO: Upgrade to using NPM
import { MatDialog } from '@angular/material';
import { ToastDialogComponent } from '@shared/components/modal/toast-dialog/toast-dialog.component';

interface Token {
  access_token: string;
  id_token: string;
  token_type: string;
  expires_in: number; // in seconds
  expires_at: number; // in milliseconds from 1970/1/1
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private tokenStorageKey = 'session';
  private token: Token = undefined;
  public selectedRegulator$: BehaviorSubject<string> = new BehaviorSubject<
    string
  >('CFTC'); // TODO: Remove this later - just to test loading without navbar
  public showNavBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  );
  public showTenantBarWithSetting: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    private toastService: ToastService,
    private dialog: MatDialog,
  ) {}

  public handleAuthRedirect(): void {
    const token = this.getAuthenticationTokenFromURL();
    this.getProfile(token);
    localStorage.setItem(this.tokenStorageKey, JSON.stringify(token));
    this.token = token;
    window.location.hash = '';
  }

  public initialNvOAuthService() {
    nvOAuthService.initial(
      {
        clientId: envVars().IAM_CLIENT_ID,
        url: envVars().OPENAM_URL,
        scope: envVars().IAM_SCOPE,
        responseType: `${envVars().IAM_RESPONSE_TYPE} ${envVars().IAM_SECOND_RESPONSE_TYPE}`,
        redirectUri: envVars().APP_URL,
        logoutRedirectUri: `${window.location.origin}/welcome`,
        silentRefreshRedirectUri: envVars().APP_URL,
        silentRefreshInterval: 10 * 60 * 1000, // 10 mins
        silentRefreshTimeout: 60 * 1000, // 60 secs
        logoutTimeout: 60 * 1000, // 60 secs
        sessionTimeout:   20 * 60 * 1000, // 20 mins
        stayConnectConfirm: () => {
          // for stay or leave website
          return this.dialog
            .open(ToastDialogComponent, {
              panelClass: 'snake-toast',
              data: 'fromResolve',
            })
            .afterClosed()
            .toPromise()
            .then(data => {
              if (data === 'YES') {
                return Promise.resolve(true);
              } else {
                return Promise.resolve(false);
              }
            });
        },
        loginSuccess: () => {
          return this.getProfile(nvOAuthService.getAccessToken());
        },
        silentRefreshSuccess: () => {
          console.log('silent refresh success');
          console.log('id_token', nvOAuthService.getIdToken());
          console.log('access_token', nvOAuthService.getAccessToken());
          console.log('expires_at', nvOAuthService.getExpiration());
        },
        silentRefreshError: () => {
          console.log('silent refresh error');
          nvOAuthService.logout();
        },
        // userActionEventNames: ['scroll'],
        userActionEventNames: ['scroll', 'resize', 'mousemove', 'touchstart', 'keydown', 'mousedown'],

      },
      localStorage,
    );
  }

  private getProfile(accessToken) {
    this.userService
      .iamFetchUserDetails({
        accessToken: accessToken,
      })
      .subscribe(
        profile => {
          let organization_id: string;
          organization_id = head(
            profile.organization_id_list.filter(id =>
              id.includes(envVars().IAM_CLIENT_ID),
            ),
          ).split('|')[1];
          Object.assign(profile, { organization_id });
          localStorage.setItem('profile', JSON.stringify(profile));
        },
        (err: Error) => {
          this.toastService.show(`${err.message}`, ToastColorOption.ERROR);
        },
      );
  }

  public retrievePreviousSession() {
    let savedTokenString = localStorage.getItem(this.tokenStorageKey);
    if (savedTokenString === 'undefined') {
      console.warn('Token not valid. Clearing...');
      localStorage.removeItem(this.tokenStorageKey);
      // console.log(localStorage.getItem(this.tokenStorageKey));
      savedTokenString = null;
    }

    if (savedTokenString) {
      const token = JSON.parse(savedTokenString);
      if (token.expires_at > Date.now()) {
        this.token = token;
      }
    }
  }

  public requestSignIn(): void {
    nvOAuthService.initImplicitFlow();
  }

  public signOutUser() {
    this.userService.clearUser();
    localStorage.removeItem(this.tokenStorageKey);
    // Remove tokens and profile and update login status subject
    [
      'access_token',
      'id_token',
      'expires_at',
      'auth_profile',
      'current_user',
    ].map(el => localStorage.removeItem(el));
    sessionStorage.removeItem('redirect_url');
    this.apollo.getClient().clearStore();
    // replace logout logic
    nvOAuthService.logout();

    // this.router.navigate(['/welcome']);
    window.location.href = `${envVars().IAM_LOGOUT_URI}/&goto=${
      envVars().APP_URL
    }/welcome`;
    // window.location.href =
    //   'https://signin.test.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout/&goto=http://localhost:4200/welcome';
  }

  public getAuthorizationHeaders(): { authorization: string } | {} {
    const token: string = nvOAuthService.getIdToken();
    if (!token) {
      return {};
    }

    if (localStorage.getItem('id_token')) {
      this.userService.signedIn.next(true);
    }
    return { authorization: `Bearer ${token}` };
  }

  public isRedirectedBack(): boolean {
    return window.location.hash.length > 0;
  }
  private getAuthenticationTokenFromURL(): Token {
    const token = parseQuery(window.location.hash.replace('#', ''));
    // console.log(token);
    if (!token) {
      const savedTokenString = localStorage.getItem(this.tokenStorageKey);
      if (savedTokenString) {
        const savedToken = JSON.parse(savedTokenString);
        return {
          access_token: savedToken.access_token,
          id_token: savedToken.id_token,
          token_type: savedToken.token_type,
          expires_in: parseFloat(savedToken.expires_in),
          expires_at: Date.now() + savedToken.expires_in * 1000,
        };
      }
    } else {
      token.expires_in = parseFloat(token.expires_in);
      token.expires_at = Date.now() + token.expires_in * 1000;
      return token;
    }
  }

  public handleRegulatorChanged(newRegulator: string): void {
    this.selectedRegulator$.next(newRegulator);
  }
}
