import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetFilterInfoGQL,
  GetBatchSummaryGQL,
  GetFilterInfo,
  GetBatchSummary,
} from '@generatedTypes/graphql';
import { SessionService } from '@services/session.service';

type BatchType = GetBatchSummary.GetBatches;
type FilterInfoType = GetFilterInfo.Query;

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  constructor(
    private getBatchSummaryGQL: GetBatchSummaryGQL,
    private getFilterInfoGQL: GetFilterInfoGQL,
    private sessionService: SessionService,
  ) {}

  getBatch(): Observable<BatchType> {
    return this.getBatchSummaryGQL
      .fetch(
        { regulator_name: this.sessionService.selectedRegulator$.value },
        { fetchPolicy: 'network-only' },
      )
      .pipe(
        map(({ data }) => {
          const batch = <BatchType>_.get(data, 'getBatches.0', {});
          batch.batch_tests = _.sortBy(batch.batch_tests, 'test.result_id');

          return batch;
        }),
      );
  }

  getFilterInfo(): Observable<FilterInfoType> {
    return this.getFilterInfoGQL.fetch().pipe(map(({ data }) => data));
  }
}
